import React, { useState, useEffect } from "react";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import logo2 from "../src/img/logo2.png";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ApiIcon from "@mui/icons-material/Api";
import FunctionsIcon from "@mui/icons-material/Functions";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LoopIcon from "@mui/icons-material/Loop";
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import StorageIcon from '@mui/icons-material/Storage';

import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import iconbarleft from "../src/img/logo.png";
import EditIcon from "@mui/icons-material/Edit";

import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import DescriptionIcon from "@mui/icons-material/Description";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

import { Button, Grid } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import appico from "../src/img/logo.png";
import downapp from "../src/img/logo.png";

import Footer from "../containers/Footer";
import ApartmentIcon from "@mui/icons-material/Apartment";

import "./amirifont.css";
import pharmacy from "../src/img/logo.png";
import blooddon from "../src/img/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import moresearch from "../src/img/logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { API_BASE_URL } from "../containers/config"; // Adjust the path if necessary
import flag_dz from "../src/img/flag_dz.png";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const themeRoboto = createTheme({
  typography: {
    fontFamily: '"Roboto Slab", serif',
    fontSize: 10,
  },
});

const themeChanga = createTheme({
  typography: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 10,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight({
  IDsession_selected,
  IDetablissement,
  IDDFEP,
  IDNature_etsF,
  IDCandidat,
  crypted_id,
  NumSem,
  IDapis_userslist,
  loadingauth,
  Singup_active,
  download_cert_scol_active,
  EditingInfo_active,
  Eval_notes_active,
  download_cert_inscription_active,
  Singup_active_Dfep,
  download_cert_scol_active_Dfep,
  EditingInfo_active_Dfep,
  Eval_notes_active_Dfep,
  download_cert_inscription_active_Dfep,
  Singup_active_Mfep,
  download_cert_scol_active_Mfep,
  EditingInfo_active_Mfep,
  Eval_notes_active_Mfep,
  download_cert_inscription_active_Mfep,
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const themeChangaHeader = createTheme({
    typography: {
      fontFamily: '"Tajawal", sans-serif',
      fontSize: 16,
      fontWeight: 900,
    },
  });

  const themeChanga = createTheme({
    typography: {
      fontFamily: '"Tajawal", sans-serif',
      fontSize: 14,
      fontWeight: 900,
    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openprint = Boolean(anchorEl);

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isMatch_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch_xs = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl_infocand, setAnchorEl_infocand] = React.useState(null);
  const open_infocand = Boolean(anchorEl_infocand);
  const handleClick_infocand = (event) => {
    setAnchorEl_infocand(event.currentTarget);
  };
  const handleClose_infocand = () => {
    setAnchorEl_infocand(null);
  };

  const [anchorElMotakawin, setAnchorElMotakawin] = React.useState(null);
  const openMotakawin = Boolean(anchorElMotakawin);
  const handleClickMotakawin = (event) => {
    setAnchorElMotakawin(event.currentTarget);
  };
  const handleCloseMotakawin = () => {
    setAnchorElMotakawin(null);
  };

  const navigate = useNavigate();
  const handleClickValidationStEtab = () => {
    // Navigate to the desired route
    navigate("/ValidationCandInfo");
    handleCloseMotakawin();
  };

  const handleClickChangPwStEtab = () => {
    // Navigate to the desired route
    navigate("/ChangPwStEtab");
    handleCloseMotakawin();
  };

  const handleClicksettingDfep = () => {
    // Navigate to the desired route
    if (Number(IDNature_etsF) === 5) {
      navigate("/SettingDfep");
    } else if (Number(IDNature_etsF) === 1) {
      navigate("/SettingMfep");
    }

    handleCloseMotakawin();
  };

  const handleClicksettingapi = () => {
    // Navigate to the desired route
    navigate("/Apis_userslist");
    handleCloseMotakawin();
  };

  const handleClicksettingApis_functions = () => {
    // Navigate to the desired route
    navigate("/Apis_functions");
    handleCloseMotakawin();
  };

  const handleClicksettingMigrate_hfsql = () => {
    // Navigate to the desired route
    navigate("/Migrate_hfsql");
    handleCloseMotakawin();
  };

  const handleClicksession = () => {
    // Navigate to the desired route
    navigate("/SessionsMfep");
    handleCloseMotakawin();
  };

  const handleClicksettingapis_userlist_functions = () => {
    // Navigate to the desired route
    navigate("/apis_userlist_functions");
    handleCloseMotakawin();
  };

  const handleClickChangPwPriv = () => {
    // Navigate to the desired route
    navigate("/ChangPwPriv");
    handleCloseMotakawin();
  };

  const handleClickChangePwEtab = () => {
    // Navigate to the desired route
    navigate("/ChangePwEtab");
    handleCloseMotakawin();
  };

  const [hidepagest, sethidepagest] = useState(false);
  const [hidepageetub, sethidepageetub] = useState(false);

  let toLink_SettingDfep_or_SettingEtab = "";
  let toLink_changpwst = "";
  let toLink_Offers = "";

  if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    Number(IDNature_etsF) === 1
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeMfep";
    toLink_changpwst = "/ChangPwStMfep";
    toLink_Offers = "/OffersMfep";
  }  else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (IDNature_etsF === 40 || IDNature_etsF === "40")
  ) {
    toLink_SettingDfep_or_SettingEtab = "/InnovationList";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "";
  }else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (IDNature_etsF === 5 || IDNature_etsF === "5")
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeDfep";
    toLink_changpwst = "/ChangPwStDfep";
    toLink_Offers = "/OffersDfep";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== ""
  ) {
    toLink_SettingDfep_or_SettingEtab = "/SettingEtab";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "/OffersEtab";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== ""
  ) {
    toLink_SettingDfep_or_SettingEtab = "/SettingPriv";
    toLink_changpwst = "";
    toLink_Offers = "";
  }

  useEffect(() => {
    if (
      IDCandidat == null ||
      IDCandidat == undefined ||
      !Number(IDCandidat) > 0
    ) {
      sethidepagest(true);
    } else if (
      IDetablissement == null ||
      IDetablissement == undefined ||
      !Number(IDetablissement) > 0 ||
      !Number(IDNature_etsF) > 0
    ) {
      sethidepageetub(true);
    } else {
      sethidepageetub(false);
      sethidepagest(false);
    }
  }, []);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <CssBaseline />
      <AppBar open={open} sx={{ width: "100%" }}>
        {!open ? (
          <>
            <div>
              <Box>
                <Grid
                  container
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{
                    backgroundColor: "#d6e6f6",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {/* Centering content horizontally and vertically */}
                    <Box sx={{ overflow: "hidden", ml: 1 }}>
                      {isMatch_xs ? (
                        <></>
                      ) : isMatch_sm ? (
                        <></>
                      ) : isMatch ? (
                        <>
                          <img
                            src={appico}
                            style={{ width: "50px", height: "50px" }}
                            alt="Image 1"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={appico}
                            style={{ width: "70px", height: "70px" }}
                            alt="Image 1"
                          />
                        </>
                      )}
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      {" "}
                      {/* Added left margin for spacing */}
                      <Typography
                        sx={{
                          color: "#191970",
                          fontFamily: '"Tajawal", sans-serif',

                          fontWeight: 900,
                          fontSize: isMatch_xs
                            ? "10px"
                            : isMatch_sm
                            ? "12px"
                            : isMatch
                            ? "20px"
                            : "12px",
                          mt: 1,
                        }}
                      >
                        الجمهورية الجزائرية الديمقراطية الشعبية
                      </Typography>
                      <Typography
                        sx={{
                          color: "#191970",
                          fontFamily: '"Tajawal", sans-serif',
                          fontWeight: 900,
                          fontSize: isMatch_xs
                            ? "10px"
                            : isMatch_sm
                            ? "10px"
                            : isMatch
                            ? "20px"
                            : "10px",
                          mb: 1,
                        }}
                      >
                        وزارة التكويـن والتعلـيـم المهنييـن
                      </Typography>
                    </Box>
                    <Box sx={{ overflow: "hidden", mr: 1 }}>
                      {isMatch_xs ? (
                        <></>
                      ) : isMatch_sm ? (
                        <></>
                      ) : isMatch ? (
                        <>
                          <img
                            src={flag_dz}
                            style={{ width: "50px", height: "50px" }}
                            alt="Image 1"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={flag_dz}
                            style={{ width: "70px", height: "70px" }}
                            alt="Image 1"
                          />
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </>
        ) : null}

        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            theme={themeChangaHeader}
            sx={{
              flexGrow: 1,
              fontSize: 18,
              mr: 1,
              mt: 0,
              fontFamily: '"Tajawal", sans-serif',
              fontSize: 20,
              fontWeight: 700,
            }}
            component="div"
          >
            منصة المتكون
          </Typography>

          <a style={{ textDecoration: "none" }} href="https://www.mfep.gov.dz/">
            <img
              alt="منصة المتكون"
              src={logo2}
              style={{ width: 60, height: 60 }} // Adjust width, height, and border-radius as needed
            />
          </a>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
      </Main>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader dir="ltr">
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </DrawerHeader>

        <List>
          {(IDetablissement == null || IDetablissement == undefined) &&
          (IDCandidat == null || IDCandidat == undefined) &&
          (IDapis_userslist == null || IDapis_userslist == undefined) ? (
            <>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />

              <ListItem
                style={{ backgroundColor: "#80808017" }}
                component={Link}
                to="/login"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#063970",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#063970" }}
                    >
                      <PersonIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      بوابة المتكون
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          ) : (
            <></>
          )}

          {(IDetablissement == null || IDetablissement == undefined) &&
          (IDCandidat == null || IDCandidat == undefined) &&
          (IDapis_userslist == null || IDapis_userslist == undefined) ? (
            <>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />

              <ListItem
                component={Link}
                to="/loginEtab"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#063970",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#063970" }}
                    >
                      <ApartmentIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      بوابة المؤسسة
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          ) : (
            <></>
          )}

          {IDCandidat !== null && IDCandidat !== undefined && (
            <>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />

              <ListItem
                style={{ backgroundColor: "#80808017" }}
                alignItems="flex-start"
                onClick={handleClick_infocand}
              >
                <ListItemText
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DescriptionIcon
                        style={{
                          marginRight: "5px",
                          marginBottom: "-6px",
                          color: "#063970",
                        }}
                      />

                      <Typography
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#063970",
                        }}
                        type="body2"
                        style={{ color: "#063970" }}
                      >
                        حسابي
                      </Typography>
                      <ArrowDropDownIcon style={{ color: "#063970" }} />
                    </div>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          )}

          {IDetablissement !== null &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined && (
              <>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />

                <ListItem
                  style={hidepagest ? { backgroundColor: "#80808017" } : null}
                  component={Link}
                  to={toLink_SettingDfep_or_SettingEtab}
                  alignItems="flex-start"
                  onClick={handleDrawerClose}
                >
                  <ListItemText
                    primary={
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#063970",
                        }}
                        variant="h6"
                        type="body2"
                        style={{ color: "#063970" }}
                      >
                        <HomeIcon
                          style={{ marginRight: "5px", marginBottom: "-6px" }}
                        />{" "}
                        الرئيسية
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        {/* Any secondary content if needed */}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </>
            )}

          {IDapis_userslist !== null && IDapis_userslist !== undefined && (
            <>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />

              <ListItem
                style={hidepagest ? { backgroundColor: "#80808017" } : null}
                component={Link}
                to="/ShowApiList"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#063970",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#063970" }}
                    >
                      <HomeIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      الرئيسية
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          )}

          {IDetablissement !== null &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined && (
              <>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />

                <ListItem
                  onClick={handleClickMotakawin}
                  alignItems="flex-start"
                >
                  <ListItemText
                    primary={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <DescriptionIcon
                          style={{
                            marginRight: "5px",
                            marginBottom: "-6px",
                            color: "#063970",
                          }}
                        />
                        <Typography
                          theme={themeChanga}
                          sx={{
                            textAlign: "right",
                            mr: 0,
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 16,
                            fontWeight: 700,
                            color: "#063970",
                          }}
                          type="body2"
                          style={{ color: "#063970" }}
                        >
                          لوحة التحكم
                        </Typography>
                        <ArrowDropDownIcon style={{ color: "#063970" }} />
                      </div>
                    }
                    secondary={
                      <React.Fragment>
                        {/* Any secondary content if needed */}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </>
            )}

          {IDetablissement !== null &&
          Number(IDNature_etsF)!==40 &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined && (
              <>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />

                <ListItem
                  style={{ backgroundColor: "#80808017" }}
                  component={Link}
                  to="/ShowAllAprs"
                  alignItems="flex-start"
                  onClick={handleDrawerClose}
                >
                  <ListItemText
                    primary={
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#063970",
                        }}
                        variant="h6"
                        type="body2"
                        style={{ color: "#063970" }}
                      >
                        <PrintIcon
                          style={{ marginRight: "5px", marginBottom: "-6px" }}
                        />{" "}
                        مطبوعات
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        {/* Any secondary content if needed */}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </>
            )}

          {IDCandidat !== null && IDCandidat !== undefined && (
            <>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />

              <ListItem alignItems="flex-start" onClick={handleClick}>
                <ListItemText
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <PrintIcon
                        style={{
                          marginRight: "5px",
                          marginBottom: "-6px",
                          color: "#063970",
                        }}
                      />
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#063970",
                        }}
                        type="body2"
                        style={{ color: "#063970" }}
                      >
                        مطبوعات
                      </Typography>
                      <ArrowDropDownIcon style={{ color: "#063970" }} />
                    </div>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          )}

          {(IDetablissement == null || IDetablissement == undefined) &&
          (IDCandidat == null || IDCandidat == undefined) &&
          (IDapis_userslist == null || IDapis_userslist == undefined) &&
          IDsession_selected !== null &&
          IDsession_selected !== undefined ? (
            <>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />

              <ListItem
                style={
                  (IDCandidat !== null && IDCandidat !== undefined) ||
                  (!(IDCandidat !== null && IDCandidat !== undefined) &&
                    !(
                      IDetablissement !== null &&
                      (IDCandidat == null ||
                        Number(IDCandidat) <= 0 ||
                        IDCandidat == undefined) &&
                      IDetablissement !== undefined
                    ))
                    ? { backgroundColor: "#80808017" }
                    : {}
                }
                component={Link}
                to="/Offersinscription"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <EventRepeatIcon
                        style={{
                          marginRight: "5px",
                          marginBottom: "-6px",
                          color: "#063970",
                        }}
                      />
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: "4px",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#063970",
                        }}
                        type="body2"
                        style={{ color: "#063970" }}
                      >
                        عروض التكوين
                      </Typography>
                    </div>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          ) : null}

          {(IDetablissement == null || IDetablissement == undefined) &&
          (IDCandidat == null || IDCandidat == undefined) &&
          (IDapis_userslist == null || IDapis_userslist == undefined) ? (
            <>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />

              <ListItem
                component={Link}
                to="/checkAuth"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DocumentScannerIcon
                        style={{
                          marginRight: "5px",
                          marginBottom: "-6px",
                          color: "#063970",
                        }}
                      />
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: "4px",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#063970",
                        }}
                        type="body2"
                        style={{ color: "#063970" }}
                      >
                        تحقق من الشهادة
                      </Typography>
                    </div>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          ) : null}

          {(IDetablissement !== null &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined) ||
          (IDCandidat !== null && IDCandidat !== undefined) ? (
            <>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />

              <ListItem
                style={!hidepagest ? { backgroundColor: "#80808017" } : null}
                component={Link}
                to="/login"
                alignItems="flex-start"
                onClick={() => {
                  if (
                    IDapis_userslist !== null &&
                    IDapis_userslist !== undefined
                  ) {
                    sessionStorage.clear();
                    // Remove all data with the prefix
                    const keysToRemove = Object.keys(localStorage).filter(
                      (key) => key.startsWith(APP_PREFIX)
                    );
                    keysToRemove.forEach((key) => localStorage.removeItem(key));

                    sethidepagest(false);
                    sethidepageetub(false);
                    sessionStorage.clear();
                    setOpen(false);
                  } else {
                    const destroyToken = `${API_BASE_URL}/api/destroyToken`;

                    fetch(destroyToken, {
                      headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${sessionStorage.getItem(
                          "authData_73xJ#E"
                        )}`,
                      },
                    })
                      .then((response) => {
                        if (!response.ok) {
                          throw new Error("Network response was not ok");
                        }
                        return response.json();
                      })
                      .then((data) => {
                        if (data) {
                          console.error("Distroyed token: successfully");
                        }
                      })
                      .catch((error) => {
                        console.error("Error destroyToken:", error);
                      })
                      .finally(() => {
                        sessionStorage.clear();
                        // Remove all data with the prefix
                        const keysToRemove = Object.keys(localStorage).filter(
                          (key) => key.startsWith(APP_PREFIX)
                        );
                        keysToRemove.forEach((key) =>
                          localStorage.removeItem(key)
                        );

                        sethidepagest(false);
                        sethidepageetub(false);
                        sessionStorage.clear();
                        setOpen(false);
                      });
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#063970",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#063970" }}
                    >
                      <LogoutIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      خروج
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          ) : (
            <></>
          )}

          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openprint}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {IDCandidat !== null &&
              IDCandidat !== undefined &&
              download_cert_inscription_active !== null &&
              download_cert_inscription_active !== undefined &&
              Number(download_cert_inscription_active) === 1 &&
              Number(download_cert_inscription_active_Dfep) === 1 &&
              Number(download_cert_inscription_active_Mfep) === 1
              &&
              Number(NumSem) ===1
                             ? (
                <MenuItem
                  component={Link}
                  to={`/ETATCertificatInscriptionPDF/${crypted_id}`}
                  target="_blank" // Add this line to open in a new tab
                >
                  {" "}
                  <PictureAsPdfIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    {" "}
                    شهادة تسجيل{" "}
                  </Typography>
                </MenuItem>
              ) : Number(NumSem) === 1 ? (
                <MenuItem disabled={true}>
                  {" "}
                  <PictureAsPdfIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    {" "}
                    شهادة تسجيل{" "}
                  </Typography>
                </MenuItem>
              ) : null}

              {IDCandidat !== null &&
              IDCandidat !== undefined &&
              download_cert_scol_active !== null &&
              download_cert_scol_active !== undefined &&
              Number(download_cert_scol_active) === 1 &&
              Number(download_cert_scol_active_Dfep) === 1 &&
              Number(download_cert_scol_active_Mfep) === 1 ? (
                <div>
                  <Divider
                    variant="inset"
                    component="li"
                    style={{ width: "100%" }}
                  />
                  <MenuItem
                    component={Link}
                    to={`/ETATCertificatScolaritePDF/${crypted_id}`}
                    target="_blank" // Add this line to open in a new tab
                  >
                    {" "}
                    <PictureAsPdfIcon />{" "}
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        mr: "5px",
                      }}
                      type="body2"
                    >
                      {" "}
                      شهادة تربص{" "}
                    </Typography>
                  </MenuItem>
                </div>
              ) : (
                <div>
                  <Divider
                    variant="inset"
                    component="li"
                    style={{ width: "100%" }}
                  />
                  <MenuItem disabled={true}>
                    {" "}
                    <PictureAsPdfIcon />{" "}
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        mr: "5px",
                      }}
                      type="body2"
                    >
                      {" "}
                      شهادة تربص{" "}
                    </Typography>
                  </MenuItem>
                </div>
              )}
            </Menu>
          </div>

          <div>
            <Menu
              id="basic-menu_infocand"
              anchorEl={anchorEl_infocand}
              open={open_infocand}
              onClose={handleClose_infocand}
              MenuListProps={{
                "aria-labelledby": "basic-button_infocand",
              }}
            >
              <MenuItem component={Link} to="/InfoCand">
                {" "}
                <PersonIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  بطاقة معلوماتي
                </Typography>
              </MenuItem>

              {IDCandidat !== null &&
              IDCandidat !== undefined &&
              Eval_notes_active !== null &&
              Eval_notes_active !== undefined &&
              Number(Eval_notes_active) === 1 &&
              Number(Eval_notes_active_Dfep) === 1 &&
              Number(Eval_notes_active_Mfep) === 1 ? (
                <div>
                  <Divider
                    variant="inset"
                    component="li"
                    style={{ width: "100%" }}
                  />
                  <MenuItem component={Link} to="/NotesApr">
                    {" "}
                    <StickyNote2Icon />{" "}
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        mr: "5px",
                      }}
                      type="body2"
                    >
                      {" "}
                      نتائج السداسي{" "}
                    </Typography>
                  </MenuItem>
                </div>
              ) : (
                <div>
                  <Divider
                    variant="inset"
                    component="li"
                    style={{ width: "100%" }}
                  />
                  <MenuItem disabled={true}>
                    {" "}
                    <StickyNote2Icon />{" "}
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        mr: "5px",
                      }}
                      type="body2"
                    >
                      {" "}
                      نتائج السداسي{" "}
                    </Typography>
                  </MenuItem>
                </div>
              )}

              {IDCandidat !== null && IDCandidat !== undefined ? (
                <div>
                  <Divider
                    variant="inset"
                    component="li"
                    style={{ width: "100%" }}
                  />
                  <MenuItem component={Link} to="/ChangePassword">
                    {" "}
                    <LockIcon />{" "}
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        mr: "5px",
                      }}
                      type="body2"
                    >
                      {" "}
                      تغيير كلمة السر{" "}
                    </Typography>
                  </MenuItem>
                </div>
              ) : null}
            </Menu>
          </div>

          <div>
            <Menu
              id="basic-menuMotakawin"
              anchorEl={anchorElMotakawin}
              open={openMotakawin}
              onClose={handleCloseMotakawin}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {IDetablissement !== null &&
                (IDCandidat == null ||
                  Number(IDCandidat) <= 0 ||
                  IDCandidat == undefined) &&
                IDetablissement !== undefined &&
                Number(IDNature_etsF) === 1 && (
                  <div>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ width: "100%" }}
                    />
                    <MenuItem onClick={handleClicksettingapi}>
                      {" "}
                      <ApiIcon />{" "}
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          mr: "5px",
                        }}
                        type="body2"
                      >
                        {" "}
                        إدارة حسابات (API){" "}
                      </Typography>
                    </MenuItem>
                  </div>
                )}

              {IDetablissement !== null &&
                (IDCandidat == null ||
                  Number(IDCandidat) <= 0 ||
                  IDCandidat == undefined) &&
                IDetablissement !== undefined &&
                Number(IDNature_etsF) === 1 && (
                  <div>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ width: "100%" }}
                    />
                    <MenuItem onClick={handleClicksettingApis_functions}>
                      {" "}
                      <FunctionsIcon />{" "}
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          mr: "5px",
                        }}
                        type="body2"
                      >
                        {" "}
                        إدارة الدوال (API Functions){" "}
                      </Typography>
                    </MenuItem>
                  </div>
                )}

              {IDetablissement !== null &&
                (IDCandidat == null ||
                  Number(IDCandidat) <= 0 ||
                  IDCandidat == undefined) &&
                IDetablissement !== undefined &&
                Number(IDNature_etsF) === 1 && (
                  <div>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ width: "100%" }}
                    />
                    <MenuItem
                      onClick={handleClicksettingapis_userlist_functions}
                    >
                      {" "}
                      <FunctionsIcon /> <ApiIcon />{" "}
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          mr: "5px",
                        }}
                        type="body2"
                      >
                        {" "}
                        الدالة و الحساب (API){" "}
                      </Typography>
                    </MenuItem>
                  </div>
                )}

              {IDetablissement !== null &&
                (IDCandidat == null ||
                  Number(IDCandidat) <= 0 ||
                  IDCandidat == undefined) &&
                IDetablissement !== undefined &&
                Number(IDNature_etsF) === 1 && (
                  <div>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ width: "100%" }}
                    />
                    <MenuItem onClick={handleClicksession}>
                      {" "}
                      <LoopIcon />{" "}
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          mr: "5px",
                        }}
                        type="body2"
                      >
                        {" "}
                        الدورات{" "}
                      </Typography>
                    </MenuItem>
                  </div>
                )}

              {IDetablissement !== null &&
              Number(IDNature_etsF)!==40 &&
                (IDCandidat == null ||
                  Number(IDCandidat) <= 0 ||
                  IDCandidat == undefined) &&
                IDetablissement !== undefined &&
                IDNature_etsF !== null &&
                IDNature_etsF !== undefined && (
                  <div>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ width: "100%" }}
                    />

                    <MenuItem component={Link} to={toLink_Offers}>
                      {" "}
                      <EventRepeatIcon />{" "}
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          mr: "5px",
                        }}
                        type="body2"
                      >
                        {" "}
                        عروض التكوين{" "}
                      </Typography>
                    </MenuItem>
                  </div>
                )}

              {IDetablissement !== null &&
              Number(IDNature_etsF)!==40 &&
                (IDCandidat == null ||
                  Number(IDCandidat) <= 0 ||
                  IDCandidat == undefined) &&
                IDetablissement !== undefined &&
                IDNature_etsF !== null &&
                IDNature_etsF !== undefined && (
                  <div>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ width: "100%" }}
                    />

                    <MenuItem component={Link} to={toLink_changpwst}>
                      {" "}
                      <LockIcon />{" "}
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          mr: "5px",
                        }}
                        type="body2"
                      >
                        تغيير كلمة سر المتكون{" "}
                      </Typography>
                    </MenuItem>
                  </div>
                )}

              {IDetablissement !== null &&
                (IDCandidat == null ||
                  Number(IDCandidat) <= 0 ||
                  IDCandidat == undefined) &&
                IDetablissement !== undefined &&
                (Number(IDNature_etsF) === 5 ||
                  Number(IDNature_etsF) === 1) && (
                  <div>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ width: "100%" }}
                    />
                    <MenuItem onClick={handleClicksettingDfep}>
                      {" "}
                      <ApartmentIcon />{" "}
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          mr: "5px",
                        }}
                        type="body2"
                      >
                        {" "}
                        إدارة حسابات المؤسسات{" "}
                      </Typography>
                    </MenuItem>
                  </div>
                )}

              {IDetablissement !== null &&
                (IDCandidat == null ||
                  Number(IDCandidat) <= 0 ||
                  IDCandidat == undefined) &&
                IDetablissement !== undefined && (
                  <div>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ width: "100%" }}
                    />
                    <MenuItem onClick={handleClickChangePwEtab}>
                      {" "}
                      <LockIcon />{" "}
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          mr: "5px",
                        }}
                        type="body2"
                      >
                        {" "}
                        تغيير كلمة سر حسابك{" "}
                      </Typography>
                    </MenuItem>
                  </div>
                )}
{/* 
              {IDetablissement !== null &&
                (IDCandidat == null ||
                  Number(IDCandidat) <= 0 ||
                  IDCandidat == undefined) &&
                IDetablissement !== undefined &&
                Number(IDNature_etsF) === 1 && (
                  <div>
                    <Divider
                      variant="inset"
                      component="li"
                      style={{ width: "100%" }}
                    />
                    <MenuItem onClick={handleClicksettingMigrate_hfsql}>
                      {" "}
                      <StorageIcon />{" "}
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          mr: "5px",
                        }}
                        type="body2"
                      >
                        {" "}
                        نقل قاعدة بيانات من (HFSQL){" "}
                      </Typography>
                    </MenuItem>
                  </div>
                )}
                */}
            </Menu>
          </div>
        </List>
      </Drawer>
    </Box>
  );
}
