import React, { useEffect, useRef } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { Html5QrcodeTranslate } from './Html5QrcodeTranslate'; // Import Html5QrcodeTranslate
import './html5qrcodeplugin.css'; // Import the CSS file

const qrcodeRegionId = "html5qr-code-full-region";

const createConfig = (props) => {
  let config = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

const Html5QrcodePlugin = (props) => {
  const qrcodeScannerRef = useRef(null);

  useEffect(() => {
    const config = createConfig(props);
    const verbose = props.verbose === true;

    if (!props.qrCodeSuccessCallback) {
      throw new Error("qrCodeSuccessCallback is a required callback.");
    }

    // Initialize Html5QrcodeTranslate
    const qrCodeTranslate = new Html5QrcodeTranslate(`#${qrcodeRegionId}`);

    // Initialize Html5QrcodeScanner
    qrcodeScannerRef.current = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
    qrcodeScannerRef.current.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

    return () => {
      // Disconnect Html5QrcodeTranslate on component unmount
      qrCodeTranslate.disconnect();

      // Clear Html5QrcodeScanner
      qrcodeScannerRef.current.clear().catch(error => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, []);

  return (

    <div id={qrcodeRegionId} />

  );
};

export default Html5QrcodePlugin;
