import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import appico from '../src/img/logo.png';
 import logoMfep from '../src/img/logo.png';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';

import ReplayIcon from '@mui/icons-material/Replay';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Button from '@mui/material/Button';

import axios from 'axios';
import { isMobile, browserName } from 'react-device-detect';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import '../containers/amirifont.css'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import InfoIcon from '@mui/icons-material/Info';
import Footer from '../containers/Footer';
import Header from '../containers/Header';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary
import ReCAPTCHA from 'react-google-recaptcha';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://AtibaDz.com/">
        Abdelaziz sebrou
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
});



const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});


const BootstrapTooltip = styled((props) => (
  <Tooltip {...props} arrow classes={{ popper: props.className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    ///color: theme.palette.common.black,
    color: 'orange',
    top: '10px',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    ///backgroundColor: theme.palette.common.black,
    backgroundColor: 'orange',
    fontSize: '14px',
    fontWeight: 'bold',
    top: '10px',
  },
}));



export default function Login() {




  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  // const history = useHistory();
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.clear();
    ///navigate('/login');
     
  }, []);

  const refNINorMatSt = useRef(null);
  const ismobref = useRef(null);


  const [valueNINorMatSt, setvalueNINorMatSt] = useState('');
  const [isValidemail, setIsValidemail] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [emailverificated, setemailverificated] = React.useState(false);

  const [emailsended, setemailsended] = React.useState(false);
  const [errorsendingemail, seterrorsendingemail] = React.useState(false);
  const refpassword1 = useRef(null);

  const [FailedAuth, setFailedAuth] = useState(false);

  const [OpenDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };


  const handleClickCloseDialog = () => {
    if (ismobref.current) {
      ismobref.current.click();
    }
    setOpenDialog(false);
  };

  const [token, setToken] = useState(null);




  const [valuesPassword, setValuesPassword] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChangePassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };






  const onemailblur = event => {
    const val = event.target.value;

    if (isValidemail) {
      setDirty(true);
    }



  }



  const hostn = window.location.hostname;
  const full = window.location.protocol + '//' + window.location.host;

  const ismoblinkred = 'intent:' + full + '/LoginDon#Intent;package=com.android.chrome;end';
  const [valuelink, setvaluelink] = useState(ismoblinkred);


  const [recaptchaVerified, setRecaptchaVerified] = useState(true); // Initially assuming reCAPTCHA is verified
  const refReCAPTCHA = useRef(null);


  const handleRecaptchaChange = (value) => {
    // handle reCAPTCHA response
    if (value) {
      // reCAPTCHA verification succeeded
      setRecaptchaVerified(true);
      // Proceed with your logic here
    } else {
      // reCAPTCHA verification failed
      setRecaptchaVerified(true);
      // Handle the failure scenario
    }
  };
  /*
  const handleRecaptchaChange = (e) => {
    setToken(e.target.value); // e.target.value should hold the token
  };*/

  useEffect(() => {

    //alert('ismobile: '+browserName);
    if (isMobile && (browserName !== "Chrome" || browserName == "Facebook" || browserName == "" || browserName == undefined || browserName == "undefined")) {
      if (ismobref.current) {
        ismobref.current.click();
        handleClickOpenDialog();
      }
    }

  }, [valueNINorMatSt]);






  useEffect(() => {
    /////console.log('diry changed='+dirty)
    if (dirty && isValidemail) {
      ///ResendEmailClick();  
    }

  }, [dirty]);


  useEffect(() => {
    /////console.log('isValidemail '+isValidemail+ 'emailverificated ' +emailverificated + 'emailsended '+emailsended )
    setemailsended(false);

  }, [isValidemail]);


  const handleChangeemail = event => {
    const val = event.target.value;


    setvalueNINorMatSt(val);


    /////handleChangeemail(val, isValidemail);
  }

  const sitekey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
  ////////
  ///////6LckCXIpAAAAAO2Y87-XcGXMTbpkBIiNSNyhGWwl

  const Auth = async () => {
    try {
      setLoading(true);

      const data = {
        NINorMatSt: valueNINorMatSt,
        password_login: valuesPassword.password,
      };


      const response = await axios.post(`${API_BASE_URL}/api/checklogincand`, data);

      if (response.status >= 200 && response.status < 300) {
        if (response.data && !response.data.error) {
          setFailedAuth(false);
          const candidat = response.data.candidat;
          const token = response.data.token;

          sessionStorage.setItem("authData_73xJ#E", token);
          sessionStorage.setItem("auth_cand", 'auth_cand');

          navigate('/InfoCand');
        } else {
          setFailedAuth(true);

        }
      } else {
        console.error('HTTP error:', response.status);

      }

    } catch (error) {
      console.error('API call failed:', error);
      // Optionally setFailedAuth(true) or perform other error handling
    } finally {
      setLoading(false);
    }
  };



  const handleSubmit = (event) => {
    event.preventDefault();

    /// ismobref.current.click();



    if (valueNINorMatSt.length == 0) {
      refNINorMatSt.current.focus();
      return
    }

    if (valuesPassword.password.length == 0) {
      refpassword1.current.focus();
      return
    }

    /*if(!recaptchaVerified){
  
      return;
  }*/


    Auth(event);



  };






  return (

    <>
      <a style={{ display: 'none' }} href={valuelink}

        ref={ismobref}

        target="_blank"></a>




      <div>

        <Header />
        <div id="root_img">

        <Container component="main" maxWidth="md" sx={{ mt: 1 }} >

          <Grid
            container
            spacing={0}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            style={{ minHeight: '100vh' }}
          >

            <div className="container"  >

              <div className="row text-center">



                <Box
                  sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',

                  }}
                >



                  <Typography
                    sx={{
                      color: "#0d5193", // Text color
                      fontFamily: '"Tajawal", sans-serif', // Font family
                      fontWeight: 900, // Font weight
                      fontSize: 20, // Font size
                      backgroundColor: "#d6f6e045", // Background color
                      border: "2px solid #11da4d36", // Border
                      padding: "10px", // Padding
                      paddingLeft: "20px",
                    }}
                  >


                    <PersonIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> بوابة المتكون

                  </Typography>
                  <Box component="form" noValidate sx={{ mt: 1 }}>



                    {/* Email */}
                    <CacheProvider value={cacheRtl}>
                      <ThemeProvider theme={themetextfieldnew}>

                        <Box sx={{ mt: 2 }}>


                          <Grid container >


                            <Grid item xs={12}>

                              <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth variant="outlined"


                                >
                                  <BootstrapTooltip

                                    title="رقم التعريف الوطني الخاص بالمتكون أو رقم تسجيله"

                                    followCursor>


                                    <TextField

                                      sx={{

                                        '& .MuiFormHelperText-root': {
                                          fontFamily: '"Tajawal", sans-serif',
                                          fontSize: '14px',
                                          color: '#b31d3e',
                                          fontWeight: 700

                                        },
                                        '& .MuiFormLabel-root': {
                                          fontFamily: '"Tajawal", sans-serif',
                                          fontSize: '18px',
                                          color: '#191970',
                                          dir: 'rtl',

                                        },
                                        '& .MuiInputBase-input': {
                                          fontFamily: '"Tajawal", sans-serif',
                                          fontSize: '22px',
                                          color: '#191970',
                                          fontWeight: 700,
                                          //text-align: right,

                                        },

                                        '& label.Mui-focused': {
                                          fontSize: '16px',
                                          color: '#b31d3e',
                                          fontWeight: 700
                                        },
                                        '& .MuiInput-underline:after': {
                                          borderBottomColor: '#add8e6',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: '16px',
                                            borderColor: 'blue',
                                            borderWidth: "2px",

                                          },
                                          '&:hover fieldset': {
                                            borderWidth: "2px",
                                            borderColor: 'purple',
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderWidth: "2px",
                                            borderColor: 'purple',
                                          },
                                          borderWidth: "2px",
                                          borderColor: 'red', // Change to the color you desire
                                        },
                                        // Target placeholder specifically
                                        '& .MuiInputBase-input::placeholder': {
                                          fontSize: '14px', // Adjust the font size of the placeholder
                                        }
                                      }}

                                      inputRef={refNINorMatSt}
                                      label="رقم التسجيل"
                                      placeholder='رقم التعريف الوطني أو رقم التسجيل'



                                      onKeyPress={(ev) => {
                                        ///////console.log(`Pressed keyCode ${ev.key}`);
                                        if (ev.key === 'Enter') {
                                          handleSubmit(ev);
                                          ev.target.blur();///to hide keyboard

                                          ev.preventDefault();
                                        }
                                      }}

                                      ///  id={props.fieldName}                    
                                      /// label={props.label}
                                      //  name={props.fieldName}                    
                                      variant="outlined"
                                      size={'small'}
                                      // helperText={props.helperText}
                                      value={valueNINorMatSt}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={(e) => handleChangeemail(e)}
                                      name="email"
                                    />

                                  </BootstrapTooltip>

                                </FormControl>


                              </Box>
                            </Grid>

                          </Grid>
                        </Box>

                      </ThemeProvider>

                    </CacheProvider>



                    {/* Password 01 */}
                    <CacheProvider value={cacheRtl}>

                      <ThemeProvider theme={themetextfieldnew}>
                        <Box sx={{ mt: 2 }}>

                          <Grid container >


                            <Grid item xs={12}>

                              <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth variant="outlined"


                                >



                                  <InputLabel
                                    sx={{
                                      color: "#b31d3e", fontFamily:
                                        '"Tajawal", sans-serif',
                                      fontSize: 16
                                      , fontWeight: 700
                                    }}
                                    htmlFor="outlined-adornment-password">كلمة المرور</InputLabel>

                                  <OutlinedInput
                                    fullWidth
                                    dir="rtl"
                                    sx={{

                                      //direction:'ltr',

                                      '& .MuiFormHelperText-root': {
                                        fontFamily: '"Tajawal", sans-serif',
                                        fontSize: '14px',
                                        color: '#b31d3e',
                                        fontWeight: 700

                                      },
                                      '& .MuiFormLabel-root': {
                                        fontFamily: '"Tajawal", sans-serif',
                                        fontSize: '18px',
                                        color: '#191970',
                                        dir: 'rtl',

                                      },
                                      '& .MuiInputBase-input': {
                                        fontFamily: '"Tajawal", sans-serif',
                                        fontSize: '22px',
                                        color: '#191970',
                                        fontWeight: 700,
                                        //text-align: right,



                                      },
                                      '& label.Mui-focused': {
                                        fontSize: '16px',
                                        color: '#b31d3e',
                                        fontWeight: 700
                                      },
                                      '& .MuiInput-underline:after': {
                                        borderBottomColor: '#add8e6',
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        borderWidth: "2px",
                                        '& fieldset': {
                                          fontFamily: '"Tajawal", sans-serif',
                                          fontSize: '16px',
                                          borderColor: 'purple',
                                          borderWidth: "2px",

                                        },
                                        '&:hover fieldset': {
                                          borderColor: 'purple',
                                        },
                                        '&.Mui-focused fieldset': {

                                          borderColor: 'purple',
                                        },
                                      }
                                    }}

                                    onKeyPress={(ev) => {
                                      ///////console.log(`Pressed keyCode ${ev.key}`);
                                      if (ev.key === 'Enter') {
                                        handleSubmit(ev);
                                        ev.target.blur();///to hide keyboard
                                        /////console.log("enter")
                                        // Do code here
                                        ///////console.log(JSON.stringify(ev.target.value));
                                        //this.onSearchClickbtn();
                                        ev.preventDefault();
                                      }
                                    }}

                                    inputRef={refpassword1}
                                    id="outlined-adornment-password"
                                    type={valuesPassword.showPassword ? 'text' : 'password'}
                                    value={valuesPassword.password}
                                    onChange={handleChangePassword('password')}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {valuesPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="كلمة المرور"
                                    name="password"

                                  />




                                </FormControl>
                              </Box>
                            </Grid>

                          </Grid>
                        </Box>
                      </ThemeProvider>


                    </CacheProvider>

                    {/* 
<ReCAPTCHA style={{marginTop:'10px'}} ////6LckCXIpAAAAAO2Y87-XcGXMTbpkBIiNSNyhGWwl
        sitekey="6LckCXIpAAAAAO2Y87-XcGXMTbpkBIiNSNyhGWwl" ///6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
        onChange={handleRecaptchaChange}
        ref={refReCAPTCHA}
      />
      */}

                    <div>
                      <ReCAPTCHA style={{ marginTop: '10px' }}
                        ref={refReCAPTCHA}
                        sitekey="6LcCt3YpAAAAAK33Jnte_3V4gKcLl-kQgeV3qWMP"
                        onChange={handleRecaptchaChange}
                      // Additional optional props (e.g., action, verifyApiArgs)
                      />

                      {/* Submit button or other actions requiring the token */}
                    </div>



                    {/* Submit Button */}
                    <Grid item xs={12}>


                      <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={themetextfieldnew}>
                          <Box sx={{ minWidth: 120, mt: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                              onClick={handleSubmit}
                              variant="contained"
                              endIcon={<LockOpenIcon sx={{ mr: '5px', transform: 'rotate(0deg)' }} />}
                            >
                              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 22, fontWeight: 900 }}>
                                الدخول
                              </Typography>
                            </Button>
                          </Box>

                        </ThemeProvider>
                      </CacheProvider>

                    </Grid>

                    <CacheProvider value={cacheRtl}>

                      <ThemeProvider theme={themetextfieldnew}>

                        <Grid spacing={0} style={{ display: 'none' }}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          dir="rtl" container sx={{ mt: 2, }}>
                          <Grid item >
                            <Link href="/ResetPwDon" variant="body2"
                              sx={{
                                fontFamily:
                                  '"Tajawal", sans-serif',
                                fontSize: 12
                                , fontWeight: 900
                              }}>
                              هل نسيت كلمة السر؟
                            </Link>
                          </Grid>
                          <Grid item sx={{ mt: 1, }}>
                            <Link href="/RegisterDon" variant="body2"
                              sx={{
                                fontFamily:
                                  '"Tajawal", sans-serif',
                                fontSize: 12
                                , fontWeight: 900
                              }}>
                              {"هل تريد إنشاء حساب جديد؟"}
                            </Link>
                          </Grid>
                        </Grid>

                      </ThemeProvider>


                    </CacheProvider>



                  </Box>
                </Box>


                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {loading && <CircularProgress />}
                </Box>

                {!recaptchaVerified && !recaptchaVerified == '' ? <>
                  <Alert severity="error"
                    sx={{
                      mt: 2, display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                      , '& .MuiAlert-action': { marginRight: '8px' }
                    }} // Adjust padding here
                  >

                    لم يتم التحقق من reCAPTCHA بنجاح. يرجى المحاولة مرة أخرى.

                  </Alert>

                </> : <></>}

                {FailedAuth ? <>

                  <Alert severity="error"
                    sx={{
                      mt: 2, display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                      , '& .MuiAlert-action': { marginRight: '8px' }
                    }} // Adjust padding here
                  >
                    تأكد من صحة رقم التعريف الوطني أو رقم التسجيل وكلمة المرور التي أدخلتها.
                  </Alert>



                </> : <></>}


              </div>

            </div>



          </Grid>

        </Container>

        </div>
        
        <Footer />

      </div>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: '#add8e6',
            boxShadow: 'none',
          },
        }}
        //fullScreen={mediumScreen}
        open={OpenDialog}
        onClose={handleClickCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >

        <Card style={{}} sx={{ maxWidth: 345 }}>

          {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
          <CardHeader
            avatar={

              <InfoIcon sx={{ color: '#2196F3', width: 50, height: 50, ml: 2 }}></InfoIcon>

            }
            action={


              <IconButton onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml: -1, mt: -1 }}>

                <CloseIcon />

              </IconButton>
            }
            title={
              <>
                <Box sx={{ ml: 2, mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>

                  <Typography sx={{ color: '#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 14, fontWeight: 900 }} >
                    من الأفضل إستعمال متصفح كروم Chrome
                  </Typography>
                </Box>

              </>

            }
            subheader=""
          />





        </Card>

      </Dialog>




    </>

  );
}