import React, { useState, useEffect, useRef } from "react";
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from "react-router-dom";
import CardContent from '@mui/material/CardContent';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import localforage from 'localforage'; // Import localforage

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
} from "react-router-dom";
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography, Box } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReCAPTCHA from 'react-google-recaptcha';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from "@material-ui/core/styles";
import {
    useMediaQuery,
    useTheme,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import isEmail from 'validator/lib/isEmail';

const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
    direction: 'rtl',
    overrides: {
        MuiOutlinedInput: {
            root: {
                "&:hover $notchedOutline": {
                    borderColor: hoverColor
                },
                "&$focused $notchedOutline": {
                    borderColor: focusColor
                }
            },
            notchedOutline: {
                borderColor: defaultColor
            }
        }
    },
    fieldset: {
        fontFamily: '"Changa", sans-serif',
        fontSize: 45,
    }
});

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const themeChanga = createTheme({
    typography: {
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 16,
    },
});

const BootstrapTooltip = styled((props) => (
    <Tooltip {...props} arrow classes={{ popper: props.className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        ///color: theme.palette.common.black,
        color: 'orange',

    },

    [`& .${tooltipClasses.tooltip}`]: {
        ///backgroundColor: theme.palette.common.black,
        backgroundColor: 'orange',
        fontSize: '18px',
        fontWeight: 'bold',
        top: '-20px'
    },
}));

export default function Inscription() {

    const [isChecked, setIsChecked] = useState(false);
    const [open_info, setopen_info] = useState(false);
    const [selectedniveau_scolaires, setselectedniveau_scolaires] = useState(null);
    const [IDniveau_scolaires, setIDniveau_scolaires] = useState(null);

    const [IDCycle_scol_selected, setIDCycle_scol_selected] = useState(null);
    const [NumOrd_selected, setNumOrd_selected] = useState(null);

    const [options_niveau_scolaires, setoptions_niveau_scolaires] = useState([]);

    let optio_niveau_scolaires = [];
    let count_nivscol = 0;

    

    

    const handleniveau_scolairesChange = (event, values) => {

        
        if (values) {
            setselectedniveau_scolaires(values);
            setIDniveau_scolaires(values.value);
            ///alert("IDCycle_scol_selected: "+values.IDCycle_scol+" NumOrd: "+values.NumOrd);
            ///alert("IDCycle_scol: "+IDCycle_scol+" NumOrd: "+NumOrd);
            setIDCycle_scol_selected(values.IDCycle_scol);
            setNumOrd_selected(values.NumOrd);

        } else {
            return;
        }


    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };


    const [loadingdata_mfep, setloadingdata_mfep] = useState(false);
    const [Nom_NomFr_session, setNom_NomFr_session] = useState('');
    const [Nom_session, setNom_session] = useState('');

    const [AgeMin, setAgeMin] = useState(15);
    const [AgeMAx, setAgeMAx] = useState(0);

    const [IDCycle_scol, setIDCycle_scol] = useState(0);
    const [NumOrd, setNumOrd] = useState(0);



    const [Nom_specialite, setNom_specialite] = useState('');

    const [Nom_etablissement, setNom_etablissement] = useState('');
    const [Nom_mode_formation, setNom_mode_formation] = useState('');

    const [Nom_NomFr_qualification_dplm, setNom_NomFr_qualification_dplm] = useState('');
    const [Abr_AbrFr_qualification_dplm, setAbr_AbrFr_qualification_dplm] = useState('');
    const [Nom_NomFr_niveau_scol, setNom_NomFr_niveau_scol] = useState('');



    const [valueNom, setvalueNom] = useState('');
    const [valueNin, setvalueNin] = useState('');
    const refNin = useRef(null);

    const [valueNomFr, setvalueNomFr] = useState('');
    const refNomFr = useRef(null);
    const refNom = useRef(null);
    const Refselectedniveau_scolaires = useRef(null);

    const refvalueDateNais = useRef(null);
    const [valuePrenom, setvaluePrenom] = useState('');
    const refPrenom = useRef(null);
    const refPrenomPere = useRef(null);
    const refPrenomPereFr = useRef(null);

    const [valueNomMere, setvalueNomMere] = useState('');
    const [valueNomMereFr, setvalueNomMereFr] = useState('');
    const [valuePrenomMereFr, setvaluePrenomMereFr] = useState('');
    const [valuePrenomMere, setvaluePrenomMere] = useState('');


    const [valueLieuNaisFr, setvalueLieuNaisFr] = useState('');
    const [valueLieuNais, setvalueLieuNais] = useState('');

    const [valueAdres, setvalueAdres] = useState('');

    const [valuePrenomPere, setvaluePrenomPere] = useState('');
    const [valuePrenomPereFr, setvaluePrenomPereFr] = useState('');


    const [valueAdresFr, setvalueAdresFr] = useState('');
    const [valueDateNais, setvalueDateNais] = useState('');

    const [msgerror, setmsgerror] = useState('');
    const [fieldnameerror, setfieldnameerror] = useState('');

    const refLieuNais = useRef(null);
    const refLieuNaisFr = useRef(null);

    const refNomMere = useRef(null);
    const refNomMereFr = useRef(null);
    const refPrenomMere = useRef(null);
    const refPrenomMereFr = useRef(null);

    const [valuePrenomFr, setvaluePrenomFr] = useState('');
    const refPrenomFr = useRef(null);

    const refDateNais = useRef(null);
    const [loading, setLoading] = useState(true);


    const [alertMessageEdit, setAlertMessageEdit] = useState(null);

    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState(null);

    const [open_recaptcha, setOpen_recaptcha] = React.useState(false);
    const [open_check, setopen_check] = React.useState(false);

    const [open_valueDateNais, setopen_valueDateNais] = React.useState(false);

    const handleClose_check = () => {
        if (!open_info) {
            setopen_check(false);
        }
        //

    };

    const handleClose_recaptcha = () => {

        setOpen_recaptcha(false);

    };

    const handleOpen_recaptcha = () => {
        setOpen_recaptcha(true);
    };


    const [OpenDialog, setOpenDialog] = useState(false);


    const [errorget, setErrorget] = useState();

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };


    const handleClickCloseDialog = () => {
        setOpenDialog(false);

        navigate(`/Preinscriptionpdf/${crypted_id}`);
    };


    const [Civ, setCiv] = React.useState(1);
    const [endicape, setendicape] = React.useState(0);
    const [crypted_id, setcrypted_id] = React.useState(null);
    const [IDCandidat, setIDCandidat] = React.useState(null);

     

    const handleChange_Civ = (event) => {
        setCiv(event.target.value);
    };

    const handleChange_endicape = (event) => {
        setendicape(event.target.value);
    };



    const handlewlyChange = (event, values) => {

        if (values) {
            setSelectedwlys(values);
            setIDwly_selected(values.value);
        } else {
            return;
        }


    }



    const handlebldChange = (event, values) => {
        ///alert(values.value);
        setSelectedcommuns(values);
        setIDcommun_selected(values.value);

    };





    const [recaptchaVerified, setRecaptchaVerified] = useState(false); // Initially assuming reCAPTCHA is verified
    const refReCAPTCHA = useRef(null);
    const refcheckbox = useRef(null);



    const handleRecaptchaChange = (value) => {
        // handle reCAPTCHA response
        if (value) {
            // reCAPTCHA verification succeeded
            setRecaptchaVerified(true);
            // Proceed with your logic here
        } else {
            // reCAPTCHA verification failed
            setRecaptchaVerified(false);
            // Handle the failure scenario
        }
    };
    /*
    const handleRecaptchaChange = (e) => {
      setToken(e.target.value); // e.target.value should hold the token
    };*/



    const ArabicCharactersRegex = /^[\u0600-\u06FF\s]+$/;
    const ArabicCharactersRegex_withdash = /^[\u0600-\u06FF\s-_]+$/;
    const ArabicCharactersRegex_address = /^[\u0600-\u06FF\s-_\/0-9]+$/;
    const LatinLettersRegex = /^[a-zA-Z\s]+$/;
    const LatinLettersRegex_withdash = /^[a-zA-Z\s-_]+$/;
    const LatinLettersRegex_address = /^[a-zA-Z\s\-_\/0-9]+$/;


    const NumbersOnlyRegex = /^[0-9]+$/;
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const refemail = useRef(null);
    const refcodeemail = useRef(null);
    const [valueemail, setValueemail] = useState('');
    const [isValidemail, setIsValidemail] = useState(false);
    const refTEL1 = useRef(null);
    const [valueTEL1, setvalueTEL1] = useState('');

    const refTEL2 = useRef(null);
    const [valueTEL2, setvalueTEL2] = useState('');

    useEffect(() => {


        if (isEmail(valueemail) || valueemail === '' || !valueemail) {
            setIsValidemail(true);
            //console.log(valueemail+"   yes email");            
        } else {
            setIsValidemail(false);
            //console.log(valueemail+"   not email");                         
        }


    }, [valueemail]);


    const [age, setAge] = useState(0);
    let ageMinNum = isNaN(Number(AgeMin)) ? 0 : Number(AgeMin);
    let ageMaxNum = isNaN(Number(AgeMAx)) ? 0 : Number(AgeMAx);

    const [valueDateNais_year, setvalueDateNais_year] = useState("0");

    useEffect(() => {
        if (valueDateNais) {
            const currentDate = new Date();
            const birthDate = new Date(valueDateNais);
            const valueDateNais_year = valueDateNais ? new Date(valueDateNais).getFullYear().toString() : '';

            setvalueDateNais_year(valueDateNais_year);
            let calculatedAge = currentDate.getFullYear() - birthDate.getFullYear();
            const monthDiff = currentDate.getMonth() - birthDate.getMonth();
            const dateDiff = currentDate.getDate() - birthDate.getDate();



            // Adjust age if the birth month hasn't occurred yet this year or it's the current month but the birth day hasn't occurred yet
            if (monthDiff < 0 || (monthDiff === 0 && dateDiff < 0)) {
                calculatedAge--;
            }

            setAge(calculatedAge);
        } else {
            setAge(null);
        }
    }, [valueDateNais]);

    const handleChangeemail = event => {
        const val = event.target.value;
        setValueemail(val);

        /////handleChangeemail(val, isValidemail);
    }



    const handleInput_TEL1 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue === '' ? '0' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

        if ((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) {
            setvalueTEL1(inputValue);
        }
    };


    const handleInput_TEL2 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue === '' ? '0' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

        if ((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) {
            setvalueTEL2(inputValue);
        }
    };



    const refbtnsubmit = useRef(null);
    const refpassword1 = useRef(null);
    const refpassword2 = useRef(null);
    const refpasswordnow = useRef(null);
    const refAdres = useRef(null);
    const refAdresFr = useRef(null);



    const [valuesPassword, setValuesPassword] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
        amount: '',
        PasswordRe: '',
        weight: '',
        weightRange: '',
        showPasswordRe: false,
    });

    const [valuespasswordnow, setValuespasswordnow] = React.useState({
        amount: '',
        passwordnow: '',
        weight: '',
        weightRange: '',
        showpasswordnow: false,
    });


    const handleChangePasswordRe = (prop) => (event) => {
        setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
    };

    const handleChangepasswordnow = (prop) => (event) => {
        setValuespasswordnow({ ...valuespasswordnow, [prop]: event.target.value });
    };

    const handleClickShowPasswordRe = () => {
        setValuesPasswordRe({
            ...valuesPasswordRe,
            showPasswordRe: !valuesPasswordRe.showPasswordRe,
        });
    };

    const handleClickShowpasswordnow = () => {
        setValuespasswordnow({
            ...valuespasswordnow,
            showpasswordnow: !valuespasswordnow.showpasswordnow,
        });
    };

    const handleMouseDownPasswordRe = (event) => {
        event.preventDefault();
    };

    const handleMouseDownpasswordnow = (event) => {
        event.preventDefault();
    };


    const handleChangePassword = (prop) => (event) => {
        setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValuesPassword({
            ...valuesPassword,
            showPassword: !valuesPassword.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    const navigate = useNavigate();
    const { IDOffre } = useParams();
    const { IDsession_selected } = useParams();
    const { IDDFEP_selected } = useParams();

    useEffect(() => {
        let localDatasetsuccess = false;

        // Check if IDOffre exists
        if (isNaN(IDOffre) || isNaN(IDsession_selected) || isNaN(IDDFEP_selected)) {
            // navigate('/Offersinscription');
            /// return;
        }

        const fetchData = async () => {



            try {
                const localData = await localforage.getItem(`dataOffersinscription_${IDsession_selected}_${IDDFEP_selected}`);
                if (localData && localData.data && localData.data.length > 0) {
                    const row = localData.data.find(row => row.IDOffre === parseInt(IDOffre, 10));
                    if (row) {

                        setNom_NomFr_session(row.Nom_NomFr_session);
                        setNom_session(row.Nom_NomFr_session);
                        setNom_specialite(row.Nom_NomFr_CodeSpec_NbrSem_specialite);
                        setNom_etablissement(row.code_Nom_etablissement);
                        setNom_mode_formation(row.Nom_NomFr_mode_formation);
                        setNom_NomFr_qualification_dplm(row.Nom_NomFr_qualification_dplm);
                        setAbr_AbrFr_qualification_dplm(row.Abr_AbrFr_qualification_dplm);
                        setNom_NomFr_niveau_scol(row.Nom_NomFr_niveau_scol);

                        ageMinNum = isNaN(Number(row.AgeMin)) ? 0 : Number(row.AgeMin);
                        setAgeMin(ageMinNum);


                        setAgeMAx(isNaN(Number(row.AgeMAx)) ? 0 : Number(row.AgeMAx));

                        setIDCycle_scol(row.IDCycle_scol);
                        setNumOrd(row.NumOrd);



                        setloadingdata_mfep(true);
                        localDatasetsuccess = true;
                    }

                }
            } catch (error) {
                console.error('Error fetching data from localForage:', error);
            }


            if (localDatasetsuccess) { return; }


            const url_checkifidoffreexist = `${API_BASE_URL}/api/isIDOffre_exist/${IDOffre}`;

            axios.get(url_checkifidoffreexist)
                .then((response) => {
                    /// console.log("Data:", JSON.stringify(response.data));

                    if (response.data && response.data.data && response.data.data.length > 0) {
                        const responseData = response.data.data[0];
                        setNom_NomFr_session(responseData.Nom_NomFr_session);
                        setNom_session(responseData.Nom_session);
                        setNom_specialite(responseData.Nom_NomFr_CodeSpec_NbrSem_specialite);
                        setNom_etablissement(responseData.code_Nom_etablissement);
                        setNom_mode_formation(responseData.Nom_NomFr_mode_formation);
                        setNom_NomFr_qualification_dplm(responseData.Nom_NomFr_qualification_dplm);
                        setAbr_AbrFr_qualification_dplm(responseData.Abr_AbrFr_qualification_dplm);
                        setNom_NomFr_niveau_scol(responseData.Nom_NomFr_niveau_scol);
                        ageMinNum = isNaN(Number(responseData.AgeMin)) ? 0 : Number(responseData.AgeMin);
                        setAgeMin(ageMinNum);
                        setAgeMAx(isNaN(Number(responseData.AgeMAx)) ? 0 : Number(responseData.AgeMAx));
                        setIDCycle_scol(responseData.IDCycle_scol);
                        setNumOrd(responseData.NumOrd);

                        // Set other state variables as needed
                        setloadingdata_mfep(true);
                    } else {
                        // Handle case where data is empty
                        // navigate('/Offersinscription');
                        // return;
                        console.error("No data returned from the server.");
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    navigate('/Offersinscription');
                    return;
                });


        };

        fetchData();






    }, [IDOffre, IDsession_selected, IDDFEP_selected]);


    // Regular expression to match the format "YYYY/MM/DD"
    const dateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

    // Regular expression to match the format "YYYY/MM/DD" with digits for years, months, and days
    const fullDateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

    const handleInput_DateNais = (event) => {
        ///alert('inputValue');
        const inputValue = event.target.value;
        setvalueDateNais(inputValue);
    };


    const handleInput_LieuNais = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex_withdash.test(inputValue) || inputValue === '') {
            setvalueLieuNais(inputValue);
        }
    };

    const handleInput_LieuNaisFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex_withdash.test(inputValue) || inputValue === '') {
            setvalueLieuNaisFr(inputValue);
        }
    };

    const handleInput_Nin = (event) => {
        const inputValue = event.target.value;

        if ((NumbersOnlyRegex.test(inputValue) && inputValue.length <= 18) || inputValue === '') {
            setvalueNin(inputValue);
        }
    };

    const handleInput_Nom = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvalueNom(inputValue);
        }
    };


    const handleInput_NomFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvalueNomFr(inputValue);
        }
    };


    const handleInput_Adres = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex_address.test(inputValue) || inputValue === '') {
            setvalueAdres(inputValue);
        }
    };

    const handleInput_PrenomMere = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomMere(inputValue);
        }
    };

    const handleInput_NomMere = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvalueNomMere(inputValue);
        }
    };

    const handleInput_Prenom = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenom(inputValue);
        }
    };

    const handleInput_PrenomPere = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomPere(inputValue);
        }
    };


    const handleInput_PrenomFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomFr(inputValue);
        }
    };



    const handleInput_AdresFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex_address.test(inputValue) || inputValue === '') {
            setvalueAdresFr(inputValue);
        }
    };



    const handleInput_PrenomMereFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomMereFr(inputValue);
        }
    };

    const handleInput_NomMereFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvalueNomMereFr(inputValue);
        }
    };


    const handleInput_PrenomPereFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomPereFr(inputValue);
        }
    };





    const onsubmitclicked = async (e) => {
        e.preventDefault();

        try {
            const data = {
                Nom: valueNom,
                NomFr: valueNomFr,
                Prenom: valuePrenom,
                PrenomFr: valuePrenomFr,
                Password: valuesPassword.password,
                LieuNaisFr: valueLieuNaisFr,
                LieuNais: valueLieuNais,
                PrenomPereFr: valuePrenomPereFr,
                NomMereFr: valueNomMereFr,
                PrenomMereFr: valuePrenomMereFr,
                AdresFr: valueAdresFr,
                PrenomPere: valuePrenomPere,
                NomMere: valueNomMere,
                PrenomMere: valuePrenomMere,
                Adres: valueAdres,
                Civ: Civ,
                DateNais: dayjs(valueDateNais).format('YYYY-MM-DD'),
                IDwly_selected: IDwly_selected,
                IDcommun_selected: IDcommun_selected,
                IDOffre: IDOffre,
                valueNin: valueNin,
                valueemail: valueemail,
                valueTEL1: valueTEL1,
                valueTEL2: valueTEL2,
                IDniveau_scolaires: IDniveau_scolaires,
                endicape:endicape

            };

            const responseFromApi = await axios.post(`${API_BASE_URL}/api/insertnewcandidat`, data);

            // Log the entire response data object to the console
            console.log('Response data:', responseFromApi.data);

            // Check if response has data
            if (responseFromApi.data && responseFromApi.data.crypted_id) {
                ///alert(responseFromApi.data.crypted_id);
                setcrypted_id(responseFromApi.data.crypted_id);
                setIDCandidat(responseFromApi.data.IDCandidat);
                // Handle successful response
                setOpenDialog(true);
            } else {
                // Handle empty or missing crypted_id in the response
                console.error('Missing crypted_id in response data');
            }


        } catch (error) {
            // Check if error has response
            if (error.response) {
                // Check if error response has data
                if (error.response.data && error.response.data.errors) {
                    // Extract error messages from the response
                    const errors = error.response.data.errors;

                    // Construct error message string
                    let errorMessage = '';
                    for (const field in errors) {
                        errorMessage += `${errors[field][0]}\n`;
                    }

                    // Display error message
                    //// alert(errorMessage);
                } else {
                    // Handle error without error message
                    ////alert('An error occurred');
                }
            } else {
                // Handle network error or other types of errors
                //alert('An error occurred');
            }
        }
    };



    const [selectedwlys, setSelectedwlys] = useState(null);
    const [IDwly_selected, setIDwly_selected] = useState(1);
    const [IDcommun_selected, setIDcommun_selected] = useState(0);


    const [options_wly, setoptions_wly] = useState([]);
    let options_bran = [];

    const apiUrl_wly = `${API_BASE_URL}/api/all_wilayas`;
    const apiUrl_niveau_scolaires = `${API_BASE_URL}/api/getniveau_scol`;

    // Fetch data from the API when the component mounts
    useEffect(() => {

        setloadingdata_mfep(true);

        fetch(apiUrl_wly)
            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const Wlylists = data.data; // Extracting the relevant data
                ////console.log('Wlylists: '+Wlylists);
                /////////////Wilayets///////////////////

                const WlyOnly = Wlylists.filter(Wlylist => Wlylist.libelleArabe !== null);

                /*
                IDwilayas
                id
                code
                clibelleArabe
                clibelleLatin
                libelleArabe
                libelleLatin

                */

                // Sorting in descending order (from Z to A)
                options_bran = WlyOnly.sort((a, b) => Number(a.IDwilayas) - Number(b.IDwilayas))
                    .map((Wlylist, i) => ({
                        value: Wlylist.IDwilayas,
                        label: Wlylist.code + ' | ' + Wlylist.libelleArabe + ' | ' + Wlylist.libelleLatin,
                        indexvalue: i
                    }));

                setoptions_wly(options_bran);


            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });




        fetch(apiUrl_niveau_scolaires)

            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const Wlylists = data.data; // Extracting the relevant data

                /////////////apiUrl_niveau_scolaires///////////////////

                const WlyOnly = Wlylists.filter(Wlylist => Wlylist.libelleArabe !== null);

                /*
         IDNiveau_Scol
IDCycle_scol
NumOrd
Nom
NomFr
Abr
AbrFr
iDMihnati
activee

    
                */

                // Sorting in descending order (from Z to A)
                optio_niveau_scolaires = WlyOnly.sort((a, b) => Number(b.libelleArabe) - Number(a.libelleArabe))
                    .map((Wlylist, i) => ({
                        value: Wlylist.IDNiveau_Scol,
                        label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
                        indexvalue: i,
                        IDCycle_scol: Wlylist.IDCycle_scol,
                        NumOrd: Wlylist.NumOrd
                    }));

                ///// options_niveau_scolaires.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

                setoptions_niveau_scolaires(optio_niveau_scolaires);

                ///console.log('optio_niveau_scolaires[0].value:' + optio_niveau_scolaires[0].value);
                setloadingdata_mfep(false);

            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });


    }, []);


    
    const handleniveau_scolairesClick = (event, values) => {

        if (options_niveau_scolaires.length>0){
            return;
        }

        setloadingdata_mfep(true);


        fetch(apiUrl_niveau_scolaires)

        .then(response => response.json())
        .then(data => {
            // Assuming 'data' is an array of objects
            const Wlylists = data.data; // Extracting the relevant data

            /////////////apiUrl_niveau_scolaires///////////////////

            const WlyOnly = Wlylists.filter(Wlylist => Wlylist.libelleArabe !== null);

            /*
     IDNiveau_Scol
IDCycle_scol
NumOrd
Nom
NomFr
Abr
AbrFr
iDMihnati
activee


            */

            // Sorting in descending order (from Z to A)
            optio_niveau_scolaires = WlyOnly.sort((a, b) => Number(b.libelleArabe) - Number(a.libelleArabe))
                .map((Wlylist, i) => ({
                    value: Wlylist.IDNiveau_Scol,
                    label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
                    indexvalue: i,
                    IDCycle_scol: Wlylist.IDCycle_scol,
                    NumOrd: Wlylist.NumOrd
                }));

            ///// options_niveau_scolaires.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

            setoptions_niveau_scolaires(optio_niveau_scolaires);

            ///console.log('optio_niveau_scolaires[0].value:' + optio_niveau_scolaires[0].value);
            setloadingdata_mfep(false);

        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });



    }

  


  


 
    const handleonclick_wilylist = (event, values) => {

        if (options_wly.length>0){
            return;
        }

    setloadingdata_mfep(true);

    fetch(apiUrl_wly)
        .then(response => response.json())
        .then(data => {
            // Assuming 'data' is an array of objects
            const Wlylists = data.data; // Extracting the relevant data
            ////console.log('Wlylists: '+Wlylists);
            /////////////Wilayets///////////////////

            const WlyOnly = Wlylists.filter(Wlylist => Wlylist.libelleArabe !== null);

            /*
            IDwilayas
            id
            code
            clibelleArabe
            clibelleLatin
            libelleArabe
            libelleLatin

            */

            // Sorting in descending order (from Z to A)
            options_bran = WlyOnly.sort((a, b) => Number(a.IDwilayas) - Number(b.IDwilayas))
                .map((Wlylist, i) => ({
                    value: Wlylist.IDwilayas,
                    label: Wlylist.code + ' | ' + Wlylist.libelleArabe + ' | ' + Wlylist.libelleLatin,
                    indexvalue: i
                }));

            setoptions_wly(options_bran);


        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });



    }






    useEffect(() => {
        if (options_wly && options_wly.length > 0) {
            setSelectedwlys(options_wly[0]);
            setIDwly_selected(options_wly[0].value);
        }

    }, [options_wly]);



    const [selectedcommuns, setSelectedcommuns] = useState(null);
    const [options_commun, setoptions_commun] = useState([]);
    let options_commu = [];


    useEffect(() => {
        if (options_commun && options_commun.length > 0) {
            setSelectedcommuns(options_commun[0]);
            setIDcommun_selected(options_commun[0].value);
            /// alert(options_commun[0].value);
        }

    }, [options_commun]);




    // Fetch data from the API when the component mounts
    useEffect(() => {
        if (!IDwly_selected || isNaN(IDwly_selected) || IDwly_selected <= 0) {
            return;
        }

        const apiUrl_commun = `${API_BASE_URL}/api/all_communn/${IDwly_selected}`;

        setloadingdata_mfep(true);

        fetch(apiUrl_commun)
            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const communlists = data.data; // Extracting the relevant data
                ///console.log('communlists: ' + communlists);

                const communOnly = communlists.filter(communlist => communlist.Nom !== null);

                /*
               IDCommunn
                IDWilayaa
                Nom
                NomFr
                Code
                CodeNouv
                NomEtat
                CODE_GEO


                */

                // Sorting in descending order (from Z to A)
                options_commu = communOnly.sort((a, b) => Number(a.IDCommunn) - Number(b.IDCommunn))
                    .map((communlist, i) => {
                        let label;
                        if (communlist.NomFr && communlist.NomFr.trim() !== '') {
                            label = `${communlist.Nom} | ${communlist.NomFr}`;
                        } else {
                            label = communlist.Nom;
                        }
                        return {
                            value: communlist.IDCommunn,
                            label: label,
                            indexvalue: i
                        };
                    });



                setoptions_commun(options_commu);

            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });




    }, [IDwly_selected]);



    const useStyles_listdfep = makeStyles((theme) => ({
        root: {
            "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                // Default transform is "translate(14px, 20px) scale(1)""
                // This lines up the label with the initial cursor position in the input
                // after changing its padding-left.
                transform: "translate(34px, 20px) scale(1);",
                fontFamily:
                    '"Tajawal", sans-serif',
                fontSize: 18,
                direction: "rtl"
            }
        },
        inputRoot: {

            color: "#191970",


            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                fontFamily: '"Tajawal", sans-serif',
                fontWeight: 700,
                color: "#191970",
                // Default left padding is 6px
                paddingLeft: 26
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderColor: "#add8e6"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderColor: "red"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderColor: "purple"
            }
        }
    }));

    const classes_listdfep = useStyles_listdfep();




    const useStyles = makeStyles({
        cancelBtn: {
            backgroundColor: "#4caf50", // أخضر
            color: "#fff",
            "&:hover": {
                backgroundColor: "#388e3c",
            },
        },
        yesBtn: {
            backgroundColor: "#f44336", // أحمر
            color: "#fff",
            "&:hover": {
                backgroundColor: "#d32f2f",
            },
        },
    });

    const classes = useStyles();


    return (
        <>
            {loadingdata_mfep ? <>

                <Box sx={{ width: '100%' }}>
                    <LinearProgress color="success" />
                </Box>

            </> : null}








            <Header />
            <div >

                <form>
                    <Container
                    >
                        <Grid  >


                            <Box
                                sx={{
                                    marginTop: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                }}
                            >

                                <Typography
                                    sx={{
                                        color: "#0d5193", // Text color
                                        fontFamily: '"Tajawal", sans-serif', // Font family
                                        fontWeight: 900, // Font weight
                                        fontSize: 20, // Font size
                                        backgroundColor: "#d6f6e045", // Background color
                                        border: "2px solid #11da4d36", // Border
                                        padding: "10px", // Padding
                                        paddingLeft: "20px",
                                    }}
                                >

                                    <DescriptionIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> إستمارة التسجيل ( {Nom_session} )

                                </Typography>

                                {!Nom_etablissement ? <>

                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress color="success" />
                                    </Box>

                                </> :

                                    <Typography
                                        sx={{
                                            color: 'grey', // Text color
                                            fontFamily: '"Tajawal", sans-serif', // Font family
                                            fontWeight: 900, // Font weight
                                            fontSize: 16, // Font size
                                            padding: "4px",
                                            direction: 'rtl',
                                            textAlign: 'right'
                                        }}
                                    >
                                        <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_etablissement}
                                        <br />
                                        <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_mode_formation}
                                        <br />
                                        <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_specialite}
                                        {Nom_NomFr_qualification_dplm && (
                                            <>
                                                <br />
                                                <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_NomFr_qualification_dplm}

                                            </>

                                        )}

                                        {Nom_NomFr_niveau_scol && (<>
                                            <br />
                                            <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_NomFr_niveau_scol}

                                        </>

                                        )}



                                        <br />
                                        <br />
                                    </Typography>

                                }
                            </Box>


                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1, mb: 1 }} justifyContent="center" >


                                <Grid item xs={isMatch ? 12 : 6} >
                                     
                                <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth>
                                                        <InputLabel sx={{
                                                            fontFamily: '"Tajawal", sans-serif',
                                                            fontSize: '16px',
                                                            color: '#191970'
                                                        }} id="endicape-simple-select-label">ذوي الاحتياجات الخاصة</InputLabel>
                                                        <Select
                                                            labelId="endicape-simple-select-label"
                                                            id="endicape-simple-select"
                                                            value={endicape}
                                                            label="endicape"
                                                            onChange={handleChange_endicape}

                                                            sx={{

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700
                                                                },

                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    color: '#191970'
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#191970',
                                                                    fontWeight: 700
                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#add8e6',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#add8e6',
                                                                        borderWidth: "2px",
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'purple',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: 'purple',
                                                                    },
                                                                }
                                                            }}
                                                        >
                                                         

                                                            <MenuItem
                                                            style={{
                                                                fontFamily: '"Tajawal", sans-serif',
                                                            
                                                                fontSize: 18,
                                                                fontWeight: 700,
                                                                color: '#191970',
                                                                textAlign: "right",
                                                                direction: 'rtl',
                                                                backgroundColor: '#add8e6'
                                                            }}
                                                            dir="rtl"
                                                             value={0}>لست من ذوي الإحتياجات الخاصة</MenuItem>
                                                            <MenuItem 
                                                            style={{
                                                                fontFamily: '"Tajawal", sans-serif',
                                                            
                                                                fontSize: 18,
                                                                fontWeight: 700,
                                                                color: '#191970',
                                                                textAlign: "right",
                                                                direction: 'rtl',
                                                                backgroundColor: '#FFFFFF'
                                                            }}
                                                            dir="rtl"
                                                            value={1}>انا من ذوي الاحتياجات الخاصة</MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1, mb: 2 }} justifyContent="center" >


                                <Grid item xs={isMatch ? 12 : 6}  >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <Autocomplete
                                                        noOptionsText={'المستوي غير موجود'}
                                                        classes={classes_listdfep}
                                                        id="combo-box-niveau_scolaires"
                                                        value={selectedniveau_scolaires}
                                                        options={options_niveau_scolaires}
                                                        onChange={handleniveau_scolairesChange}
                                                        renderOption={(props, option, index) => {
                                                            const { label } = option;
                                                            count_nivscol++;
                                                            return (
                                                                <span
                                                                    key={count_nivscol}  // Use count_nivscol as the key
                                                                    {...props}
                                                                    style={{
                                                                        backgroundColor: count_nivscol % 2 === 0 ? '#add8e6' : '#FFFFFF',
                                                                        color: '#191970',
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: 18,
                                                                        fontWeight: 700,
                                                                        textAlign: "right",
                                                                        direction: 'rtl'
                                                                    }}
                                                                    dir="ltr"
                                                                >
                                                                    {count_nivscol === -1 ? '- ' + label : label}
                                                                </span>
                                                            );
                                                        }}

                                                        renderInput={(params) => (

                                                            <TextField
                                                                {...params}
                                                                
                                                                placeholder="المستوى الدراسي"
                                                                onClick={handleniveau_scolairesClick}

                                                                error={
                                                                    endicape === 1 
                                                                        ? !selectedniveau_scolaires 
                                                                        : (!selectedniveau_scolaires || 
                                                                            (Number(IDCycle_scol_selected) < Number(IDCycle_scol)) || 
                                                                            (Number(IDCycle_scol_selected) === Number(IDCycle_scol) && Number(NumOrd_selected) < Number(NumOrd)))
                                                                }
                                                                helperText={
                                                                    endicape === 1
                                                                        ? !selectedniveau_scolaires 
                                                                            ? 'إختر المستوى الدراسي'
                                                                            : ''
                                                                        : !selectedniveau_scolaires
                                                                            ? 'إختر المستوى الدراسي'
                                                                            : (Number(IDCycle_scol_selected) < Number(IDCycle_scol) ||
                                                                                (Number(IDCycle_scol_selected) === Number(IDCycle_scol) && Number(NumOrd_selected) < Number(NumOrd)))
                                                                                ? 'غير مسموح لك التسجيل في هذا العرض'
                                                                                : ''
                                                                }


                                                                tabIndex={1}
                                                                dir="rtl" // Add dir="rtl" here
                                                                inputRef={Refselectedniveau_scolaires}
                                                                fullWidth
                                                                sx={{

                                                                    '& .MuiFormHelperText-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '14px',
                                                                        color: '#b31d3e',
                                                                        fontWeight: 700
                                                                    },

                                                                    '& .MuiFormLabel-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        color: '#191970'
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '18px',
                                                                        color: '#191970',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& label.Mui-focused': {
                                                                        fontSize: '16px',
                                                                        color: '#b31d3e',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#add8e6',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '16px',
                                                                            borderColor: '#add8e6',
                                                                            borderWidth: "2px",
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                    }
                                                                }}
                                                            />


                                                        )}



                                                    />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>





                            </Grid>



                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                {/* اللقب بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNom"
                                                        value={valueNom}
                                                        inputRef={refNom}
                                                        onInput={handleInput_Nom}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valueNom)
                                                                ? 'اللقب (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 1 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNom`}

                                                        label="اللقب (ع)"
                                                        placeholder="اللقب (ع)"
                                                        variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>



                                {/* اللقب باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNomFr"
                                                        value={valueNomFr}
                                                        inputRef={refNomFr}
                                                        onInput={handleInput_NomFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valueNomFr)
                                                                ? 'اللقب (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 2 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueNomFr" label="اللقب (ف)" placeholder="اللقب (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>

                            </Grid>




                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* الاسم بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenom"
                                                        value={valuePrenom}
                                                        inputRef={refPrenom}
                                                        onInput={handleInput_Prenom}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valuePrenom)
                                                                ? 'الإسم (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 3 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenom" label="الإسم (ع)" placeholder="الإسم (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>





                                {/* الإسم باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomFr"
                                                        value={valuePrenomFr}
                                                        inputRef={refPrenomFr}
                                                        onInput={handleInput_PrenomFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valuePrenomFr)
                                                                ? 'الإسم (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 4 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomFr" label="الإسم (ف)" placeholder="الإسم (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>





                            </Grid>

                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* النوع */}
                                <Grid item xs={isMatch ? 12 : 3} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth>
                                                        <InputLabel sx={{
                                                            fontFamily: '"Tajawal", sans-serif',
                                                            fontSize: '16px',
                                                            color: '#191970'
                                                        }} id="Civ-simple-select-label">النوع</InputLabel>
                                                        <Select
                                                            labelId="Civ-simple-select-label"
                                                            id="Civ-simple-select"
                                                            value={Civ}
                                                            label="Civ"
                                                            onChange={handleChange_Civ}




                                                            sx={{

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700
                                                                },

                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    color: '#191970'
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#191970',
                                                                    fontWeight: 700
                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#add8e6',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#add8e6',
                                                                        borderWidth: "2px",
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'purple',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: 'purple',
                                                                    },
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem value={1}>ذكر</MenuItem>
                                                            <MenuItem value={2}>أنثى</MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>

                                {/*  تاريخ الميلاد */}
                                {/*  <Tooltip open={open_valueDateNais && valueDateNais===''}  title="تاريخ الميلاد">
                                </Tooltip>
                                */}

                                <Grid item xs={isMatch ? 12 : 3} sx={{ mt: '-7px' }} >

                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker']}>
                                                            <DatePicker
                                                                ref={refvalueDateNais}
                                                                name="valueDateNais"
                                                                value={valueDateNais}
                                                                slotProps={{
                                                                    /*
                                                                     // Calculate age
                                                                const currentDate = new Date();
                                                                const birthDate = new Date(valueDateNais);
                                                                const age = currentDate.getFullYear() - birthDate.getFullYear();

                                                                    */
                                                                    textField: {
                                                                        error: !valueDateNais || (AgeMin !== 0 && (Number(age) < AgeMin || (AgeMAx > AgeMin && Number(age) > AgeMAx))),
                                                                        helperText: (() => {
                                                                            const ageNum = Number(age);


                                                                            if (!valueDateNais) {
                                                                                return 'أدخل تاريخ الميلاد';
                                                                            } else if (AgeMin === 0) {
                                                                                return '';
                                                                            } else if (ageNum < AgeMin) {
                                                                                return `يجب أن يكون على الأقل ${AgeMin} عامًا`;
                                                                            } else if (ageNum > AgeMAx && AgeMAx > AgeMin) {
                                                                                return `يجب ان يكون أكبر من ${AgeMin} عاما و أقل من ${AgeMAx} عاما`;
                                                                            } else {
                                                                                return '';
                                                                            }
                                                                        })(),
                                                                    }

                                                                }}


                                                                inputRef={refDateNais}

                                                                onChange={(newValue) => setvalueDateNais(newValue)}
                                                                format="YYYY/MM/DD" // Set the desired format here
                                                                placeholder="YYYY/MM/DD"

                                                                label=" تاريخ ميلاد "



                                                                sx={{

                                                                    '& .MuiFormHelperText-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '14px',
                                                                        color: '#b31d3e',
                                                                        fontWeight: 700
                                                                    },

                                                                    '& .MuiFormLabel-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        color: '#191970'
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '18px',
                                                                        color: '#191970',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& label.Mui-focused': {
                                                                        fontSize: '16px',
                                                                        color: '#b31d3e',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#add8e6',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '16px',
                                                                            borderColor: '#add8e6',
                                                                            borderWidth: "2px",
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                    }
                                                                }}

                                                            />


                                                        </DemoContainer>
                                                    </LocalizationProvider>




                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>



                                <Grid item xs={isMatch ? 12 : 3} >

                                    <Autocomplete
                                        noOptionsText={'حدث الصفحة مرة أخرى'}
                                        classes={classes_listdfep}
                                        id="combo-box-demowly"
                                        value={selectedwlys}
                                        options={options_wly}
                                        defaultValue={options_wly[0]}
                                        onChange={handlewlyChange}

                                        renderOption={(props, option) => {
                                            const { label, indexvalue } = option;
                                            return (
                                                <span
                                                    {...props}
                                                    style={{
                                                        backgroundColor: indexvalue % 2 === 0 ? '#add8e6' : '#FFFFFF',
                                                        color: '#191970',
                                                        fontFamily: '"Tajawal", sans-serif',
                                                        fontSize: 18,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {indexvalue === -1 ? '- ' + label : label}
                                                </span>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <Box display="flex" justifyContent="center">
                                                <TextField 
                                                onClick={handleonclick_wilylist}
                                                
                                                fullWidth {...params} label="الولاية" placeholder="الولاية" />
                                            </Box>
                                        )}
                                    />
                                </Grid>




                                <Grid item xs={isMatch ? 12 : 3} >

                                    <Autocomplete
                                        noOptionsText={'حدث الصفحة مرة أخرى'}
                                        classes={classes_listdfep}
                                        id="combo-box-demobld"
                                        value={selectedcommuns}
                                        options={options_commun}
                                        onChange={handlebldChange}


                                        renderOption={(props, option) => {
                                            const { label, indexvalue } = option;
                                            return (
                                                <span
                                                    {...props}
                                                    style={{
                                                        backgroundColor: indexvalue % 2 === 0 ? '#add8e6' : '#FFFFFF',
                                                        color: '#191970',
                                                        fontFamily: '"Tajawal", sans-serif',
                                                        fontSize: 18,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {indexvalue === -1 ? '- ' + label : label}
                                                </span>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <Box display="flex" justifyContent="center">
                                                <TextField fullWidth {...params} label="البلدية" placeholder="البلدية" />
                                            </Box>
                                        )}
                                    />
                                </Grid>





                                {/* مكان الميلاد بالعربية */}
                                {/*                                  
                                <Grid item xs={isMatch ? 6 : 3} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueLieuNais"
                                                        value={valueLieuNais}
                                                        inputRef={refLieuNais}
                                                        onInput={handleInput_LieuNais}
                                                        helperText={
                                                            !ArabicCharactersRegex_withdash.test(valueLieuNais)
                                                                ? 'مكان الميلاد (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 7 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueLieuNais" label="مكان الميلاد (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>

*/}



                                {/* مكان الميلاد باللاتنية */}
                                {/*  
                                <Grid item xs={isMatch ? 6 : 3} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valueLieuNaisFr"
                                                        value={valueLieuNaisFr}
                                                        inputRef={refLieuNaisFr}
                                                        onInput={handleInput_LieuNaisFr}
                                                        helperText={
                                                            !LatinLettersRegex_withdash.test(valueLieuNaisFr)
                                                                ? 'مكان الميلاد (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 8 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueLieuNaisFr" label="مكان الميلاد (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>


*/}







                            </Grid>





                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* العنوان بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueAdres"
                                                        value={valueAdres}
                                                        inputRef={refAdres}
                                                        onInput={handleInput_Adres}
                                                        helperText={
                                                            !ArabicCharactersRegex_address.test(valueAdres)
                                                                ? 'العنوان (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 9 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueAdres" label="العنوان (ع)" placeholder="العنوان (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>





                                {/* العنوان باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valueAdresFr"
                                                        value={valueAdresFr}
                                                        inputRef={refAdresFr}
                                                        onInput={handleInput_AdresFr}
                                                        helperText={
                                                            !LatinLettersRegex_address.test(valueAdresFr)
                                                                ? 'العنوان (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 10 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueAdresFr" label="العنوان (ف)" placeholder="العنوان (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>










                            </Grid>







                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* إسم الأب بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomPere"
                                                        value={valuePrenomPere}
                                                        inputRef={refPrenomPere}
                                                        onInput={handleInput_PrenomPere}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valuePrenomPere)
                                                                ? 'إسم الأب (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 11 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomPere" label="إسم الأب (ع)" placeholder="إسم الأب (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>





                                {/* إسم الأب باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomPereFr"
                                                        value={valuePrenomPereFr}
                                                        inputRef={refPrenomPereFr}
                                                        onInput={handleInput_PrenomPereFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valuePrenomPereFr)
                                                                ? 'إسم الأب (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 12 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomPereFr" label="إسم الأب (ف)" placeholder="إسم الأب (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>










                            </Grid>






                            <Grid container rowSpacing={1} sx={{ mt: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                {/* لقب الأم بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNomMere"
                                                        value={valueNomMere}
                                                        inputRef={refNomMere}
                                                        onInput={handleInput_NomMere}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valueNomMere)
                                                                ? 'لقب الأم (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 13 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueNomMere" label="لقب الأم (ع)" placeholder="لقب الأم (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>



                                {/* لقب الأم باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNomMereFr"
                                                        value={valueNomMereFr}
                                                        inputRef={refNomMereFr}
                                                        onInput={handleInput_NomMereFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valueNomMereFr)
                                                                ? 'لقب الأم (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 14 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueNomMereFr" label="لقب الأم (ف)" placeholder="لقب الأم (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>

                            </Grid>


                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* إسم الأم بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomMere"
                                                        value={valuePrenomMere}
                                                        inputRef={refPrenomMere}
                                                        onInput={handleInput_PrenomMere}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valuePrenomMere)
                                                                ? 'إسم الأم (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 15 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomMere" label="إسم الأم (ع)" placeholder="إسم الأم (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>





                                {/* إسم الأم باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomMereFr"
                                                        value={valuePrenomMereFr}
                                                        inputRef={refPrenomMereFr}
                                                        onInput={handleInput_PrenomMereFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valuePrenomMereFr)
                                                                ? 'إسم الأم (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 16 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomMereFr" label="إسم الأم (ف)" placeholder="إسم الأم (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>





                            </Grid>





                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                {/* رقم التعريف الوطني NIN */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>

                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <TextField
                                                         fullWidth
                                                        name="valueNin"
                                                        value={valueNin}
                                                        inputRef={refNin}
                                                        onInput={handleInput_Nin}
                                                        error={
                                                            !valueDateNais ||
                                                            !NumbersOnlyRegex.test(valueNin) ||
                                                            !valueNin ||
                                                            valueNin.length !== 18 ||
                                                            (valueNin.substring(0, 2) !== "10" && valueNin.substring(0, 2) !== "11") ||
                                                            valueNin.substring(2, 5) !== valueDateNais_year.substring(1, 4)
                                                        }
                                                        helperText={
                                                            !valueDateNais
                                                                ? 'قم بملئ خانة تاريخ الميلاد'
                                                                : (!NumbersOnlyRegex.test(valueNin) || !valueNin || valueNin.length !== 18 || (valueNin.substring(0, 2) !== "10" && valueNin.substring(0, 2) !== "11") || valueNin.substring(2, 5) !== valueDateNais_year.substring(1, 4))
                                                                    ? 'رقم التعريف الوطني غير صحيح'
                                                                    : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 8 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <NumbersIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomnin`}

                                                        label="رقم التعريف الوطني NIN "
                                                        placeholder="رقم التعريف الوطني NIN "

                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>




                                </Grid>



                                {/* البريد الإلكتروني */}
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth variant="outlined"


                                                    >

                                                        <TextField

                                                            sx={{

                                                                //direction:'ltr',

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700

                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#191970',
                                                                    dir: 'rtl',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#191970',
                                                                    fontWeight: 700,
                                                                    //text-align: right,



                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#add8e6',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#add8e6',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'purple',
                                                                    },
                                                                    '&.Mui-focused fieldset': {

                                                                        borderColor: 'purple',
                                                                    },
                                                                }
                                                            }}

                                                            inputRef={refemail}
                                                            error={isValidemail === false}
                                                            label="البريد الإلكتروني (إختياري)"
                                                            placeholder="البريد الإلكتروني (إختياري)"
                                                            helperText={isValidemail === false ? "أدخل عنوان بريد إلكتروني صالح" : <>


                                                            </>}

                                                            variant="outlined"
                                                            size={'small'}
                                                            name="valueemail"
                                                            value={valueemail}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => handleChangeemail(e)}



                                                            InputProps={{
                                                                inputProps: { tabIndex: 5 },
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EmailIcon />
                                                                    </InputAdornment>
                                                                )


                                                            }}


                                                        />

                                                    </FormControl>


                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                            </Grid>





                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                               

                        {/* رقم الهاتف 01 */}

                    <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueTEL1"
                                                        value={valueTEL1}
                                                        inputRef={refTEL1}
                                                        onInput={handleInput_TEL1}
                                                        error={!NumbersOnlyRegex.test(valueTEL1)}

                                                        helperText={
                                                            (!NumbersOnlyRegex.test(valueTEL1) || valueTEL1.length !== 10)
                                                                ? 'أدخل رقم هاتف صالح'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 10 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhonelinkRingIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomtel1`}

                                                        label="رقم الهاتف 01"
                                                        placeholder="رقم الهاتف 01"
                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                                {/* رقم الهاتف 02 */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueTEL2"
                                                        value={valueTEL2}
                                                        inputRef={refTEL2}
                                                        onInput={handleInput_TEL2}
                                                        helperText={
                                                            NumbersOnlyRegex.test(valueTEL2) || !valueTEL2
                                                                ? ''
                                                                : 'رقم الهاتف 02 يجب ان يحتوي على أرقام فقط'
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 1 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhonelinkRingIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#191970'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#191970',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#b31d3e',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#add8e6',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#add8e6',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'purple',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNom`}

                                                        label="رقم الهاتف 02 (إختياري)"
                                                        placeholder="رقم الهاتف 02 (إختياري)" variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>

                            </Grid>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>








                                {/*  كلمة السر  */}
{/*
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">

                                                    <FormControl fullWidth variant="outlined"

                                                    >

                                                        <InputLabel
                                                            sx={{
                                                                color: "#b31d3e", fontFamily:
                                                                    '"Tajawal", sans-serif',
                                                                fontSize: 16
                                                                , fontWeight: 700
                                                            }}
                                                            htmlFor="outlined-adornment-password">كلمة المرور </InputLabel>
                                                        <OutlinedInput
                                                            fullWidth
                                                            dir="rtl"
                                                            sx={{

                                                                //direction:'ltr',

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700

                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#191970',
                                                                    dir: 'rtl',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#191970',
                                                                    fontWeight: 700,
                                                                    //text-align: right,


                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#add8e6',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#add8e6',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'purple',
                                                                    },
                                                                    '&.Mui-focused fieldset': {

                                                                        borderColor: 'purple',
                                                                    },
                                                                }
                                                            }}

                                                            inputRef={refpassword1}
                                                            id="outlined-adornment-password"
                                                            type={valuesPassword.showPassword ? 'text' : 'password'}
                                                            value={valuesPassword.password}
                                                            name="valuesPassword"
                                                            onChange={handleChangePassword('password')}
                                                            inputProps={{
                                                                tabIndex: 18,
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {valuesPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label=" كلمة المرور  "
                                                            placeholder=" كلمة المرور  "
                                                        />


                                                        {!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(valuesPassword.password) ? (
                                                            <FormHelperText
                                                                sx={{
                                                                    color: "#b31d3e",
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: 14,
                                                                    fontWeight: 700
                                                                }}
                                                                error
                                                                id="accountId-error"
                                                            >
                                                                أنشئ كلمة مرور قوية تتضمن حرفًا كبيرًا واحدًا على الأقل، وحرفًا صغيرًا واحدًا على الأقل، ورقمًا واحدًا على الأقل، ويجب أن لا تقل عن 8 أحرف.

                                                            </FormHelperText>
                                                        ) : (
                                                            <></>
                                                        )}



                                                    </FormControl>

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>

*/}


                                {/* إعادة كلمة السر */}
                                {/*
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">

                                                    <FormControl fullWidth variant="outlined"

                                                    >

                                                        <InputLabel
                                                            sx={{
                                                                color: "#b31d3e", fontFamily:
                                                                    '"Tajawal", sans-serif',
                                                                fontSize: 16
                                                                , fontWeight: 700
                                                            }}
                                                            htmlFor="outlined-adornment-password"> إعادة كلمة المرور الجديدة</InputLabel>
                                                        <OutlinedInput

                                                            fullWidth
                                                            dir="rtl"
                                                            sx={{

                                                                //direction:'ltr',

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700

                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#191970',
                                                                    dir: 'rtl',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#191970',
                                                                    fontWeight: 700,
                                                                    //text-align: right,



                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#b31d3e',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#add8e6',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#add8e6',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'purple',
                                                                    },
                                                                    '&.Mui-focused fieldset': {

                                                                        borderColor: 'purple',
                                                                    },
                                                                }
                                                            }}

                                                            inputRef={refpassword2}
                                                            id="outlined-adornment-PasswordRe"
                                                            type={valuesPasswordRe.showPasswordRe ? 'text' : 'Password'}
                                                            value={valuesPasswordRe.PasswordRe}
                                                            name="valuesPasswordRe"
                                                            onChange={handleChangePasswordRe('PasswordRe')}
                                                            inputProps={{
                                                                tabIndex: 19,
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPasswordRe}
                                                                        onMouseDown={handleMouseDownPasswordRe}
                                                                        edge="end"
                                                                    >
                                                                        {valuesPasswordRe.showPasswordRe ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label=" إعادة كلمة المرور الجديدة "
                                                            placeholder=" إعادة كلمة المرور الجديدة "
                                                        />

                                                        {valuesPasswordRe.PasswordRe !== valuesPassword.password ? <>

                                                            <FormHelperText
                                                                sx={{
                                                                    color: "#b31d3e", fontFamily:
                                                                        '"Tajawal", sans-serif',
                                                                    fontSize: 14
                                                                    , fontWeight: 700
                                                                }}
                                                                error id="accountId-error">
                                                                يجب أن تدخل نفس كلمة السر
                                                            </FormHelperText>


                                                        </> : <></>}


                                                    </FormControl>
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>
                                */}






                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                <Paper style={{ maxHeight: 400, overflow: 'auto', padding: 20, mt: 2, mb: 2 }}>
                                    <div className="terms-section" style={{ textAlign: "right" }} direction="rtl">
                                        <Typography variant="body1" dir="rtl"
                                            sx={{
                                                fontFamily: '"Tajawal", sans-serif', // Font family
                                                fontSize: 14, // Font size
                                                direction: 'rtl',
                                                textAlign: 'right'
                                            }}
                                        >

                                            <h2>شروط تخزين ومشاركة البيانات</h2>
                                            <p>سيتم تخزين بياناتك الشخصية بأمان:</p>
                                            <ul>
                                                <li>نحتفظ ببياناتك طوال مسارك المهني وبعد تخرجك لفترة زمنية محددة.</li>
                                                <li>نستخدم قاعدة بيانات آمنة لحماية بياناتك من الوصول غير المصرح به أو الاستخدام أو الكشف أو التغيير أو التلف.</li>
                                            </ul>
                                            <h2>مشاركة البيانات مع جهات خارجية محددة</h2>
                                            <p>قد نضطر إلى مشاركة بياناتك مع جهات خارجية موثوقة في بعض الحالات، مثل:</p>
                                            <ul>
                                                <li>مؤسسات التكوين المهني التي ستلتحق بها لتسهيل عملية التسجيل و المتابعة.</li>
                                                <li>الجهات الحكومية المختصة عند الطلب لأغراض قانونية أو إدارية.</li>
                                                <li>مقدمي الخدمات الذين نتعاقد معهم لتقديم خدمات الدعم والمتابعة، مع ضمان حماية بياناتك.</li>
                                            </ul>
                                            <h2>موافقتك ضرورية</h2>
                                            <p>باستخدامك لهذه المنصة، فإنك توافق على شروط الاتفاق هذه.</p>
                                            <p>إذا كنت لا توافق على هذه الشروط، فلا تستخدم هذه المنصة.</p>

                                        </Typography>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    inputRef={refcheckbox}
                                                    color="primary"
                                                    inputProps={{
                                                        tabIndex: 20,
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant="body2" dir="rtl" sx={{ // Use Typography component
                                                    fontFamily: '"Tajawal", sans-serif',
                                                    fontSize: 14,
                                                    color: 'blue', // Change label color to blue
                                                    fontWeight: 'bold', // Make the label text bolder
                                                }}>
                                                    أوافق على شروط وأحكام الاستخدام
                                                </Typography>
                                            }
                                            sx={{ // Remove styling for the FormControlLabel itself (optional)
                                                direction: 'rtl',
                                                textAlign: 'right'
                                            }}
                                        />


                                    </div>
                                </Paper>


                            </Grid>
                            <ClickAwayListener onClickAway={handleClose_recaptcha}>
                                <div>
                                    <BootstrapTooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        open={open_recaptcha} onClose={handleClose_recaptcha} onOpen={handleOpen_recaptcha}


                                        title="اثبت انك لست روبوت"

                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <Grid item xs={12} container justifyContent="center">


                                            <ReCAPTCHA style={{ marginTop: '10px' }}
                                                ref={refReCAPTCHA}
                                                sitekey="6LcCt3YpAAAAAK33Jnte_3V4gKcLl-kQgeV3qWMP"
                                                onChange={handleRecaptchaChange}
                                            // Additional optional props (e.g., action, verifyApiArgs)
                                            />

                                        </Grid>

                                    </BootstrapTooltip>


                                    {/* Submit Button */}
                                    <Grid item xs={12} container justifyContent="center" >
                                        <CacheProvider value={cacheRtl}>
                                            <ThemeProvider theme={themetextfieldnew}>
                                                <Box sx={{ minWidth: 120, mt: '35px', mb: 15 }}>
                                                    <div>

                                                        <Button

                                                            onClick={(e) => {



                                                                e.preventDefault();

                                                                /*
                                                                setvalueNom('صبرو');
                                                                setvalueNomFr('sebrou');
                                                                setvaluePrenom('عبد العزيز');
                                                                setvaluePrenomFr('abdelaziz');

                                                                setvalueAdres('حي بن سمارة');
                                                                setvalueAdresFr('ben smara rue');
                                                                setvaluePrenomPere('بلقاسم');
                                                                setvaluePrenomPereFr('belkacem');
                                                                setvalueNomMere('الشحمة');
                                                                setvalueNomMereFr('chehma');
                                                                setvaluePrenomMere('فطيمة');
                                                                setvaluePrenomMereFr('fatima');
                                                                setIsChecked(true);
                                                                setRecaptchaVerified(true);

                                                                // Now you can set the Password property using setValuesPassword
                                                                setValuesPassword(prevState => ({
                                                                    ...prevState,
                                                                    password: 'azizSEBROU1991' // Changed from 'Password' to 'password'
                                                                }));


                                                                setValuesPasswordRe(prevState => ({
                                                                    ...prevState,
                                                                    PasswordRe: 'azizSEBROU1991'
                                                                }));

                                                                */
                                                               

                                                                if (!selectedniveau_scolaires || (endicape !== 1 && (Number(IDCycle_scol_selected) < Number(IDCycle_scol) || (Number(IDCycle_scol_selected) === Number(IDCycle_scol) && Number(NumOrd_selected) < Number(NumOrd))))) {
                                                                    Refselectedniveau_scolaires.current.focus();
                                                                return;
                                                                }

                                                                if (!ArabicCharactersRegex.test(valueNom) || valueNom === '') {
                                                                    refNom.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex.test(valueNomFr) || valueNomFr === '') {
                                                                    refNomFr.current.focus();
                                                                    return
                                                                }

                                                                if (!ArabicCharactersRegex.test(valuePrenom) || valuePrenom === '') {
                                                                    refPrenom.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex.test(valuePrenomFr) || valuePrenomFr === '') {
                                                                    refPrenomFr.current.focus();
                                                                    return
                                                                }

                                                                                        
                                                                const currentDate = new Date();
                                                                const birthDate = new Date(valueDateNais);
                                                                const age_curr = currentDate.getFullYear() - birthDate.getFullYear();

                                                                if (!valueDateNais || (AgeMin !== 0 && (Number(age_curr) < AgeMin || (AgeMAx > AgeMin && Number(age_curr) > AgeMAx)))) {
                                                                    setmsgerror("غير صالح");
                                                                    setfieldnameerror("تاريخ الميلاد: ")
                                                                    setopen_info(true);

                                                                    refDateNais.current.focus();
                                                                    return false;
                                                                }

                                                                


                                                                if (!ArabicCharactersRegex_address.test(valueAdres) || valueAdres === '') {
                                                                    refAdres.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex_address.test(valueAdresFr) || valueAdresFr === '') {
                                                                    refAdresFr.current.focus();
                                                                    return
                                                                }

                                                                if (!ArabicCharactersRegex.test(valuePrenomPere) || valuePrenomPere === '') {
                                                                    refPrenomPere.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex.test(valuePrenomPereFr) || valuePrenomPereFr === '') {
                                                                    refPrenomPereFr.current.focus();
                                                                    return
                                                                }

                                                                if (!ArabicCharactersRegex.test(valueNomMere) || valueNomMere === '') {
                                                                    refNomMere.current.focus();
                                                                    return
                                                                }
                                                                if (!LatinLettersRegex.test(valueNomMereFr) || valueNomMereFr === '') {
                                                                    refNomMereFr.current.focus();
                                                                    return
                                                                }

                                                                if (!ArabicCharactersRegex.test(valuePrenomMere) || valuePrenomMere === '') {
                                                                    refPrenomMere.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex.test(valuePrenomMereFr) || valuePrenomMereFr === '') {
                                                                    refPrenomMereFr.current.focus();
                                                                    return
                                                                }

                                                                if (!isValidemail) {
                                                                    refemail.current.focus();
                                                                    return;
                                                                }
                                                                
                                                                if ( !valueDateNais ||
                                                                    !NumbersOnlyRegex.test(valueNin) ||
                                                                    !valueNin ||
                                                                    valueNin.length !== 18 ||
                                                                    (valueNin.substring(0, 2) !== "10" && valueNin.substring(0, 2) !== "11") ||
                                                                    valueNin.substring(2, 5) !== valueDateNais_year.substring(1, 4)) {

                                                                        refNin.current.focus();
                                                                    return
                                                                }

                                                                if (!NumbersOnlyRegex.test(valueTEL1) || valueTEL1.length !== 10) {
                                                                    refTEL1.current.focus();

                                                                    return
                                                                }

                                                               
/*
                                                                if (valuesPassword.password === '') {
                                                                    refpassword1.current.focus();
                                                                    return
                                                                }

                                                                if (valuesPasswordRe.PasswordRe === '') {
                                                                    refpassword2.current.focus();
                                                                    return
                                                                }

                                                                if (valuesPassword.password !== valuesPasswordRe.PasswordRe) {
                                                                    refpassword2.current.focus();
                                                                    return
                                                                }

                                                                if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(valuesPassword.password)) {
                                                                    refpassword1.current.focus();
                                                                    return
                                                                }*/




                                                                if (!isChecked) {
                                                                    setmsgerror("لم تقم بالموافقة على شروط و أحكام الإستخدام");
                                                                    setopen_info(true);
                                                                    setopen_check(true);
                                                                    refcheckbox.current.focus();
                                                                    return
                                                                }


                                                                if (!recaptchaVerified) {
                                                                    setmsgerror("قم بالنقر على 'أنا لست روبوت' في ريكابتشا");
                                                                    setfieldnameerror("ريكابتشا: ")
                                                                    setopen_info(true);

                                                                    setOpen_recaptcha(true);
                                                                    return
                                                                }

                                                                onsubmitclicked(e);


                                                            }}



                                                            variant="contained"
                                                            endIcon={<SaveIcon sx={{
                                                                mr: '5px', ml: '5px',
                                                                transform: 'rotate(0deg)', // Adjust the angle as needed
                                                            }} />}>
                                                            <Typography sx={{
                                                                color: "#FFFFFF", fontFamily:
                                                                    '"Tajawal", sans-serif',
                                                                fontSize: 22
                                                                , fontWeight: 900
                                                            }}>
                                                                سجل الآن

                                                            </Typography>

                                                        </Button>

                                                    </div>

                                                </Box>
                                            </ThemeProvider>
                                        </CacheProvider>

                                    </Grid>

                                </div>
                            </ClickAwayListener>





                        </Grid>
                    </Container>


                </form>
            </div>

            <Footer />






            {/* Dialog success save */}

            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: '#add8e6',
                        boxShadow: 'none',
                    },
                }}
                //fullScreen={mediumScreen}
                open={OpenDialog}
                onClose={handleClickCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >

                <Card style={{}} sx={{ maxWidth: 345 }}>

                    {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
                    <CardHeader
                        avatar={

                            <CheckCircleIcon sx={{ color: '#4BB543', width: 50, height: 50, ml: 2 }}></CheckCircleIcon>

                        }
                        action={


                            <IconButton onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml: -1, mt: -1 }}>

                                <CloseIcon />

                            </IconButton>
                        }
                        title={
                            <>
                                <Box sx={{ ml: 2, mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>

                                    <Typography sx={{ color: '#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }} >
                                        تم التسجيل بنجاح</Typography>

                                </Box>

                            </>

                        }
                        subheader={
                            <>
                                <Box sx={{  mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>

                                    <Typography sx={{ color: '#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }} >
                                        
                                        رقم التسجيل: {IDCandidat}
                                        
                                        </Typography>

                                </Box>

                            </>

                        }
                    />
                    <CardContent>
                        <Divider sx={{ mt: '0px' }}></Divider>
                        <Grid item  >
                            <Button
                                component={Link}
                                to={`/Preinscriptionpdf/${crypted_id}`}
                                target="_blank"
                                variant="contained" // Add this line to make it a contained button
                                sx={{
                                    display: 'block', // Display the button as a block element
                                    textAlign: 'right',
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,

                                }}
                            >

                                <Typography
                                    theme={themeChanga}
                                    sx={{ textAlign: "right", mr: 0 }}
                                    type="body2"
                                >

                                    تحميل استمارة التسجيل <PictureAsPdfIcon />
                                </Typography>
                            </Button>
                        </Grid>
                    </CardContent>


                </Card>

            </Dialog>










            <CacheProvider value={cacheRtl}>
                <ThemeProvider theme={themetextfieldnew}>


                    <Dialog
                        open={open_info} onClose={() => setopen_info(false)}>
                        <DialogTitle>
                            <Divider >
                                <Grid item sx={{ mt: 2 }} >
                                    <Typography
                                        style={{
                                            color: "blue",
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: 20,
                                            fontWeight: 700,
                                        }}
                                    >
                                        أكمل المعلومات <InfoIcon sx={{ ml: '0px' }} />
                                    </Typography>
                                </Grid>
                            </Divider>


                        </DialogTitle>



                        <DialogContent>
                            <DialogContentText
                                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                                <span style={{ color: 'black' }}>{fieldnameerror}</span> {msgerror}

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                onClick={() => setopen_info(false)}
                                className={classes.cancelBtn}
                                color="primary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                                    backgroundColor: "#4caf50", // أخضر
                                    color: "#fff",
                                    "&:hover": {
                                        backgroundColor: "#388e3c",
                                    },
                                }}
                            >
                                موافق
                            </Button>

                        </DialogActions>
                    </Dialog>

                </ThemeProvider>
            </CacheProvider>



        </>
    )
}