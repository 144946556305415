import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { APP_PREFIX } from './config'; // Adjust the path if necessary
import useAuthentication_etab from './useAuthentication_etab';


const useStyles_CircularProgress = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it's above everything else
  },
}));

const Preinscriptionpdf = () => {


  const { cryptedId } = useParams();
  const [result, setresult] = useState(null);
  const [result_dfep, setresult_dfep] = useState(null);

  const [result_mfep, setresult_mfep] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null); // State to store PDF URL
  const [Annee_formation_Nom, setAnnee_formation_Nom] = useState(null);

  const [download_cert_inscription_active_state, setdownload_cert_inscription_active_state] = useState(0);
  const [download_cert_inscription_active_dfep_state, setdownload_cert_inscription_active_dfep_state] = useState(0);
  const [download_cert_inscription_active_mfep_state, setdownload_cert_inscription_active_mfep_state] = useState(0);
  const [numsem_state, setnumsem_state] = useState(0);

  const navigate = useNavigate();
  const [loadcrypted, setloadcrypted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/getPreinscriptionpdf/${cryptedId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setresult(data.data);

        setAnnee_formation_Nom(data.Annee_formation_Nom);

        setloadcrypted(true);
      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      }
    };
    if (cryptedId) {
      fetchData();
    }



  }, [cryptedId]);



  useEffect(() => {


    if (result && !pdfUrl) { // Only generate PDF if result is set and PDF URL is not already generated




      // Create a new jsPDF instance
      const pdf = new jsPDF();

      // Set document information
      pdf.setProperties({
        title: 'استمارة التسجيل الأولي',
        subject: 'استمارة التسجيل الأولي',
        author: 'MFEP',
      });
      const fontUrl = `${process.env.PUBLIC_URL}/Amiri-Regular.ttf`;
      const fontUrl_bold = `${process.env.PUBLIC_URL}/Amiri-Bold.ttf`;
      const fontUrl_arabic_ejaza = `${process.env.PUBLIC_URL}/arabic-ejaza.ttf`;
      const fontUrl_majalla = `${process.env.PUBLIC_URL}/majalla.ttf`;

      pdf.addFont(fontUrl, 'Amiri', 'normal');
      // Draw Certificate title
      pdf.addFont(fontUrl_bold, 'Amiri_bold', 'normal');
      pdf.addFont(fontUrl_arabic_ejaza, 'arabic_ejaza', 'normal');
      pdf.addFont(fontUrl_majalla, 'Sakkal Majalla', 'normal');

      pdf.setFont('arabic_ejaza'); // Set font

      // Set the title of the browser tab
      document.title = 'استمارة التسجيل الأولي';

      // Set font size and text direction
      pdf.setFontSize(26);
      pdf.text('الجمهورية الجزائرية الديمقراطية الشعبية', pdf.internal.pageSize.getWidth() / 2, 12, { align: 'center' });

      // Move to new line
      pdf.setLineHeightFactor(1.5); // Adjust line height if needed

      // Draw Ministry information
      pdf.text('وزارة التكويـن والتعلـيـم المهنييـن', pdf.internal.pageSize.getWidth() / 2, 22, { align: 'center' });
      pdf.setFont('Sakkal Majalla'); // Set font
      ///pdf.setFont('Amiri'); // Set font
      pdf.setFontSize(16);
      // Draw Wilaya and Direction information
      if (result !== null && result.dfep_Nom) {
        ///pdf.text('ولاية ' + result.dfep_Nom, pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
        pdf.text('مديرية التكوين والتعليم المهنيين لولاية ' + result.dfep_Nom, pdf.internal.pageSize.getWidth() - 10, 40, { align: 'right' });
      } else {
        /// pdf.text('ولاية ', pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
        pdf.text('مديرية التكوين والتعليم المهنيين لولاية ', pdf.internal.pageSize.getWidth() - 10, 40, { align: 'right' });
      }

      // Draw Name, Date, and Number
      pdf.text(result.etablissement_Nom, pdf.internal.pageSize.getWidth() - 10, 50, { align: 'right' });
      pdf.text(' الرقم:  ' + result.IDCandidat, pdf.internal.pageSize.getWidth() - 10, 60, { align: 'right' });


      ///pdf.setFont('Amiri_bold'); // Set font
      pdf.setFont('Sakkal Majalla'); // Set font
      pdf.setFontSize(35);
      pdf.text(' استمارة التسجيل الأولي ', pdf.internal.pageSize.getWidth() / 2, 70, { align: 'center' });
      pdf.setFontSize(25);
      pdf.text('( ' + result.session_Nom + ' )', pdf.internal.pageSize.getWidth() / 2, 80, { align: 'center' });

      // Add empty line
      ///pdf.text('', 10, 80);

      // Draw trainee information
      pdf.setFontSize(16);

      // Draw trainee details
      pdf.text('اللقب و الاسم : ' + result.Nom + ' ' + result.Prenom, pdf.internal.pageSize.getWidth() - 10, 100, { align: 'right' });
      ///pdf.text('تاريخ و مكان الميلاد : ' + result.DateNais + ' ' + result.wilayas_libelleArabe + ' ' + result.communn_Nom, pdf.internal.pageSize.getWidth() - 10, 110, { align: 'right' });
      // Assuming result.DateNais is in the format yyyy-mm-dd
      const dateParts = result.DateNais.split('-');
      ///const formattedDate = `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
      const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;


      pdf.text('تاريخ و مكان الميلاد : ' + formattedDate  + ' ' + result.communn_Nom, pdf.internal.pageSize.getWidth() - 10, 110, { align: 'right' });


      pdf.text('العنوان : ' + result.Adres, pdf.internal.pageSize.getWidth() - 10, 120, { align: 'right' });
      pdf.text(result.IDCandidat + ' : مسجل )ة( تحت رقم', pdf.internal.pageSize.getWidth() - 10, 130, { align: 'right' });
      pdf.text('اختصاص: ' + result.specialite_Nom, pdf.internal.pageSize.getWidth() - 10, 140, { align: 'right' });
      pdf.text('نمـط التكوين : ' + result.mode_formation_Nom, pdf.internal.pageSize.getWidth() - 10, 150, { align: 'right' });

      // Convert result.DateDF and result.DateFF to Date objects
      var dateDF = new Date(result.DateD);
      var dateFF = new Date(result.DateF);

      // Get day, month, and year components
      var dayDF = dateDF.getDate();
      var monthDF = dateDF.getMonth() + 1; // Months are zero-based, so add 1
      var yearDF = dateDF.getFullYear();

      var dayFF = dateFF.getDate();
      var monthFF = dateFF.getMonth() + 1; // Months are zero-based, so add 1
      var yearFF = dateFF.getFullYear();

      // Format the date strings as dd-mm-yyyy
      var formattedDateDF = ('0' + dayDF).slice(-2) + '-' + ('0' + monthDF).slice(-2) + '-' + yearDF;
      var formattedDateFF = ('0' + dayFF).slice(-2) + '-' + ('0' + monthFF).slice(-2) + '-' + yearFF;

      // Display the formatted dates
      pdf.text('مدة التكوين من: ' + formattedDateDF + ' إلى ' + formattedDateFF, pdf.internal.pageSize.getWidth() - 10, 160, { align: 'right' });

      // Add empty lines
      pdf.text('', 10, 190);
      pdf.text('', 10, 200);

      // Draw issuing information
      pdf.setFontSize(16);
      // Get the current date
      var currentDate = new Date();

      // Get day, month, and year components
      var day = currentDate.getDate();
      var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
      var year = currentDate.getFullYear();

      // Get hours, minutes, and seconds components
      var hours = currentDate.getHours();
      var minutes = currentDate.getMinutes();
      var seconds = currentDate.getSeconds();

      // Format the date as dd-mm-yyyy hh:mm:ss
      var formattedCurrentDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year +
        ' ' + ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);

      // Display the formatted current date
      pdf.text(' في : ' + formattedCurrentDate, 60, 190, { align: 'left' });
      ////pdf.text('مدير )ة( المؤسسة', 80, 200, { align: 'left' });


      // Create QR code as a data URL
      const qrDataURL = document.getElementById('qrCode').toDataURL('image/png');

      // Calculate the X-coordinate for right alignment
      const qrCodeX = pdf.internal.pageSize.getWidth() - 200; // 60 is the total width of the QR code image and its margin

      // Add QR code image to the PDF
      ///pdf.addImage(qrDataURL, 'PNG', qrCodeX, 200, 50, 50);
      pdf.addImage(qrDataURL, 'PNG', 60, 200, 50, 50);

      // Get the full current URL
      const currentUrl = window.location.href;

      // Add the current URL at the bottom of the PDF page
      pdf.text(currentUrl, 20, pdf.internal.pageSize.height - 30, { align: 'left' });

      // Draw additional information
      ////pdf.text('سلمت هذه الوثيقة لاستعمالها فيما يسمح به القانون', pdf.internal.pageSize.getWidth() - 10, 260, { align: 'right' });


      // Generate a blob URL for the PDF content
      const pdfBlob = pdf.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);

      // Set pdfUrl to the blob URL
      setPdfUrl(pdfBlobUrl);


    }
  }, [result, pdfUrl]); // pdfUrl added to dependency array

  const classes_CircularProgress = useStyles_CircularProgress();





  return (
    <>


      <div style={{ width: '100%', height: '1000px' }}>



        {!pdfUrl &&
          <div className={classes_CircularProgress.overlay}>

            <Dialog open={!pdfUrl} PaperProps={{ sx: { backgroundColor: 'white' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                <CircularProgress sx={{ ml: 1 }} color="primary" />
                <Typography variant="h6" sx={{ color: '#191970', fontFamily: 'Tajawal, sans-serif', fontWeight: 900, ml: 2 }}>
                  جاري التحميل...
                </Typography>
              </Box>
            </Dialog>

          </div>
        }
        {pdfUrl && (

          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="100%"
            aria-label="Generated PDF"
          >
          </object>


        )}
        {/* Render QR code component */}
        <div style={{ display: 'none' }}>
          <QRCode id="qrCode" value={`${window.location.origin}${window.location.pathname}`} />
        </div>
      </div>
    </>
  );
};

export default Preinscriptionpdf;
