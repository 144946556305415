import * as React from 'react';

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Button, Grid } from '@mui/material';

export default function HomePageMfp() {
    return (

        <Grid container spacing={2}
            sx={{
                mt: 2,
                mb: 2,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {/* {[...Array(9).keys()].map((index) => ( // Render 9 cards  
            ))}
            */}


<Grid item key={1} xs={12} sm={6} md={4}
                sx={{
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Card sx={{ maxWidth: 345, minHeight: 400 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/mfep.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >
                                البوابة الرئيسية
                            </Typography>
                            <Typography variant="body2" color="text.secondary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                }}
                            >
                                تجدون فيه كل ما يتعلق بقطاع التكوين والتعليم المهنيين، من أخبار وآخر المستجدات.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <a href="https://www.mfep.gov.dz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

                            <Button variant="contained"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >تصفح الموقع

                            </Button>
                        </a>
                    </CardActions>
                </Card>
            </Grid>



            <Grid item key={1} xs={12} sm={6} md={4}
                sx={{
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Card sx={{ maxWidth: 345, minHeight: 400 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/moutakawin.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >
                                منصة المتكون
                            </Typography>
                            <Typography variant="body2" color="text.secondary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                }}
                            >
                                هي منصة تُمكِّن المتربص والمتمهن من الوصول إلى جميع معلوماتهم وتحميلها، مثل شهادات التربص وكشوف النقاط وما إلى ذلك.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <a href="https://moutakawin.mfp.gov.dz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

                            <Button variant="contained"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >تصفح المنصة

                            </Button>
                        </a>
                    </CardActions>
                </Card>
            </Grid>





            <Grid item key={1} xs={12} sm={6} md={4}
                sx={{
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Card sx={{ maxWidth: 345, minHeight: 400 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/dorosi.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >
                                منصة دروسي
                            </Typography>
                            <Typography variant="body2" color="text.secondary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                }}
                            >
                                هي منصة تعليمية تحتوي على دروس و برامج التدريس للتكوين المهني                             </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <a href="https://mfp.gov.dz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

                            <Button variant="contained"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >تصفح المنصة

                            </Button>
                        </a>
                    </CardActions>
                </Card>
            </Grid>




            <Grid item key={1} xs={12} sm={6} md={4}
                sx={{
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Card sx={{ maxWidth: 345, minHeight: 400 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/massari.jfif"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >
                                منصة مساري
                            </Typography>
                            <Typography variant="body2" color="text.secondary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                }}
                            >
                                من خلال المنصة يمكن للمستخدمين الإطلاع بسهولة على وثائقهم الإدارية و البيداغوجية، بالإضافة إلى متابعة سيرورتهم المهنية بكل يسر و سهولة                          </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <a href="https://mfp.gov.dz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

                            <Button variant="contained"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >تصفح المنصة

                            </Button>
                        </a>
                    </CardActions>
                </Card>
            </Grid>





            <Grid item key={1} xs={12} sm={6} md={4}
                sx={{
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Card sx={{ maxWidth: 345, minHeight: 400 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/dalil.jfif"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >
                                منصة دليل
                            </Typography>
                            <Typography variant="body2" color="text.secondary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                }}
                            >
                                توفر المنصة البرامج الخاصة بجميع التخصصات، التعريف بمختلف التجهيزات، التعرف على جميع الشركاء الإقتصاديين للقطاع، البحث عن بيانات جميع المؤسسات التكوينية عبر التراب الوطني...الخ
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <a href="https://dalil.mfp.gov.dz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

                            <Button variant="contained"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >تصفح المنصة

                            </Button>
                        </a>
                    </CardActions>
                </Card>
            </Grid>


            <Grid item key={1} xs={12} sm={6} md={4}
                sx={{
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Card sx={{ maxWidth: 345, minHeight: 400 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/charaka.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >
                                منصة شراكة
                            </Typography>
                            <Typography variant="body2" color="text.secondary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                }}
                            >
تتضمن هذه المنصة، على وجه الخصوص، قائمة المستخدمين والحرفيين والقائمة الاسمية لمعلمي التمهين والحرفيين ومؤهلاتهم المهنية على مستوى كل بلدية وكل ولاية. كما تتيح للشركاء الاقتصاديين إدخال معلومات حول مناصب ومعلمي التمهين.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <a href="https://mfp.gov.dz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>

                            <Button variant="contained"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >تصفح المنصة

                            </Button>
                        </a>
                    </CardActions>
                </Card>
            </Grid>


            <Grid item key={1} xs={12} sm={6} md={4}
                sx={{
                    mt: 2,
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Card sx={{ maxWidth: 345, minHeight: 320 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/tachbik.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >
                                منصة تشبيك
                            </Typography>
                            <Typography variant="body2" color="text.secondary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif',
                                }}
                            >
هي خدمة رقمية يقدمها قطاع التكوين والتعليم المهنيين، تُمكن البرامج والتطبيقات في القطاعات الأخرى أو المؤسسات الشريكة من الحصول على البيانات التي تحتاجها.

                            </Typography>
                        </CardContent>
                    </CardActionArea>
                   
                </Card>
            </Grid>


        </Grid>

    );
}