import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import Container from '@mui/material/Container';
import Footer from '../containers/Footer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';
import PrintIcon from '@mui/icons-material/Print';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';

import appico from '../src/img/logo.png';
import logo2 from '../src/img/logo2.png';
import flag_dz from '../src/img/flag_dz.png';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import iconbarleft from '../src/img/logo.png';
import pharmacy from '../src/img/logo.png';
import blooddon from '../src/img/logo.png';
import moresearch from '../src/img/logo.png';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer";
import DrawerCheckAuth from "./DrawerCheckAuth";


import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary

import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


import './amirifont.css';



const HeaderCheckAuth = () => {



  const [value, setValue] = useState(0); //select default tab
  const [value2, setValue2] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl_infocand, setAnchorEl_infocand] = React.useState(null);
  const open_infocand = Boolean(anchorEl_infocand);
  const handleClick_infocand = (event) => {
    setAnchorEl_infocand(event.currentTarget);
  };
  const handleClose_infocand = () => {
    setAnchorEl_infocand(null);
  };




  const [anchorElMotakawin, setAnchorElMotakawin] = React.useState(null);
  const openMotakawin = Boolean(anchorElMotakawin);
  const handleClickMotakawin = (event) => {
    setAnchorElMotakawin(event.currentTarget);
  };
  const handleCloseMotakawin = () => {
    setAnchorElMotakawin(null);
  };

  const navigate = useNavigate();
  const handleClickValidationStEtab = () => {
    // Navigate to the desired route
    navigate('/ValidationCandInfo');
    handleCloseMotakawin();

  };
  const handleClickChangPwStEtab = () => {
    // Navigate to the desired route
    navigate('/ChangPwStEtab');
    handleCloseMotakawin();

  };

  const handleClickChangPwPriv = () => {
    // Navigate to the desired route
    navigate('/ChangPwPriv');
    handleCloseMotakawin();

  };

  const handleClickChangePwEtab = () => {
    // Navigate to the desired route
    navigate('/ChangePwEtab');
    handleCloseMotakawin();

  };



  /* const usePathname = () => {
     const location = useLocation();
     return location.pathname;
   }*/

  const openMyWindow = () => {
    //window.open("/Doc", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
    ///window.open("/Doc", '_system'); 
  }



  const location = useLocation();
  //////////////console.log(location.pathname);


  const themeChangaHeader = createTheme({
    typography: {
      fontFamily:
        '"Tajawal", sans-serif',
      fontSize: 16,
      fontWeight: 900


    },
  });





  const handleChangeTabs1 = (event, newValue) => {
    setValue(newValue);
  };


  const theme = useTheme();
  ///////////console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  ///////////console.log(isMatch);
  const isMatch_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch_xs = useMediaQuery(theme.breakpoints.down("xs"));



  const themeAmiri = createTheme({
    typography: {
      fontFamily:
        '"Amiri", serif',
      fontSize: 12,


    },
  });

  const themeRoboto = createTheme({
    typography: {
      fontFamily:
        '"Roboto Slab", serif',
      fontSize: 12,


    },
  });


  const themeChanga = createTheme({
    typography: {
      fontFamily:
        '"Tajawal", sans-serif',
      fontSize: 16


    },
  });


  return (
    ///select first tab

    <>

      {isMatch ? (
        <>
          <Box
            sx={{ mt: 20 }}
            display="flex"
            justifyContent="center"
            alignItems="center" >
            <DrawerCheckAuth />

          </Box>
        </>) : (<>
          <Box
            sx={{

              mb: 25

            }}
            display="flex"
            justifyContent="center"
            alignItems="center"

          >





            <React.Fragment>

              <div>
                {/* Your content above the header */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                  <AppBar  >

                    <div>
                      <Box >
                        <Grid container columns={{ xs: 12, sm: 12, md: 12 }} sx={{ backgroundColor: '#d6e6f6' }}>


                          <Grid item xs={12} sm={12} md={12}>


                            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} sx={{
                              display: 'flex',
                              justifyContent: 'center', alignItems: 'center'
                            }}>
                              <Grid item xs={12} sm={2} md={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 1 }}>


                                {(isMatch_xs) ? (
                                  <>
                                  </>
                                ) : (isMatch_sm) ? (
                                  <>

                                  </>
                                ) : (isMatch) ? (
                                  <>
                                    <img src={appico} style={{ width: '50px', height: '50px' }} alt="Image 1" />
                                  </>
                                ) : (
                                  <>
                                    <img src={appico} style={{ width: '70px', height: '70px' }} alt="Image 1" />
                                  </>
                                )}


                              </Grid>
                              <Grid item sx={{
                                display: 'flex',
                                justifyContent: 'center', alignItems: 'center'
                              }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>


                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <div>
                                        <Box sx={{ textAlign: 'center', mt: 2 }}>

                                          <Typography
                                            sx={{
                                              color: '#191970',
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontWeight: 900,
                                              fontSize: 22,
                                            }}
                                          >
                                            الجمهورية الجزائرية الديمقراطية الشعبية
                                          </Typography>
                                        </Box>

                                        <Box sx={{ textAlign: 'center', mb: 2, position: 'relative' }}>
                                          <Typography
                                            sx={{
                                              color: '#191970',
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontWeight: 900,
                                              fontSize: 20,
                                            }}
                                          >
                                            وزارة التكويـن والتعلـيـم المهنييـن
                                          </Typography>

                                        </Box>
                                      </div>
                                    </Box>



                                  </div></Box>


                              </Grid>
                              <Grid item xs={12} sm={2} md={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 1 }}>

                                {(isMatch_xs) ? (
                                  <>
                                  </>
                                ) : (isMatch_sm) ? (
                                  <>
                                  </>
                                ) : (isMatch) ? (
                                  <>
                                    <img src={flag_dz} style={{ width: '50px', height: '50px' }} alt="Image 1" />
                                  </>
                                ) : (
                                  <>
                                    <img src={flag_dz} style={{ width: '70px', height: '70px' }} alt="Image 1" />
                                  </>
                                )}


                              </Grid>
                            </Grid>



                          </Grid>



                        </Grid>
                      </Box>
                    </div>





                    <Toolbar sx={{ backgroud: 'red' }}>
                      <Typography variant="h6" noWrap
                        theme={themeChangaHeader} sx={{
                          flexGrow: 1, fontSize: 18, mr: 1, mt: 0
                          , fontFamily:
                            '"Tajawal", sans-serif',
                          fontSize: 20
                          , fontWeight: 700
                        }} component="div">
                        منصة المتكون
                      </Typography>

                      <a style={{ textDecoration: 'none' }} href="https://www.mfep.gov.dz/">
                        <img
                          alt="منصة المتكون"
                          src={logo2}
                          style={{ width: 60, height: 60, }} // Adjust width, height, and border-radius as needed 
                        />
                      </a>

                    </Toolbar>
                  </AppBar>
                </Box>
              </div>






            </React.Fragment>




          </Box>

        </>)}

    </>



  )
}

export default HeaderCheckAuth;

