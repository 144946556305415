const translates = [
    { en: "QR code parse error, error =", ar: "خطأ في تحليل رمز الاستجابة السريعة، خطأ =" },
    { en: "Error getting userMedia, error =", ar: "خطأ في الحصول على userMedia، خطأ =" },
    { en: "The device doesn't support navigator.mediaDevices , only supported cameraIdOrConfig in this case is deviceId parameter (string).", ar: "لا يدعم الجهاز navigator.mediaDevices ، فقط cameraIdOrConfig المدعومة في هذه الحالة هي معلمة deviceId (سلسلة)." },
    { en: "Camera streaming not supported by the browser.", ar: "لا يدعم المتصفح بث الكاميرا." },
    { en: "Unable to query supported devices, unknown error.", ar: "تعذر استعلام الأجهزة المدعومة، خطأ غير معروف." },
    { en: "Camera access is only supported in secure context like https or localhost.", ar: "يُدعم الوصول إلى الكاميرا فقط في سياق آمن مثل https أو localhost." },
    { en: "Scanner paused", ar: "تم إيقاف الماسح الضوئي مؤقتًا" },
    { en: "Scanning", ar: "جارٍ المسح" },
    { en: "Idle", ar: "خامل" },
    { en: "Error", ar: "خطأ" },
    { en: "Permission", ar: "إذن" },
    { en: "No Cameras", ar: "لا توجد كاميرات" },
    { en: "Last Match:", ar: "آخر تطابق:" },
    { en: "Code Scanner", ar: "ماسح الكود" },
    { en: "Request Camera Permissions", ar: "طلب السماح باستخدام الكاميرا" },
    { en: "Requesting camera permissions...", ar: "جارٍ طلب أذونات الكاميرا..." },
    { en: "No camera found", ar: "لم يتم العثور على أي كاميرا" },
    { en: "Stop Scanning", ar: "إيقاف المسح" },
    { en: "Start Scanning", ar: "بدء المسح" },
    { en: "Switch On Torch", ar: "تشغيل المصباح" },
    { en: "Switch Off Torch", ar: "إطفاء المصباح" },
    { en: "Failed to turn on torch", ar: "فشل تشغيل المصباح" },
    { en: "Failed to turn off torch", ar: "فشل إطفاء المصباح" },
    { en: "Launching Camera...", ar: "جارٍ تشغيل الكاميرا..." },
    { en: "Scan an Image File", ar: "مسح ملف صورة" },
    { en: "Scan using camera directly", ar: "المسح باستخدام الكاميرا مباشرة" },
    { en: "Select Camera", ar: "تحديد الكاميرا" },
    { en: "Choose Image", ar: "اختيار صورة" },
    { en: "Choose Another", ar: "اختيار آخر" },
    { en: "No image choosen", ar: "لم يتم اختيار أي صورة" },
    { en: "Anonymous Camera", ar: "كاميرا مجهولة" },
    { en: "Or drop an image to scan", ar: "أو اسحب صورة لمسحها" },
    { en: "Or drop an image to scan (other files not supported)", ar: "أو اسحب صورة لمسحها (لا يتم دعم الملفات الأخرى)" },
    { en: "zoom", ar: "تكبير" },
    { en: "Loading image...", ar: "جارٍ تحميل الصورة..." },
    { en: "Camera based scan", ar: "مسح ضوئي قائم على الكاميرا" },
    { en: "Fule based scan", ar: "مسح ضوئي قائم على الملف" },
    { en: "Powered by ", ar: "مدعوم من " },
    { en: "Report issues", ar: "الإبلاغ عن المشاكل" },
    { en: "NotAllowedError: Permission denied", ar: "خطأ: رفض الإذن" },
    { en: "Choose Image - No image choosen", ar: "اختيار صورة" }
]

export class Html5QrcodeTranslate {
    #observer = null

    constructor(elementById) {
        this.#observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach((node) => {
                        this.#textNodeTranslate(node);
                    });
                }
            });
        });

        const config = { childList: true, subtree: true };
        this.#observer.observe(document.querySelector(elementById), config);

        this.#textNodeTranslate(document.querySelector(elementById));
    }

    disconnect() {
        this.#observer !== null && this.#observer.disconnect();
    }

    #translate(text) {
        const translation = translates.find(t => t.en === text);
        return translation ? translation.ar : text;
    }

    #textNodeTranslate(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            node.textContent = this.#translate(node.textContent.trim());
        } else {
            for (let i = 0; i < node.childNodes.length; i++) {
                this.#textNodeTranslate(node.childNodes[i]);
            }
        }
    }
}
