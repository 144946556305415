import React, { useState, useEffect, useRef } from 'react';

import { Typography, Box } from '@mui/material';
import axios from 'axios';

import { InputLabel, Select, MenuItem } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Autocomplete from '@mui/material/Autocomplete';
import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
 
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { arSD } from '@mui/x-data-grid/locales';

import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CacheProvider } from "@emotion/react";

import Checkbox from '@mui/material/Checkbox';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import XLSX from 'sheetjs-style';
import Footer from './Footer';
import Header from './Header';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import createCache from "@emotion/cache";

import '../containers/amirifont.css'; // Import the font CSS file
import Divider from '@mui/material/Divider';
import LockIcon from '@mui/icons-material/Lock';
import { makeStyles } from "@material-ui/core/styles";

import useAuthentication_etab from './useAuthentication_etab';



const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
}, arSD);

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

async function loginUser(email, MotDePass) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, MotDePass: MotDePass }),
    });

    if (!response.ok) {
      throw new Error('Invalid credentials');
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error('Error logging in:', error.message);
    return null;
  }
}


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let rowsdata = [];
let promiseTimeout;
let options_Wly = [];
let options_sess = [];
let options_specialite = [];
let options_bran = [];
function validateName(username) {

  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only alphabets (a to z).');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only Arabic alphabets.');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}



function CustomToolbar({ setRows, setRowModesModel, handleExportExcel, handleAddRecord }) {
  return (


    <GridToolbarContainer style={{ fontFamily: 'Amiri', fontSize: '20px' }} >
      <Button
        onClick={handleAddRecord}
        variant="contained"
        endIcon={<AddCircleOutlineIcon sx={{ fontSize: 40 }} />}
        style={{ backgroundColor: 'green', width: 'fit-content' }}
      >
        <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 24, fontWeight: 900 }}>
          إضافة
        </Typography>
      </Button>

      <GridToolbarColumnsButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarFilterButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarDensitySelector style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <Button style={{ fontFamily: 'Amiri', fontSize: '20px' }} onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>


      <GridToolbarExport style={{ fontFamily: 'Amiri', fontSize: '20px' }} />

      <GridToolbarQuickFilter
        style={{ fontFamily: 'Amiri', fontSize: '20px', marginRight: '25px' }}
        inputProps={{ style: { fontFamily: 'Amiri', fontSize: '20px' } }}
      />



    </GridToolbarContainer>
  );
}




function OffersEtab() {


  const { IDetablissement, IDDFEP, IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  } = useAuthentication_etab();

  const navigate = useNavigate();


  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState('');
  const [loadingdata_mfep, setloadingdata_mfep] = useState(false);

  const [snackbar, setSnackbar] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState('');
  const [valuegenpw_nomUser, setvaluegenpw_nomUser] = useState('');


  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [privilege_users_data, setprivilege_users_data] = useState([]);
  const [selectedID_privilege_users, setselectedID_privilege_users] = useState('');
  const [selectedOption_session, setSelectedOption_session] = useState(null);
  const [selectedOption_branche, setSelectedOption_branche] = useState(null);
  const [selectedOption_spec, setSelectedOption_spec] = useState(null);

  const [options_session, setoptions_session] = useState([]);
  const [options_branche, setoptions_branche] = useState([]);

  const editedValueRef = useRef(editedValue);
  const [IDDFEP_selected, setIDDFEP_selected] = useState(1);
  const [IDsession_selected, setIDsession_selected] = useState(0);
  const [IDBranche_selected, setIDBranche_selected] = useState(1);
  const [IDSpecialite_selected, setIDSpecialite_selected] = useState(0);
  // Define options_specrow state to store specialite options for each row
  const [options_specrow, setOptionsSpecRow] = useState({});
  const [valueDateNais, setvalueDateNais] = useState('');
  const handleInput_DateNais = (event) => {
    const inputValue = event.target.value;
    setvalueDateNais(inputValue);
  };




  // Function to update specialite options for a specific row
  const handleSpecialiteOptionsChange = (rowId, options) => {
    setOptionsSpecRow(prevOptions => ({
      ...prevOptions,
      [rowId]: options
    }));
  };


  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily:
          '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl"
      }
    },
    inputRoot: {

      color: "#191970",


      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple"
      }
    }
  }));

  const classes_listdfep = useStyles_listdfep();





  const onwlyChanged_session = (event, values) => {

    if (values) {
      setSelectedOption_session(values);
      setIDsession_selected(values.value);
    } else {
      return;
    }


  }

  const onwlyChanged_branche = (event, values) => {

    if (values) {
      setSelectedOption_branche(values);
      setIDBranche_selected(values.value);
    } else {
      return;
    }


  }


  const onwlyChanged_spec = (event, values) => {

    if (values) {
      setSelectedOption_spec(values);
      setIDSpecialite_selected(values.value);
    } else {
      return;
    }


  }




  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);




  const apiUrl_session = `${API_BASE_URL}/api/getlistofallsession`;

  // Fetch data from the API when the component mounts
  useEffect(() => {


    setloadingdata_mfep(true);
    fetch(apiUrl_session,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data

        /////////////Wilayets///////////////////

        const WlyOnly = Wlylists.filter(Wlylist => Wlylist.Nom !== null);

        // Sorting in descending order (from A to Z)
        /* options_sess = WlyOnly.sort((a, b) => Number(a.IDSession) - Number(b.IDSession))
             .map((Wlylist, i) => ({
                 value: Wlylist.IDSession,
                 label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
                 indexvalue: i
             }));*/


        // Sorting in descending order (from Z to A)
        options_sess = WlyOnly
          .map((Wlylist, i) => ({
            value: Wlylist.IDSession,
            label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
            indexvalue: i
          }));

        ///// options_sess.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

        setoptions_session(options_sess);

        /// console.log('options_Wly[0].value:'+options_sess[0].value);
        setloadingdata_mfep(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });


  }, []);

  useEffect(() => {
    if (options_session && options_session.length > 0) {
      setSelectedOption_session(options_session[0]);
      setIDsession_selected(options_session[0].value);
    }

  }, [options_session]);



  const [options_spec, setoptions_spec] = React.useState([]);
  const [options_specall, setoptions_specall] = React.useState([]);
  const [options_mode_formation, setoptions_mode_formation] = React.useState([]);

  const apiUrl_branche = `${API_BASE_URL}/api/getlistofallbranche`;
  const apiUrl_spec = `${API_BASE_URL}/api/getlistofallspecialite`;

  const apiUrl_mode_formation = `${API_BASE_URL}/api/getlistofallmode_formation`;


  // Fetch data from the API when the component mounts
  useEffect(() => {



    setloadingdata_mfep(true);
    
    fetch(apiUrl_branche,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data

        /////////////Wilayets///////////////////

        const WlyOnly = Wlylists.filter(Wlylist => Wlylist.Nom !== null);

        /*
        IDBranche
        Nom
        NomFr
        Code
        activee
        numod
        
        */

        // Sorting in descending order (from Z to A)
        options_bran = WlyOnly.sort((a, b) => Number(b.IDBranche) - Number(a.IDBranche))
          .map((Wlylist, i) => ({
            value: Wlylist.IDBranche,
            label: Wlylist.Code + ' | ' + Wlylist.Nom + ' | ' + Wlylist.NomFr,
            indexvalue: i
          }));

        ///// options_bran.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

        setoptions_branche(options_bran);

        /// console.log('options_Wly[0].value:'+options_bran[0].value);

      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });

    /////////////////
    fetch(apiUrl_spec,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data
        //// console.log('specialite: ',data.data);
        /////////////Wilayets///////////////////
        setoptions_specall(Wlylists);

        const WlyOnly = Wlylists.filter(Wlylist => Wlylist.IDSpecialite !== null);

        // Sorting in descending order (from Z to A)
        options_specialite = WlyOnly.sort((a, b) => Number(b.IDSpecialite) - Number(a.IDSpecialite))
          .map((Wlylist, i) => ({

            value: Wlylist.IDSpecialite,
            label: Wlylist.CodeSpec + ' | ' + Wlylist.Nom + ' | ' + Wlylist.NomFr,
            indexvalue: i
          }));

        ///// options_spec.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

        setoptions_spec(options_specialite);

        /// console.log('options_Wly[0].value:'+options_spec[0].value);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });



    /////////////////
  
    fetch(apiUrl_mode_formation,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data
        //// console.log('specialite: ',data.data);
        /////////////Wilayets///////////////////
        setoptions_mode_formation(Wlylists);

        setloadingdata_mfep(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });




  }, []);



  // Fetch data from the API when the component mounts
  useEffect(() => {
    let option_sp = options_specall.filter(listsp => listsp.IDBranche === IDBranche_selected);
    let filteredOptions = option_sp.map((Wlylist, i) => ({
      value: Wlylist.IDSpecialite,
      label: Wlylist.CodeSpec + ' | ' + Wlylist.Nom + ' | ' + Wlylist.NomFr,
      indexvalue: i
    }));
    setoptions_spec(filteredOptions);
  }, [IDBranche_selected, options_specall]);




  const apiUrl = `${API_BASE_URL}/api/getoffer_idsession_iddfep_etab`;

  // Fetch data from the API when the component mounts
  React.useEffect(() => {

    if(IDetablissement==null || IDetablissement==undefined || !Number(IDetablissement)>0)
    {
      return;
    }
    if(IDsession_selected==null || IDsession_selected==undefined || !Number(IDsession_selected)>0)
    {
      return;
    }

    setloadingdata_mfep(true);
    setRows([]); // Clear rows before setting new rows

    fetch(apiUrl + '/' + IDsession_selected + '/' + IDetablissement,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then((response) => response.json())
      .then((data) => {
        const apiRows = data.data.map((item, index) => ({

          id: item.IDOffre,
          id_rows: index + 1,
          IDOffre: item.IDOffre,
          IDSession: item.IDSession,
          IDBranche: item.IDBranche,
          IDSpecialite: item.IDSpecialite,
          IDMode_formation: item.IDMode_formation,
          DateD: item.DateD,
          DateF: item.DateF,
          IDEts_Form: item.IDEts_Form,
          ValidationDfp: item.ValidationDfp,
          ValidDfp: item.ValidDfp,
          ValideCentral: item.ValideCentral,
          Valide: item.Valide,
          code_etab: item.code_etab,
          Nom_Et: item.Nom_Et,
          Nom_NomFr_session: item.Nom_NomFr_session,
          Nom_NomFr_CodeSpec_NbrSem_specialite: item.Nom_NomFr_CodeSpec_NbrSem_specialite,
          code_Nom_etablissement: item.code_Nom_etablissement,
          Nom_NomFr_mode_formation: item.Nom_NomFr_mode_formation,


          /*
                  offre.*, 
                  `session`.Nom AS Nom_session, 
                  `session`.NomFr AS NomFr_session, 
                  CONCAT(`session`.Nom, ' | ', `session`.NomFr) AS Nom_NomFr_session,
                  specialite.CodeSpec, 
                  specialite.Nom AS Nom_specialite, 
                  specialite.NomFr AS NomFr_specialite, 
                  specialite.NbrSem, 
                  CONCAT(specialite.CodeSpec, ' | ', specialite.Nom, ' | ', specialite.NomFr, ' ( ', specialite.NbrSem, ' S ) ') AS Nom_NomFr_CodeSpec_NbrSem_specialite,
                  etablissement.IDetablissement, 
                  etablissement.Nom AS Nom_etablissement, 
                  etablissement.nomFr AS nomFr_etablissement, 
                  etablissement.IDDFEP,
                  etablissement.`Code` as code_etab,
                  etablissement.Nom as 	Nom_Et,
                  CONCAT(etablissement.`Code`, ' | ', etablissement.Nom) AS code_Nom_etablissement,  
                  mode_formation.Nom AS Nom_mode_formation, 
                  mode_formation.NomFr AS NomFr_mode_formation,
                  CONCAT(mode_formation.`Nom`, ' | ', mode_formation.NomFr) AS Nom_NomFr_mode_formation
          */
        }));
        setRows(apiRows);
        console.log('apiRows:', apiRows);
        ///rowsdata=apiRows;
        // Extract unique 'Nom_Et' values
        /// const uniqueValues = [...new Set(apiRows.map(row => row.code_etab))];

        /*
       const uniqueCodeEtabs = [...new Set(apiRows.map(row => row.code_etab))];
       const uniqueValues = uniqueCodeEtabs.map((code_etab, index) => ({
         key: index,
         value: code_etab,
         label: code_etab + ' | ' + apiRows.find(row => row.code_etab === code_etab).Nom_Et
       }));
       
       // Adding the desired object at the beginning of uniqueValues
      uniqueValues.unshift({
        key: 0,
        value: 999,
        label: 'كل المؤسسات'
      });
  
  
       setUniqueNomEtValues(uniqueValues);
       */


        /////console.log('apiRows: ', apiRows);
        ///  console.log('uniqueValues: ',uniqueNomEtValues.length);
        //setSelectedNomEtValue(999);
        setloadingdata_mfep(false);


      })
      .catch((error) => console.error('Error fetching data:', error));


  }, [IDsession_selected, IDetablissement]);


  const renderEditCellWithFontSize = (params, fontSize) => {
    return (
      <TextField
        value={params.value}
        onChange={(e) => params.api.setValue(params.id, e.target.value)}
        style={{ fontSize: fontSize }} // Apply the specified font size here
      />
    );
  };


  // Define state variable to store selected IDs for each Select component
  const [selectedIDs, setSelectedIDs] = useState({});
  const [selectedBranches, setSelectedBranches] = useState({});

  const handleBrancheChange = (idRows, value) => {

    // Update the rows state
    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        if (row.id === idRows) {
          // Check if value is not null or undefined before accessing its value property
          const newValue = value !== null && value !== undefined ? value.value : null;
          return { ...row, IDBranche: newValue };
        }
        return row;
      });
      return updatedRows;
    });




    setSelectedBranches(prevState => ({
      ...prevState,
      [idRows]: value !== null && value !== undefined ? value : null,
    }));
  };

  const [selectedSpecialite, setSelectedSpecialite] = useState({});

  const handlespecialiteChange = (idRows, value) => {

    // Update the rows state
    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        if (row.id === idRows) {
          const newValue = value !== null && value !== undefined ? value.value : null;
          return { ...row, IDSpecialite: newValue };
        }
        return row;
      });

      // Alert the updatedRow here, inside the setRows callback
      /// alert(`Row Data After Update: ${JSON.stringify(updatedRows.find(row => row.id === idRows))}`);

      return updatedRows;
    });

    setSelectedSpecialite(prevState => ({
      ...prevState,
      [idRows]: value !== null && value !== undefined ? value : null,
    }));
  };



  const [selectedmformation, setSelectedmformation] = useState({});

  const handlemformationChange = (idRows, value) => {

    // Update the rows state
    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        if (row.id === idRows) {
          const newValue = value !== null && value !== undefined ? value.value : null;
          return { ...row, IDMode_formation: newValue };
        }
        return row;
      });
      return updatedRows;
    });



    setSelectedmformation(prevState => ({
      ...prevState,
      [idRows]: value !== null && value !== undefined ? value : null,
    }));
  };

  const handleInput_DateNaisss = (event) => {
    const inputValue = event.target.value;
    alert(inputValue);
  };

  ///DateD
  const [selectedDateD, setSelectedDateD] = useState({});

  const handleDateDChange = (idRows, newValue) => {

    // Update the rows state
    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        if (row.id === idRows) {

          return { ...row, DateD: newValue };
        }
        return row;
      });
      return updatedRows;
    });




    setSelectedDateD(prevState => ({
      ...prevState,
      [idRows]: newValue,
    }));
  };

  ///DateF


  const [selectedDateF, setSelectedDateF] = useState({});

  const handleDateFChange = (idRows, newValue) => {
    // Update the rows state
    setRows(prevRows => {
      const upDateFRows = prevRows.map(row => {
        if (row.id === idRows) {

          return { ...row, DateF: newValue };
        }
        return row;
      });
      return upDateFRows;
    });



    setSelectedDateF(prevState => ({
      ...prevState,
      [idRows]: newValue,
    }));
  };






  // Handler to update selected ID for a specific Select component
  const handleChange_privilege_users = (event, index, id) => {
    const newID = event.target.value;

    // Update selectedIDs state
    setSelectedIDs(prevState => ({
      ...prevState,
      [id]: newID,
    }));



    /* // Update the corresponding row in the rows state with the new ID
   setRows(prevRows => {
     const updatedRows = prevRows.map(row => {
       if (row.id === id) {
         return { ...row, IDprivilege_users: newID };
       }
       return row;
     });
     
     // Alert the updatedRow here, inside the setRows callback
     alert(`Row Data After Update: ${JSON.stringify(updatedRows.find(row => row.id === id))}`);
     
     return updatedRows;
   });*/




  };


  // Render EditCell for privilege_users
  const renderEditCell_privilege_users = (params) => {
    /// //console.log("selectedIDs[params.value]",selectedIDs[params.value]);
    /////console.log("selectedIDs[params.value]",params.id);
    return (
      <div>
        <FormControl fullWidth>
          <Select
            label={`Select NomAr ${params.id}`}
            sx={{
              fontFamily: 'Amiri',
              fontSize: '18px',
              fontWeight: 'bold',
            }}
            value={selectedIDs[params.id] || params.value} // Get value from selectedIDs based on params.value
            onChange={(event) => handleChange_privilege_users(event, params.value, params.id)} // Pass params.value to handleChange_privilege_users
          >
            {privilege_users_data.map(item => (
              <MenuItem
                sx={{
                  fontFamily: 'Amiri',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}

                key={item.ID} value={item.ID}>{item.NomAr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  // Render Cell for privilege_users
  const renderCell_privilege_users = (params) => {
    const rowData = privilege_users_data.find(item => Number(item.ID) === Number(params.value));
    return (
      <div>
        {rowData ? rowData.NomAr : ''}
      </div>
    );
  };



  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.commit) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
        ////handleSaveOperation(editedRow.id);


      }
    }
  };




  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };



  const [idsaved, setidsaved] = useState(0);


  const [confirmationTrigger, setConfirmationTrigger] = useState(false);



  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);



  const handleDeleteClick = (id) => () => {

    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { id } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(id);


    }


  };




  const handleConfirmationResponse = async (confirmed) => {

    // Close the confirmation dialog
    setConfirmationOpen(false);
    if (confirmed) {
      const idToDelete = deletedRowId;
      setDeletedRowId(null); // Reset deletedRowId to avoid multiple deletions

      try {
        // Send the API request to delete the row from the Laravel backend
        const response = await fetch(`${API_BASE_URL}/api/deleteRow_fromoffer/${idToDelete}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

        if (response.ok) {
          // If the API request is successful, update the local state to remove the row
          setRows((prevRows) => prevRows.filter((row) => row.id !== idToDelete));
          ////////console.log('Row deleted successfully from Laravel backend.');
          setSnackbar({ children: '  تم الحذف بنجاح  ', severity: 'success' });

        } else {
          console.error('Failed to delete row from Laravel backend.');
          // Handle the error or display a notification to the user
        }
      } catch (error) {
        console.error('Error during the API request:', error);
        // Handle the error or display a notification to the user
      }
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;
      handleSaveOperation(idforsavea);
      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };



  const processRowUpdate = async (newRow) => {

    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Display an alert with the updated row data and id
    ///alert(`Row Data After Update for ID ${newRow.id}: ${JSON.stringify(newRow)}`);

    /* try {
 
       // Call handleSaveOperation with the id
       await handleSaveOperation(newRow.id, updatedRow);
     } catch (error) {
       console.error('Error during handleSaveOperation:', error);
     }*/

    return updatedRow;
  };


  // Function to check if a string is a valid date
  function isValidDate(dateString) {
    // Attempt to parse the date
    const parsedDate = new Date(dateString);

    // Check if the parsed date is a valid date
    return !isNaN(parsedDate.getTime());
  }


  const handleSaveOperation = async (id, updatedRow) => {

    let new_selectedSpecialite;
    if (selectedSpecialite[id] !== undefined) {
      new_selectedSpecialite = selectedSpecialite[id].value;
    } else if (updatedRow && updatedRow.IDSpecialite) {
      new_selectedSpecialite = updatedRow.IDSpecialite;
    }

    let new_selectedBranches;
    if (selectedBranches[id] !== undefined) {
      new_selectedBranches = selectedBranches[id].value;
    } else if (updatedRow && updatedRow.IDBranche) {
      new_selectedBranches = updatedRow.IDBranche;
    }

    let new_selectedmformation;
    if (selectedmformation[id] !== undefined) {
      new_selectedmformation = selectedmformation[id].value;
    } else if (updatedRow && updatedRow.IDMode_formation) {
      new_selectedmformation = updatedRow.IDMode_formation;
    }

    let new_selectedDateD;
    if (selectedDateD[id] !== undefined) {
      new_selectedDateD = selectedDateD[id];
    } else if (updatedRow && updatedRow.DateD) {
      new_selectedDateD = updatedRow.DateD;
    }

    let new_selectedDateF;
    if (selectedDateF[id] !== undefined) {
      new_selectedDateF = selectedDateF[id];
    } else if (updatedRow && updatedRow.DateF) {
      new_selectedDateF = updatedRow.DateF;
    }



    const startDate = dayjs(new_selectedDateD);
    const endDate = dayjs(new_selectedDateF);

    // Calculate the difference in days
    const numberOfDays = endDate.diff(startDate, 'day');

    if (isEmpty(new_selectedBranches)) {
      setSnackbar({ children: 'حدد الشعبة', severity: 'error' });

      return false;
    }

    if (isEmpty(new_selectedSpecialite)) {
      setSnackbar({ children: 'حدد التخصص', severity: 'error' });

      return false;
    }

    if (isEmpty(new_selectedmformation)) {
      setSnackbar({ children: 'حدد نمط التكوين', severity: 'error' });

      return false;
    }

    if (isEmpty(new_selectedDateD) || !isValidDate(new_selectedDateD)) {
      setSnackbar({ children: 'حدد تاريخ البداية', severity: 'error' });

      return false;
    }

    if (isEmpty(new_selectedDateF) || !isValidDate(new_selectedDateF)) {
      setSnackbar({ children: 'حدد تاريخ النهاية', severity: 'error' });

      return false;
    }

    if (numberOfDays < 7) {
      setSnackbar({ children: 'المدة التكوين غير صحيحة', severity: 'error' });

      return false;
    }



    const editedRow = {
      ...updatedRow, IDSpecialite: new_selectedSpecialite, IDBranche: new_selectedBranches, IDMode_formation: new_selectedmformation,
      DateD: new_selectedDateD, DateF: new_selectedDateF
    };

    ////alert(`Row Data After Update: ${JSON.stringify(editedRow)}`);
    console.log(`Row Data After Update: ${JSON.stringify(editedRow)}`);

    try {
      let response;


      // If the row is not new, it means it's already in the database, so update it
      response = await fetch(`${API_BASE_URL}/api/updateRowofferetab/${id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', // Specify the content type
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        },
        body: JSON.stringify(editedRow),
      });


      if (response.ok) {



        // Set the snackbar message
        setSnackbar({ children: '  تم الحفظ  ', severity: 'success' });

        // Set the row mode to view after saving
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });


        setloadingdata_mfep(true);
        setRows([]); // Clear rows before setting new rows

        fetch(apiUrl + '/' + IDsession_selected + '/' + IDetablissement,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          })
          .then((response) => response.json())
          .then((data) => {
            const apiRows = data.data.map((item, index) => ({

              id: item.IDOffre,
              id_rows: index + 1,
              IDOffre: item.IDOffre,
              IDSession: item.IDSession,
              IDBranche: item.IDBranche,
              IDSpecialite: item.IDSpecialite,
              IDMode_formation: item.IDMode_formation,
              DateD: item.DateD,
              DateF: item.DateF,
              IDEts_Form: item.IDEts_Form,
              ValidationDfp: item.ValidationDfp,
              ValidDfp: item.ValidDfp,
              ValideCentral: item.ValideCentral,
              Valide: item.Valide,
              code_etab: item.code_etab,
              Nom_Et: item.Nom_Et,
              Nom_NomFr_session: item.Nom_NomFr_session,
              Nom_NomFr_CodeSpec_NbrSem_specialite: item.Nom_NomFr_CodeSpec_NbrSem_specialite,
              code_Nom_etablissement: item.code_Nom_etablissement,
              Nom_NomFr_mode_formation: item.Nom_NomFr_mode_formation,

            }));
            setRows(apiRows);

            setloadingdata_mfep(false);


          })
          .catch((error) => console.error('Error fetching data:', error));



      } else {
        console.error('Failed to save record in the database.');

        // Extract validation error messages from the API response
        const errorData = await response.json();
        const validationErrors = errorData?.error;

        // Concatenate validation error messages
        const errorMessage =
          'الأخطاء:' +
          Object.values(validationErrors || {}).join(', ');

        console.error('errorMessage:', errorMessage);

        setSnackbar({ children: validationErrors, severity: 'error' });

      }
    } catch (error) {
      console.error('Error during the API request:', error);

      // Set the snackbar message for the error
      setSnackbar({ children: 'حدث خطأ', severity: 'error' });
    }

  };

  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };





  const [addRecordClicked, setAddRecordClicked] = useState(false);

  useEffect(() => {
    if (addRecordClicked) {
      handleAddRecord();
      setAddRecordClicked(false);
    }
  }, [addRecordClicked]);

  const handleAddClick = () => {
    setIsAdding(true);
    setAddRecordClicked(true);
  };




  const handleAddRecord = () => {
    const id = uuidv4();



    // Add an empty row at the end of the existing rows
    const emptyRow = {
      id: id,
      IDOffre: '',
      IDEts_Form: IDetablissement,
      IDSession: IDsession_selected,
      IDBranche: 0,
      IDSpecialite: 0,
      IDMode_formation: 0,
      DateD: '',
      DateF: '',
      Valide: 0,
      ValidDfp: 0,
      ValideCentral: 0,
      actions: '',
      isNew: true,

    };

    setRows((oldRows) => [...oldRows, emptyRow]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'IDSpecialite' },
    }));
  };




  const handleRowModesModelChange = (newRowModesModel) => {

    setRowModesModel(newRowModesModel);
  };



  /*

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  
    // Check if a row is in edit mode
    const editedRowId = Object.keys(newRowModesModel).find(
      (id) => newRowModesModel[id]?.mode === GridRowModes.Edit
    );
  
    // If a row is in edit mode, trigger the save operation
    if (editedRowId) {
      handleSaveOperation(editedRowId);
    }
  };

*/
  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };


  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#4caf50", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>


          <Dialog
            open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider >
                <Grid item sx={{ mt: 2 }} >
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: '0px' }} />
                  </Typography>
                </Grid>
              </Divider>


            </DialogTitle>



            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                هل أنت متأكد من أنك تريد حذف هذا السطر؟

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#4caf50", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </CacheProvider>
    );
  };


  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  /*
  
  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, {
      header: columns.map((col) => col.headerName),
    });
  
    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };
  
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };*/

  const handleSaveClick = (id, row) => () => {
    /// setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    handleSaveOperation(row.id, row);


  };


  const columns = [

    {
      field: 'id_rows',
      headerName: 'ترقيم',
      minWidth: 40,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'IDOffre',
      headerName: 'IDOffre',
      minWidth: 55,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'IDBranche',
      headerName: 'الشعبة',
      flex: 1,
      editable: true,
      minWidth: 300,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

      renderCell: (params) => {
        const matchingOption = options_branche.find(option => Number(option.value) === Number(params.value));
        return matchingOption ? matchingOption.label : '';
      },

      renderEditCell: (params) => (

        <Grid item xs={12}>

          <Autocomplete
            noOptionsText={'حدث الصفحة مرة أخرى'}
            classes={classes_listdfep}
            id="combo-box-demobranche"
            value={selectedBranches[params.row.id] !== undefined ? selectedBranches[params.row.id] : options_branche.find(option => Number(option.value) === Number(params.value))}
            options={options_branche}
            onChange={(event, value) => {
              handleBrancheChange(params.row.id, value);
            }}
            renderOption={(props, option) => {
              const { label, indexvalue } = option;
              return (
                <span
                  {...props}
                  style={{
                    backgroundColor: indexvalue % 2 === 0 ? '#add8e6' : '#FFFFFF',
                    color: '#191970',
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  {indexvalue === -1 ? '- ' + label : label}
                </span>
              );
            }}
            renderInput={(params) => (
              <Box display="flex" justifyContent="center">
                <TextField fullWidth {...params} label="الشعبة" />
              </Box>
            )}
          />
        </Grid>
      ),

    },

    {
      field: 'IDSpecialite',
      headerName: 'التخصص',
      flex: 1,
      editable: true,
      minWidth: 300,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => {
        const selectedSpeciality = options_specall.find(spec => Number(spec.IDSpecialite) === Number(params.value));
        return selectedSpeciality ? `${selectedSpeciality.CodeSpec} | ${selectedSpeciality.Nom} | ${selectedSpeciality.NomFr}` : '';
      },

      renderEditCell: (params) => {
        const selectedBranche = selectedBranches[params.row.id] || null;
        const WlyOnly = options_specall.filter(Wlylist => Number(Wlylist.IDBranche) === (selectedBranche ? Number(selectedBranche.value) : Number(params.row.IDBranche)));

        // Sorting in descending order (from Z to A)
        const options_specialite = WlyOnly.sort((a, b) => Number(b.IDSpecialite) - Number(a.IDSpecialite))
          .map((Wlylist, i) => ({
            value: Wlylist.IDSpecialite,
            label: Wlylist.CodeSpec + ' | ' + Wlylist.Nom + ' | ' + Wlylist.NomFr,
            indexvalue: i
          }));

        return (
          <Grid item xs={12}>
            <Autocomplete
              noOptionsText={'حدث الصفحة مرة أخرى'}
              classes={classes_listdfep}
              id="combo-box-demobranche"
              value={selectedSpecialite[params.row.id] !== undefined ? selectedSpecialite[params.row.id] : options_specialite.find(option => Number(option.value) === Number(params.value))}
              options={options_specialite}
              onChange={(event, value) => {
                handlespecialiteChange(params.row.id, value);
              }}
              renderOption={(props, option) => {
                const { label, indexvalue } = option;
                return (
                  <span
                    {...props}
                    style={{
                      backgroundColor: indexvalue % 2 === 0 ? '#add8e6' : '#FFFFFF',
                      color: '#191970',
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 18,
                      fontWeight: 700,
                    }}
                  >
                    {indexvalue === -1 ? '- ' + label : label}
                  </span>
                );
              }}
              renderInput={(params) => (
                <Box display="flex" justifyContent="center">
                  <TextField fullWidth {...params} label="التخصصات" />
                </Box>
              )}
            />
          </Grid>
        );
      }


    }
    ,


    {
      field: 'IDMode_formation',
      headerName: 'نمط التكوين',
      flex: 1,
      minWidth: 250,
      editable: true,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => {
        const selectedSpeciality = options_mode_formation.find(spec => spec.IDMode_formation === params.value);
        return selectedSpeciality ? `${selectedSpeciality.Nom} | ${selectedSpeciality.NomFr}` : '';
      },

      renderEditCell: (params) => {
        const WlyOnly = options_mode_formation.filter(Wlylist => Wlylist.IDMode_formation !== null);

        // Sorting in descending order (from Z to A)
        const options_mformation = WlyOnly.sort((a, b) => Number(b.IDMode_formation) - Number(a.IDMode_formation))
          .map((Wlylist, i) => ({
            value: Wlylist.IDMode_formation,
            label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
            indexvalue: i
          }));

        return (
          <Grid item xs={12}>
            <Autocomplete
              noOptionsText={'حدث الصفحة مرة أخرى'}
              classes={classes_listdfep}
              id="combo-box-demobranche"
              value={selectedmformation[params.row.id] !== undefined ? selectedmformation[params.row.id] : options_mformation.find(option => option.value === params.value)}
              options={options_mformation}
              onChange={(event, value) => {
                handlemformationChange(params.row.id, value);
              }}
              renderOption={(props, option) => {
                const { label, indexvalue } = option;
                return (
                  <span
                    {...props}
                    style={{
                      backgroundColor: indexvalue % 2 === 0 ? '#add8e6' : '#FFFFFF',
                      color: '#191970',
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 18,
                      fontWeight: 700,
                    }}
                  >
                    {indexvalue === -1 ? '- ' + label : label}
                  </span>
                );
              }}
              renderInput={(params) => (
                <Box display="flex" justifyContent="center">
                  <TextField fullWidth {...params} label="نمط التكوين" />
                </Box>
              )}
            />
          </Grid>
        );
      }
    },

    {
      field: 'DateD',
      headerName: 'تاريخ البداية',
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',

      renderEditCell: (params) => {
        return (
          <Grid item xs={12}>
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>
                  <div dir="ltr">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          value={selectedDateD[params.row.id] !== undefined ? selectedDateD[params.row.id] : dayjs(params.value)}
                          onChange={(newValue) => {
                            const formattedDate = newValue.format("YYYY-MM-DD");
                            handleDateDChange(params.row.id, formattedDate);
                          }}
                          format="YYYY-MM-DD" // Set the desired format here
                          placeholder="YYYY-MM-DD"
                          sx={{
                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#b31d3e',
                              fontWeight: 700
                            },
                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#191970'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#191970',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#b31d3e',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#add8e6',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#add8e6',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: 'purple',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'purple',
                              },
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Box>
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        );
      },


    },

    {
      field: 'DateF',
      headerName: 'تاريخ النهاية',
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderEditCell: (params) => {

        return (
          <Grid item xs={12}>
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>
                  <div dir="ltr">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          value={selectedDateF[params.row.id] !== undefined ? selectedDateF[params.row.id] : dayjs(params.value)}

                          onChange={(newValue) => {
                            const formattedDate = newValue.format("YYYY-MM-DD");
                            handleDateFChange(params.row.id, formattedDate);
                            ///handleDateFChange(params.row.id, newValue);
                          }}
                          format="YYYY-MM-DD" // Set the desired format here
                          placeholder="YYYY-MM-DD"
                          sx={{
                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#b31d3e',
                              fontWeight: 700
                            },
                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#191970'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#191970',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#b31d3e',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#add8e6',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#add8e6',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: 'purple',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'purple',
                              },
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Box>
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        );
      },

    },

    {
      field: 'Valide',
      headerName: 'مصادقة المؤسسة',
      minWidth: 140,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',

      renderCell: ({ row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

        if (row && Number(row.ValidDfp) === 1 && Number(row.Valide) === 1) {

          return Number(row.Valide) === 1 ? <CheckCircleIcon style={{ color: 'green' }} /> : null;


        } else if (row && isIntegerNumber(row.IDOffre) && !isInEditMode) {
          return (
            <FormControlLabel
              control={<IOSSwitch
                sx={{ m: 1 }}
                checked={Number(row.Valide) === 1 ? true : false}
                onChange={() => onchangeValid(row)}
              />}
              label={<span style={{
                color: "#191970",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 16,
                fontWeight: 900,
              }}></span>}
            />
          );
        }
        else {
          return (
            <FormControlLabel
              control={<IOSSwitch
                sx={{ m: 1 }}
                checked={false}
                disabled={true}
              />}
              label={<span style={{
                color: "#191970",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 16,
                fontWeight: 900,
              }}></span>}
            />
          );
        }
      }

    },


    {
      field: 'ValidDfp',
      headerName: 'مصادقة المديرية',
      minWidth: 180,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        if (row && Number(row.Valide) === 0) {
          return (
            <React.Fragment>
              <Typography
                style={{
                  color: 'darkred',
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                variant="h3"
                gutterBottom>
                <HourglassTopIcon style={{ marginRight: '5px' }} />
                المؤسسة ...
              </Typography>
            </React.Fragment>
          );
        } else {
          return row && Number(row.ValidDfp) === 1 ? (
            <CheckCircleIcon style={{ color: 'green' }} />
          ) : (
            <React.Fragment>
              <Typography
                style={{
                  color: 'darkorange',
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                variant="h3"
                gutterBottom>
                <HourglassBottomIcon style={{ marginRight: '5px' }} />
                قيد المراجعة...
              </Typography>
            </React.Fragment>
          );
        }
      }
    },


    {
      field: 'ValideCentral',
      headerName: 'مصادقة الوزارة',
      minWidth: 180,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {

        if (row && Number(row.Valide) === 0) {
          return (
            <React.Fragment>
              <Typography
                style={{
                  color: 'darkred',
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                variant="h3"
                gutterBottom>
                <HourglassTopIcon style={{ marginRight: '5px' }} />
                المؤسسة ...
              </Typography>
            </React.Fragment>
          );
        }
        else if (row && Number(row.ValidDfp) === 0) {
          return (
            <React.Fragment>
              <Typography
                style={{
                  color: 'darkred',
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                variant="h3"
                gutterBottom>
                <HourglassTopIcon style={{ marginRight: '5px' }} />
                المديرية ...
              </Typography>
            </React.Fragment>
          );
        } else {
          return Number(row.ValideCentral) === 1 ? (
            <CheckCircleIcon style={{ color: 'green' }} />
          ) : (
            <React.Fragment>
              <Typography
                style={{
                  color: 'darkorange',
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
                variant="h3"
                gutterBottom>
                <HourglassBottomIcon style={{ marginRight: '5px' }} />
                قيد المراجعة...
              </Typography>
            </React.Fragment>
          );
        }
      }
    },
    ///{ field: 'crypted_id', headerName: 'crypted_id', width: 200, editable: false },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'إجراءات',
      flex: 1,
      align: 'left',
      minWidth: 220,
      cellClassName: 'actions',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      getActions: ({ id, params, row }) => {

        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const isRowEditable = (row && Number(row.ValideCentral) === 0 && Number(row.ValidDfp) === 0 && Number(row.Valide) === 0);

        if (isInEditMode) {
          return [
            <Button
              onClick={handleSaveClick(id, row)}
              variant="contained"
              endIcon={<SaveIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />}
              style={{ backgroundColor: 'green', width: 'fit-content' }}
            >
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                حفظ
              </Typography>
            </Button>,
            <Button
              onClick={handleCancelClick(id)}
              variant="contained"
              endIcon={<CancelIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />}
              style={{ backgroundColor: 'grey', width: 'fit-content' }}
            >
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                إلغاء
              </Typography>
            </Button>
          ];
        }

        if (isRowEditable) {
          return [
            <Button
              onClick={handleEditClick(id)}
              variant="contained"
              endIcon={<EditIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />}
              style={{ backgroundColor: '#1976d2', width: 'fit-content' }}
            >
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                تعديل
              </Typography>
            </Button>,
            <Button
              onClick={handleDeleteClick(id)}
              variant="contained"
              endIcon={<DeleteIcon sx={{ mr: '0px', ml: '0px', transform: 'rotate(0deg)' }} />}
              style={{ backgroundColor: 'red', width: 'fit-content' }}
            >
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                حذف
              </Typography>
            </Button>
          ];
        }
        return []; // Return an empty array if the row is not editable and not in edit mode
      },
    }

  ];


  const styles_cell_editable = {
    cell_editable: {
      border: '2px solid green', // Define the border style
    },
  };

  const CellRenderer = ({ params }) => {
    const { id, value } = params;

    const [isActive, setIsActive] = useState(Number(value) === 1);


    const handleChange = () => {
      setIsActive(!isActive);
    };

    return (

      <div>
        <Checkbox
          checked={isActive}
          onChange={handleChange}
          disabled={!params.row.editable}
          color="success" // Set the color to "success"
        />
        {isActive ? 'قَيْد التشغيل' : 'مُعَطَّل'}
      </div>
    );
  };


  const EditCellRenderer = ({ params }) => {
    const { id, value, api, field } = params;
    const [isActive, setIsActive] = useState(Number(value) === 1);

    const handleChange = () => {
      setIsActive(!isActive);
      api.setEditCellValue({ id, field, value: isActive ? "0" : "1" });
    };

    return (


      <Checkbox
        checked={isActive}
        onChange={handleChange}
        color="success" // Set the color to "success"
      />

    );
  };








  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  const CopyToClipboardButton_nomUser = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    ////setSnackbar({ children:  `  تم نسخ  ${value}  `, severity: 'success' });
    setSnackbar({ children: <span dangerouslySetInnerHTML={{ __html: `تم نسخ <div dir="ltr">${value}</div>` }} />, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };




  // Function to check if a value is empty, null, undefined, or equal to 0
  const isEmpty = (value) => value === "" || value === null || value === undefined || value === 0;

  function onchangeValid(row) {

    if (Number(row.Valide) === 1) {
      /// alert(0);
      row.Valide = 0; // Set Valid to 0
    } else if (Number(row.Valide) === 0) {
      //// alert(1);
      row.Valide = 1; // Set Valid to 1
    }

    // Update the state of the row data
    setRows(prevRows => {
      // Create a new array by mapping over the previous rows
      return prevRows.map(prevRow => {
        // If the ID of the current row matches the modified row, return the modified row
        if (prevRow.id === row.id) {
          return row;
        }
        // Otherwise, return the unchanged row
        return prevRow;
      });
    });

    handleSaveOperation(row.id, row);

  }

  function isIntegerNumber(str) {
    return /^\d+$/.test(str);
  }




  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDNature_etsF === null ||
        Number(IDNature_etsF) === 5 ||
        Number(IDNature_etsF) === 1
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/404');
        return;
      }
    }
  }, [IDetablissement, IDDFEP, IDNature_etsF, IDCandidat, NumSem, IDapis_userslist,
    loadingauth, navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }



  return (
    <>
      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={download_cert_inscription_active_Dfep}
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={download_cert_inscription_active_Mfep}
      />






      <Box
        sx={{
          '& .super-app-theme--header': {
            fontFamily: 'Amiri',
            fontSize: '20px',
            fontWeight: 'bold', // Make the font bold
            backgroundColor: '#B9D3EE',
            borderRight: '1px solid #ccc', // Add this line to apply right border to the cell
          }

        }}

      >

        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={themetextfieldnew}>



            {!!snackbar && (
              <Snackbar
                open
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                sx={{
                  marginTop: 12
                }}
              >
                <Alert
                  {...snackbar}
                  onClose={handleCloseSnackbar}
                  sx={{
                    display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                    , '& .MuiAlert-action': { marginRight: '8px' }
                  }} // Adjust padding here
                />
              </Snackbar>

            )}



            <Grid container   >


              <Grid item sx={{ width: "100%" }}>

                <Divider sx={{ mt: '20px' }}>
                  <Grid item  >
                    <Typography
                      style={{
                        color: "#191970",
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 25,
                        fontWeight: 700,
                        marginBottom: '25px'
                      }}
                      variant="h3" gutterBottom>
                      <EventRepeatIcon sx={{ mr: '5px' }} />
                      عروض التكوين
                    </Typography>
                  </Grid>

                </Divider>



                <Grid container justifyContent="center">



                </Grid>





              </Grid>



              <Grid container alignItems="center" align="center" justifyContent="center" sx={{ width: "100%", marginBottom: "10px" }}>

                <Grid item xs={12}>
                  <Grid item xs={5}>

                    <CacheProvider value={cacheRtl}>


                      <Autocomplete
                        noOptionsText={'حدث الصفحة مرة أخرى'}
                        classes={classes_listdfep}
                        id="combo-box-demowly"
                        onChange={onwlyChanged_session}
                        value={selectedOption_session} // Use the value prop to set the selected option
                        options={options_session}

                        defaultValue={options_session[0]} // Set the defaultValue to the first option
                        sx={{ mt: 1, mb: 1 }}
                        renderOption={(props, option) => {
                          const { label, indexvalue } = option;
                          return (
                            <span
                              {...props}
                              style={{
                                backgroundColor: indexvalue % 2 === 0 ? "#add8e6" : "#FFFFFF",
                                color: "#191970",
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 18,
                                fontWeight: 700,
                              }}
                            >
                              {indexvalue === -1 ? '- ' + label : label}
                            </span>
                          );
                        }}

                        renderInput={(params) => (
                          <Box display="flex" justifyContent="center">
                            <TextField fullWidth {...params} label="الدورات" />
                          </Box>
                        )}


                      />


                    </CacheProvider>

                  </Grid>
                </Grid>
              </Grid>

              {/* 
    <Grid item xs={12}>
          <Grid item xs={5}>
      
    <CacheProvider value={cacheRtl}>
    
     
    <Autocomplete
      noOptionsText={'حدث الصفحة مرة أخرى'}
      classes={classes_listdfep}
      id="combo-box-demobranche"
      onChange={onwlyChanged_branche}
      value={selectedOption_branche} // Use the value prop to set the selected option
      options={options_branche}
     
      defaultValue={options_branche[0]} // Set the defaultValue to the first option
      sx={{ mt: 1, mb: 1 }}
      renderOption={(props, option) => {
        const { label, indexvalue } = option;
        return (
          <span
            {...props}
            style={{
              backgroundColor: indexvalue % 2 === 0 ? "#add8e6" : "#FFFFFF",
              color: "#191970",
              fontFamily: '"Tajawal", sans-serif',
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            {indexvalue === -1 ? '- ' + label : label}
          </span>
        );
      }}
      
      renderInput={(params) => (
        <Box display="flex" justifyContent="center">
          <TextField fullWidth {...params} label="الشعبة" />
        </Box>
      )}
    
     
    />
    

    </CacheProvider>
    
    </Grid>
    </Grid>


    <Grid item xs={12}>
          <Grid item xs={5}>
      
    <CacheProvider value={cacheRtl}>
    
     
    <Autocomplete
      noOptionsText={'حدث الصفحة مرة أخرى'}
      classes={classes_listdfep}
      id="combo-box-demobranche"
      onChange={onwlyChanged_spec}
      value={selectedOption_spec} // Use the value prop to set the selected option
      options={options_spec}
     
      defaultValue={options_spec[0]} // Set the defaultValue to the first option
      sx={{ mt: 1, mb: 1 }}
      renderOption={(props, option) => {
        const { label, indexvalue } = option;
        return (
          <span
            {...props}
            style={{
              backgroundColor: indexvalue % 2 === 0 ? "#add8e6" : "#FFFFFF",
              color: "#191970",
              fontFamily: '"Tajawal", sans-serif',
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            {indexvalue === -1 ? '- ' + label : label}
          </span>
        );
      }}
      
      renderInput={(params) => (
        <Box display="flex" justifyContent="center">
          <TextField fullWidth {...params} label="التخصصات" />
        </Box>
      )}
    
     
    />

    
    </CacheProvider>
    
    </Grid>
    </Grid>
            </Grid>

          */}

              {loadingdata_mfep ? <>

                <Box sx={{ width: '100%' }}>
                  <LinearProgress color="success" />
                </Box>

              </> : null}

              <DataGrid
                locale="ar-SD"
                rows={rows}
                columns={columns}
                isCellEditable={(params) => (Number(params.row.ValideCentral) === 0 && Number(params.row.ValidDfp) === 0 && Number(params.row.Valide) === 0)}

                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}

                getRowId={(row) => row.id}
                getRowClassName={(params) =>
                  parseInt(params.row.id_rows, 10) % 2 === 0 ? 'even-row' : 'odd-row'
                }

                slots={{
                  toolbar: CustomToolbar,
                }}

                slotProps={{
                  toolbar: { setRows, setRowModesModel, columns, handleExportExcel, handleAddRecord },
                }}

                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',


                  '& .MuiDataGrid-editInputCell': {
                    fontFamily: 'Amiri',
                    fontSize: '20px',
                    textAlign: 'center',
                    backgroundColor: 'rgba(255,255,255,0.65)',
                    borderRight: '1px solid #ccc', // Add this line to apply right border to the cell


                  },

                  '& .MuiDataGrid-cell': {
                    fontFamily: 'Amiri',
                    fontSize: '20px',
                    textAlign: 'center',
                    backgroundColor: 'rgba(255,255,255,0.65)',
                    borderRight: '1px solid #ccc', // Add this line to apply right border to the cell


                  },
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: '#2b84dd', // Background color when row is hovered
                  },

                  '& .even-row': {
                    backgroundColor: '#2b84dd61', // Background color for even rows
                  },
                  '& .odd-row': {
                    backgroundColor: '#ffffff', // Background color for odd rows
                  },

                  '& .cell-editable': styles_cell_editable.cell_editable, // Apply styles to the header with class 'cell-editable'


                }}

                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 25, page: 0 },
                  },
                }}
              />



            </Grid>



          </ThemeProvider>
        </CacheProvider>

      </Box>


      <Footer />

      <ConfirmationDialog open={isConfirmationOpen} onClose={handleConfirmationResponse} />
      <SaveConfirmationDialog open={isSaveConfirmationOpen} onClose={handleSaveConfirmationResponse} />

    </>
  );
}

export default OffersEtab;
