// App.js
import React from 'react';
import Html5QrcodePlugin from './Html5QrcodePlugin';

const AppHtml5qrcode = () => {
  const onNewScanResult = (decodedText, decodedResult) => {
    //console.log("New scan result:", decodedText);
    alert(`QR Code Scanned: ${decodedText}`);
    // handle decoded results here
  };

  return (
    <div className="AppHtml5qrcode">
      <Html5QrcodePlugin
        fps={10}
        qrbox={250}
        disableFlip={false}
        qrCodeSuccessCallback={onNewScanResult}
      />
    </div>
  );
};

export default AppHtml5qrcode;
