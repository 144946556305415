import React from 'react';
import './error404ar.css'; // Import the CSS file

const NotFoundPage = () => (
  <div className="error-container">
    <h1 className="error-message">الصفحة غير موجودة</h1>
  </div>
);

export default NotFoundPage;
