import React, { useState, useEffect } from "react";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import logo2 from '../src/img/logo2.png';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import iconbarleft from '../src/img/logo.png';
import EditIcon from '@mui/icons-material/Edit';

import PersonIcon from '@mui/icons-material/Person';
import { Link } from "react-router-dom";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';
import PrintIcon from '@mui/icons-material/Print';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';


import {
  Button,
  Grid
} from "@mui/material";

import Avatar from '@mui/material/Avatar';
import appico from '../src/img/logo.png';
import downapp from '../src/img/logo.png';

import Footer from '../containers/Footer';

import './amirifont.css';
import pharmacy from '../src/img/logo.png';
import blooddon from '../src/img/logo.png';
import HomeIcon from '@mui/icons-material/Home';
import moresearch from '../src/img/logo.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary
import flag_dz from '../src/img/flag_dz.png';
import {
  useMediaQuery,

} from "@mui/material";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);


const themeRoboto = createTheme({
  typography: {
    fontFamily:
      '"Roboto Slab", serif',
    fontSize: 10

  },
});


const themeChanga = createTheme({
  typography: {
    fontFamily:
      '"Changa", sans-serif',
    fontSize: 10,


  },
});




const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);



  };



  const themeChangaHeader = createTheme({
    typography: {
      fontFamily:
        '"Tajawal", sans-serif',
      fontSize: 16,
      fontWeight: 900


    },
  });

  const themeChanga = createTheme({
    typography: {
      fontFamily:
        '"Tajawal", sans-serif',
      fontSize: 14,
      fontWeight: 900


    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openprint = Boolean(anchorEl);

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isMatch_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch_xs = useMediaQuery(theme.breakpoints.down("xs"));



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl_infocand, setAnchorEl_infocand] = React.useState(null);
  const open_infocand = Boolean(anchorEl_infocand);
  const handleClick_infocand = (event) => {
    setAnchorEl_infocand(event.currentTarget);
  };
  const handleClose_infocand = () => {
    setAnchorEl_infocand(null);
  };


  const [anchorElMotakawin, setAnchorElMotakawin] = React.useState(null);
  const openMotakawin = Boolean(anchorElMotakawin);
  const handleClickMotakawin = (event) => {
    setAnchorElMotakawin(event.currentTarget);

  };
  const handleCloseMotakawin = () => {
    setAnchorElMotakawin(null);
  };

  const navigate = useNavigate();
  const handleClickValidationStEtab = () => {
    // Navigate to the desired route
    navigate('/ValidationCandInfo');
    handleCloseMotakawin();
  };

  const handleClickChangPwStEtab = () => {
    // Navigate to the desired route
    navigate('/ChangPwStEtab');
    handleCloseMotakawin();

  };

  const handleClickChangPwPriv = () => {
    // Navigate to the desired route
    navigate('/ChangPwPriv');
    handleCloseMotakawin();

  };

  const handleClickChangePwEtab = () => {
    // Navigate to the desired route
    navigate('/ChangePwEtab');
    handleCloseMotakawin();

  };




  const [hidepagest, sethidepagest] = useState(false);
  const [hidepageetub, sethidepageetub] = useState(false);





  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CssBaseline />

      <AppBar position="fixed" open={open}>


        {(!open) ? (
          <>

            <div>
              <Box >
                <Grid container columns={{ xs: 12, sm: 12, md: 12 }} sx={{ backgroundColor: '#d6e6f6', display: 'flex', alignItems: 'center' }}>





                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Centering content horizontally and vertically */}
                    <Box sx={{ overflow: 'hidden', ml: 1 }}>

                      {(isMatch_xs) ? (
                        <>
                        </>
                      ) : (isMatch_sm) ? (
                        <>

                        </>
                      ) : (isMatch) ? (
                        <>
                          <img src={appico} style={{ width: '50px', height: '50px' }} alt="Image 1" />
                        </>
                      ) : (
                        <>
                          <img src={appico} style={{ width: '70px', height: '70px' }} alt="Image 1" />
                        </>
                      )}






                    </Box>
                    <Box sx={{ textAlign: 'center' }}> {/* Added left margin for spacing */}
                      <Typography sx={{
                        color: '#191970',
                        fontFamily: '"Tajawal", sans-serif',
                        fontWeight: 900,
                        fontSize: (isMatch_xs ? '10px' : isMatch_sm ? '12px' : isMatch ? '20px' : '12px'),
                        mt: 1
                      }}>
                        الجمهورية الجزائرية الديمقراطية الشعبية
                      </Typography>
                      <Typography sx={{
                        color: '#191970', fontFamily: '"Tajawal", sans-serif', fontWeight: 900,
                        fontSize: (isMatch_xs ? '10px' : isMatch_sm ? '10px' : isMatch ? '20px' : '10px'),
                        mb: 1
                      }}>
                        وزارة التكويـن والتعلـيـم المهنييـن
                      </Typography>
                    </Box>
                    <Box sx={{ overflow: 'hidden', mr: 1 }}>
                      {(isMatch_xs) ? (
                        <>
                        </>
                      ) : (isMatch_sm) ? (
                        <>
                        </>
                      ) : (isMatch) ? (
                        <>
                          <img src={flag_dz} style={{ width: '50px', height: '50px' }} alt="Image 1" />
                        </>
                      ) : (
                        <>
                          <img src={flag_dz} style={{ width: '70px', height: '70px' }} alt="Image 1" />
                        </>
                      )}


                    </Box>
                  </Grid>



                </Grid>


              </Box>
            </div>

          </>) : null}


        <Toolbar  >

          <Typography variant="h6" noWrap
            theme={themeChangaHeader} sx={{
              flexGrow: 1, fontSize: 18, mr: 1, mt: 0
              , fontFamily:
                '"Tajawal", sans-serif',
              fontSize: 20
              , fontWeight: 700
            }} component="div">
            منصة المتكون
          </Typography>

          <a style={{ textDecoration: 'none' }} href="https://www.mfep.gov.dz/">
            <img
              alt="منصة المتكون"
              src={logo2}
              style={{ width: 60, height: 60, }} // Adjust width, height, and border-radius as needed 
            />
          </a>

        </Toolbar>
      </AppBar>








    </Box>




  )
}
