// Jscookiestest.js

import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_BASE_URL } from './config'; // Adjust the path if necessary

const Jscookiestest = () => {
    const handleClick = () => {
        // Set a secure cookie
        Cookies.set('myCookie', 'azizcookieValue', { secure: true });
        const apiUrl_cookie = `${API_BASE_URL}/api/readcookies`;

        // Make a request to the Laravel backend
        axios.get(apiUrl_cookie, {
            withCredentials: true // Send cookies with the request
        })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    };

    return (
        <div>
            <h1>Testing Secure Cookies in React.js</h1>
            <button onClick={handleClick}>Send Secure Cookie</button>
        </div>
    );
};

export default Jscookiestest;
