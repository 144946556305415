import React, { useState, useEffect } from 'react';
import Webcam from 'react-webcam';

function CameraComponent() {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [videoConstraints, setVideoConstraints] = useState({
    width: 640,
    height: 480,
    facingMode: 'user' // Front-facing camera by default
  });
  const [stream, setStream] = useState(null);

  useEffect(() => {
    const handleCameraStart = async () => {
      try {
        const hasPermission = await navigator.permissions.query({ name: 'camera' });
        if (hasPermission.state === 'granted') {
          const stream = await navigator.mediaDevices.getUserMedia({ video: videoConstraints });
          setStream(stream);
          setIsCameraOpen(true); // Update state after stream is set
        } else {
          console.warn('User denied camera permission.');
          // Optionally handle permission denial here (e.g., display an error message)
        }
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    };

    if (isCameraOpen) {
      handleCameraStart();
    } else if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
      setIsCameraOpen(false); // Update state after stream is cleared
    }

    return () => stream?.getTracks().forEach(track => track.stop());
  }, [isCameraOpen, videoConstraints]);

  const handleToggleCamera = () => {
    setIsCameraOpen(!isCameraOpen);
  };

  return (
    <>
      {!isCameraOpen && (
        <button onClick={handleToggleCamera}>Open Camera</button>
      )}
      {isCameraOpen && stream && (
        <div>
          <Webcam audio={false} width={videoConstraints.width} height={videoConstraints.height} videoConstraints={videoConstraints} />
          {/* Consider adding a toggle for front/rear camera: */}
          <button onClick={() => setVideoConstraints({ ...videoConstraints, facingMode: videoConstraints.facingMode === 'user' ? 'environment' : 'user' })}>
            Switch Camera
          </button>
        </div>
      )}
    </>
  );
}

export default CameraComponent;
