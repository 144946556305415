import React, { useState, useEffect, useRef } from 'react';

import { Typography, Box } from '@mui/material';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import localforage from 'localforage'; // Import localforage
import { arSD } from '@mui/x-data-grid/locales';
import './MyToolbarStyles.css'; // Adjust the path as necessary

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,

  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CacheProvider } from "@emotion/react";



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import XLSX from 'sheetjs-style';
import Footer from './Footer';
import Header from './Header';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import createCache from "@emotion/cache";
import DescriptionIcon from '@mui/icons-material/Description';

import '../containers/amirifont.css'; // Import the font CSS file
import Divider from '@mui/material/Divider';
import LockIcon from '@mui/icons-material/Lock';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import { makeStyles } from "@material-ui/core/styles";

const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
}, arSD);

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

async function loginUser(email, password) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, password: password }),
    });

    if (!response.ok) {
      throw new Error('Invalid credentials');
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error('Error logging in:', error.message);
    return null;
  }
}


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {

  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only alphabets (a to z).');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only Arabic alphabets.');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}



const apiUrldfep = `${API_BASE_URL}/api/getlistofalldfep`;

const customStyle = {
  fontFamily: 'Amiri',
  fontSize: '20px',
};


function CustomToolbar({ setRows, setRowModesModel, columns, handleExportExcel, validation_multiple, selectedRows }) {
  return (


    <GridToolbarContainer className="custom-toolbar">
      <GridToolbarColumnsButton className="custom-toolbar" />
      <GridToolbarFilterButton className="custom-toolbar" />
      <GridToolbarDensitySelector className="custom-toolbar" />
      <Button className="custom-toolbar" onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport className="custom-toolbar" />

      <GridToolbarQuickFilter
        className="custom-toolbar-GridToolbarQuickFilter"
        inputProps={{ className: 'custom-toolbar-GridToolbarQuickFilter' }}
      />



    </GridToolbarContainer>
  );
}

let options_Wly = [];
let options_sess = [];
let Wlylists = [];


function Offersinscription() {


  // Inherit the theme from the docs site (dark/light mode)
  const existingTheme = useTheme();

  const theme_datagrid = React.useMemo(
    () =>
      createTheme({}, arSD, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme],
  );


  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState('');

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState('');
  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);
  const [loadingdata_mfep, setloadingdata_mfep] = useState(false);
  const [uniqueNomEtValues, setUniqueNomEtValues] = useState([]);

  const [uniquespecialiteValues, setuniquespecialiteValues] = useState([]);
  const [selectedspecialiteValue, setselectedspecialiteValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "كل التخصصات",
  });

  const [uniqueNomFormationValues, setuniqueNomFormationValues] = useState([]);

  const [selectedNomEtValue, setSelectedNomEtValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "كل المؤسسات",
  });

  const [selectedNomFormationValue, setselectedNomFormationValue] = useState([]);
  /*const [selectedNomFormationValue, setselectedNomFormationValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "جميع الأنماط",
  });*/


  const [filteredRows, setFilteredRows] = useState([]);
  const [gridKey, setGridKey] = useState(0); // Add a key for the DataGrid

  const [IDDFEP_selected, setIDDFEP_selected] = useState(null);
  const [IDsession_selected, setIDsession_selected] = useState(0);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const editedValueRef = useRef(editedValue);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption_session, setSelectedOption_session] = useState(null);

  const [options_Wlylist, setoptions_Wlylist] = React.useState([]);
  const [options_session, setoptions_session] = React.useState([]);


  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily:
          '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl"
      }
    },
    inputRoot: {

      color: "#191970",


      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple"
      }
    }
  }));

  const classes_listdfep = useStyles_listdfep();


  const onwlyChanged_session = (event, values) => {

    if (values) {
      setSelectedOption_session(values);
      setIDsession_selected(values.value);
    } else {
      return;
    }


  }

  const onwlyChanged = (event, values) => {

    if (values) {
      setSelectedOption(values);
      setIDDFEP_selected(values.value);
    } else {
      return;
    }


  }



  const navigate = useNavigate();


  const [Nomsession, setNomsession] = useState('');

  const apiUrl_session = `${API_BASE_URL}/api/getsessionEncour`;

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(apiUrl_session);
        if (!response.ok) {

          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        if (!data || !data.data || !Array.isArray(data.data)) {
          throw new Error('Unexpected data format received from API');
        }

        // Store fetched data in localforage
        await localforage.setItem('sessionEncour', data.data);

        // Process fetched data
        const getsessionEncour = data.data.filter(Wlylist => Wlylist.Nom !== null);


        if (!getsessionEncour
          || getsessionEncour[0] == undefined ||
          !Number(getsessionEncour[0].IDSession) > 0) {
          navigate('/404');
        }
        // Set selected session if available
        if (getsessionEncour.length > 0) {
          setIDsession_selected(getsessionEncour[0].IDSession);
          setNomsession(getsessionEncour[0].Nom);
        }

        setloadingdata_mfep(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setloadingdata_mfep(false);
      }
    };

    const loadDataFromLocalForage = async () => {
      try {
        // Check if data exists in localforage
        const localData = await localforage.getItem('sessionEncour');
        if (localData) {
          // Process fetched data from localforage
          const getsessionEncour = localData.filter(Wlylist => Wlylist.Nom !== null);

          // Set selected session if available
          if (getsessionEncour.length > 0) {
            setIDsession_selected(getsessionEncour[0].IDSession);
            setNomsession(getsessionEncour[0].Nom);
          }

          setloadingdata_mfep(false);
          return;
        }

        // Set the snackbar message
        ////setSnackbar({ children: ' fetching... ', severity: 'success' });

        // If data doesn't exist in localforage, fetch it
        await fetchData();
      } catch (error) {
        console.error('Error loading data from localforage:', error);
        setloadingdata_mfep(false);
      }
    };

    // Load data from localforage or fetch from API
    loadDataFromLocalForage();
  }, []);




  useEffect(() => {

    const dfepData = [
      { IDDFEP: 1, Nom: 'أدرار', NomFr: 'Adrar' },
      { IDDFEP: 2, Nom: 'شلف', NomFr: 'Chlef' },
      { IDDFEP: 3, Nom: 'الأغواط', NomFr: 'Laghouat' },
      { IDDFEP: 4, Nom: 'أم البواقي', NomFr: 'Oum El Bouaghi' },
      { IDDFEP: 5, Nom: 'با تنة', NomFr: 'Batna' },
      { IDDFEP: 6, Nom: 'بجا ية', NomFr: 'Bejaia' },
      { IDDFEP: 7, Nom: 'بسكرة', NomFr: 'Biskra' },
      { IDDFEP: 8, Nom: 'بشا ر', NomFr: 'Bechar' },
      { IDDFEP: 9, Nom: 'البليدة', NomFr: 'Blida' },
      { IDDFEP: 10, Nom: 'البو ير ة', NomFr: 'Bouira' },
      { IDDFEP: 11, Nom: 'تمنراست', NomFr: 'Tamanrasset' },
      { IDDFEP: 12, Nom: 'تبسة', NomFr: 'Tebessa' },
      { IDDFEP: 13, Nom: 'تلمسان', NomFr: 'Tlemcen' },
      { IDDFEP: 14, Nom: 'تيارت', NomFr: 'Tiaret' },
      { IDDFEP: 15, Nom: 'تيزي وزو', NomFr: 'Tizi Ouzou' },
      { IDDFEP: 16, Nom: 'الجزا ئر', NomFr: 'Alger' },
      { IDDFEP: 17, Nom: 'الجلفة', NomFr: 'Djelfa' },
      { IDDFEP: 18, Nom: 'جيجل', NomFr: 'Jijel' },
      { IDDFEP: 19, Nom: 'سطيف', NomFr: 'Setif' },
      { IDDFEP: 20, Nom: 'سعيدة', NomFr: 'Saida' },
      { IDDFEP: 21, Nom: 'سكيكدة', NomFr: 'Skikda' },
      { IDDFEP: 22, Nom: 'سيدي بلعباس', NomFr: 'Sidi Bel Abbes' },
      { IDDFEP: 23, Nom: 'عنابة', NomFr: 'Annaba' },
      { IDDFEP: 24, Nom: 'قالمة', NomFr: 'Guelma' },
      { IDDFEP: 25, Nom: 'قسنطينة', NomFr: 'Constantine' },
      { IDDFEP: 26, Nom: 'المدية', NomFr: 'Medea' },
      { IDDFEP: 27, Nom: 'مستغانم', NomFr: 'Mostaganem' },
      { IDDFEP: 28, Nom: 'مسيلة', NomFr: 'M\'Sila' },
      { IDDFEP: 29, Nom: 'معسكر', NomFr: 'Mascara' },
      { IDDFEP: 30, Nom: 'ورقلة', NomFr: 'Ouargla' },
      { IDDFEP: 31, Nom: 'وهران', NomFr: 'Oran' },
      { IDDFEP: 32, Nom: 'البيض', NomFr: 'El Bayadh' },
      { IDDFEP: 33, Nom: 'إليزي', NomFr: 'Illizi' },
      { IDDFEP: 34, Nom: 'برج بوعريريج', NomFr: 'Bordj Bou Arréridj' },
      { IDDFEP: 35, Nom: 'بومرداس', NomFr: 'Boumerdes' },
      { IDDFEP: 36, Nom: 'الطارف', NomFr: 'El Taref' },
      { IDDFEP: 37, Nom: 'تيندوف', NomFr: 'Tindouf' },
      { IDDFEP: 38, Nom: 'تيسمسيلت', NomFr: 'Tissemsilt' },
      { IDDFEP: 39, Nom: 'الوادي', NomFr: 'El Oued' },
      { IDDFEP: 40, Nom: 'خنشلة', NomFr: 'Khenchela' },
      { IDDFEP: 41, Nom: 'سوق أهراس', NomFr: 'Souk Ahras' },
      { IDDFEP: 42, Nom: 'تيبازة', NomFr: 'Tipaza' },
      { IDDFEP: 43, Nom: 'ميلة', NomFr: 'Mila' },
      { IDDFEP: 44, Nom: 'عين الدفلى', NomFr: 'Ain Defla' },
      { IDDFEP: 45, Nom: 'النعامة', NomFr: 'Naâma' },
      { IDDFEP: 46, Nom: 'عين تيموشنت', NomFr: 'Ain Temouchent' },
      { IDDFEP: 47, Nom: 'غرداية', NomFr: 'Ghardaia' },
      { IDDFEP: 48, Nom: 'غليزان', NomFr: 'Relizane' },
      { IDDFEP: 49, Nom: 'تميمون', NomFr: 'Timimoun' },
      { IDDFEP: 50, Nom: 'برج باجي مختار', NomFr: 'Bordj Badji Mokhtar' },
      { IDDFEP: 51, Nom: 'أولاد جلال', NomFr: 'Ouled  Djellal' },
      { IDDFEP: 52, Nom: 'بني عباس', NomFr: 'Béni Abbès' },
      { IDDFEP: 53, Nom: 'عين صالح', NomFr: 'In Salah' },
      { IDDFEP: 54, Nom: 'عين قزام', NomFr: 'In Guezzam' },
      { IDDFEP: 55, Nom: 'توقرت', NomFr: 'Touggourt' },
      { IDDFEP: 56, Nom: 'جانت', NomFr: 'Djanet' },
      { IDDFEP: 57, Nom: 'المغير', NomFr: 'El M’Ghaier' },
      { IDDFEP: 58, Nom: 'المنيعة', NomFr: 'El Meniaa' }

    ];

    const options_Wly = dfepData.map((Wlylist, i) => ({
      value: Wlylist.IDDFEP,
      label: Wlylist.IDDFEP + '- ' + Wlylist.Nom + '- ' + Wlylist.NomFr,
      indexvalue: i
    }));


    setoptions_Wlylist(options_Wly);
  }, []);



  const MAX_ENTRIES = 3; // Maximum number of entries to keep
  const PREFIX = "dataOffersinscription";
  const apiUrl = `${API_BASE_URL}/api/getoffer_idsession_iddfep_inscription`;

  useEffect(() => {
    const fetchData = async () => {
      try {

        if (IDsession_selected == null || IDsession_selected == undefined || !Number(IDsession_selected) > 0) {
          return
        }

        if (IDDFEP_selected == null || IDDFEP_selected == undefined || !Number(IDDFEP_selected) > 0) {
          return
        }

        // Fetch data from the API
        const response = await fetch(apiUrl + '/' + IDsession_selected + '/' + IDDFEP_selected, {
          headers: {
            Accept: 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        if (!data || !data.data || !Array.isArray(data.data)) {
          throw new Error('Unexpected data format received from API');
        }

        // Process fetched data
        const apiRows = data.data.map((item, index) => ({
          id: item.IDOffre,
          id_rows: index + 1,
          IDOffre: item.IDOffre,
          IDSession: item.IDSession,
          IDSpecialite: item.IDSpecialite,
          IDMode_formation: item.IDMode_formation,
          DateD: item.DateD,
          DateF: item.DateF,
          IDEts_Form: item.IDEts_Form,
          ValidationDfp: item.ValidationDfp,
          ValidDfp: item.ValidDfp,
          ValideCentral: item.ValideCentral,
          Valide: item.Valide,
          code_etab: item.code_etab,
          Nom_Et: item.Nom_Et,
          Nom_NomFr_session: item.Nom_NomFr_session,
          Nom_NomFr_CodeSpec_NbrSem_specialite: item.Nom_NomFr_CodeSpec_NbrSem_specialite,
          code_Nom_etablissement: item.code_Nom_etablissement,
          Nom_NomFr_mode_formation: item.Nom_NomFr_mode_formation,
          Nom_NomFr_qualification_dplm: item.Nom_NomFr_qualification_dplm,
          Abr_AbrFr_qualification_dplm: item.Abr_AbrFr_qualification_dplm,
          Nom_NomFr_niveau_scol: item.Nom_NomFr_niveau_scol,
          AgeMAx: item.AgeMAx,
          AgeMin: item.AgeMin,
          IDCycle_scol: item.IDCycle_scol,
          NumOrd: item.NumOrd,
          actions: "",
        }));



        // Load existing data from localforage
        const existingKeys = await localforage.keys();
        const matchingKeys = existingKeys.filter(key => key.startsWith(PREFIX));

        // Filter out keys with timestamps older than 1 minute and log the remaining keys
        for (const key of matchingKeys) {
          try {
            const item = await localforage.getItem(key);
            const timestamp = item ? new Date(item.timestamp) : null;
            if (timestamp && (Date.now() - timestamp.getTime()) >= 30 * 60 * 1000) { // Checking if timestamp is older than 1 minute
              await localforage.removeItem(key); // Remove the key from local storage
              console.log(`Removed item with key more than 30 minutes old: ${key}`);
              if (localStorage.getItem("sessionEncour")) {
                localStorage.removeItem("sessionEncour");
              }
            } else {
              console.log(`Matching dataOffersinscription_: ${key} (Timestamp: ${timestamp.toISOString()})`);
            }
          } catch (error) {
            console.error(`Error retrieving item for key ${key}: ${error.message}`);
          }
        }



        // If there are more than MAX_ENTRIES, remove the oldest entries
        if (matchingKeys.length >= MAX_ENTRIES) {
          try {
            const timestampPromises = matchingKeys.map(async key => {
              try {
                const item = await localforage.getItem(key);
                const timestamp = item ? new Date(item.timestamp).getTime() : -Infinity;
                return { key, timestamp };
              } catch (error) {
                console.error(`Error retrieving timestamp for key ${key}: ${error.message}`);
                return { key, timestamp: -Infinity };
              }
            });

            const timestamps = await Promise.all(timestampPromises);
            timestamps.sort((a, b) => a.timestamp - b.timestamp);
            const sortedKeys = timestamps.map(entry => entry.key);

            /// remove the 4th key 
            const keysToRemove = sortedKeys.slice(0, Math.max(0, sortedKeys.length - MAX_ENTRIES));
            await Promise.all(keysToRemove.map(async key => {
              try {
                await localforage.removeItem(key);
                console.log(`Removed item with key ${key}`);
                if (localStorage.getItem("sessionEncour")) {
                  localStorage.removeItem("sessionEncour");
                }

              } catch (error) {
                console.error(`Error removing item with key ${key}: ${error.message}`);
              }
            }));
          } catch (error) {
            console.error(`Error sorting or removing keys: ${error.message}`);
          }
        }



        // Store the latest data in localforage with the timestamp as a number
        const timestamp = new Date().getTime();
        try {
          await localforage.setItem(`dataOffersinscription_${IDsession_selected}_${IDDFEP_selected}`, { data: apiRows, timestamp });
        } catch (error) {
          console.error(`Error storing data in localforage: ${error.message}`);
        }




        // Update state with fetched data
        setRows(apiRows);
        setFilteredRows(apiRows);

        const uniqueCodeEtabs = [...new Set(apiRows.map(row => row.code_etab))];
        const uniqueValues = uniqueCodeEtabs.map((code_etab, index) => ({
          key: index,
          value: code_etab,
          label: code_etab + ' | ' + apiRows.find(row => row.code_etab === code_etab).Nom_Et
        }));

        uniqueValues.unshift({
          key: 0,
          value: 999,
          label: 'كل المؤسسات'
        });

        setUniqueNomEtValues(uniqueValues);



        /////////Nom formation

        const uniqueNomformationabs = [
          ...new Set(apiRows.map((row) => row.IDMode_formation)),
        ];
        const uniqueValuesNomFormation = uniqueNomformationabs.map(
          (IDMode_formation, index) => ({
            indexvalue: index,
            value: IDMode_formation,
            label: apiRows.find(
              (row) => row.IDMode_formation === IDMode_formation
            ).Nom_NomFr_mode_formation,
          })
        );

        // Adding the desired object at the beginning of uniqueValuesNomFormation
        /*uniqueValuesNomFormation.unshift({
          indexvalue: 0,
          value: 999,
          label: "جميع الأنماط",
        });*/
        // Reset indexvalue based on the sorted order
        uniqueValuesNomFormation.forEach((item, index) => {
          item.indexvalue = index;
        });
        setuniqueNomFormationValues([]);
        setuniqueNomFormationValues(uniqueValuesNomFormation);

        /*setselectedNomFormationValue({
          indexvalue: 0,
          value: 999,
          label: "جميع الأنماط",
        });*/



        /////////Nom specialite

        const uniquespecialiteabs = [
          ...new Set(apiRows.map((row) => row.IDSpecialite)),
        ];
        const uniqueValuesspecialite = uniquespecialiteabs.map(
          (IDSpecialite, index) => ({
            indexvalue: index,
            value: IDSpecialite,
            label: apiRows.find((row) => row.IDSpecialite === IDSpecialite)
              .Nom_NomFr_CodeSpec_NbrSem_specialite,
          })
        );

        // Adding the desired object at the beginning of uniqueValuesspecialite
        uniqueValuesspecialite.unshift({
          indexvalue: 0,
          value: 999,
          label: "كل التخصصات",
        });
        // Reset indexvalue based on the sorted order
        uniqueValuesspecialite.forEach((item, index) => {
          item.indexvalue = index;
        });
        setuniquespecialiteValues([]);
        setuniquespecialiteValues(uniqueValuesspecialite);

        setselectedspecialiteValue({
          indexvalue: 0,
          value: 999,
          label: "كل التخصصات",
        });



        setloadingdata_mfep(false);




      } catch (error) {
        console.error('Error fetching data:', error);
        setloadingdata_mfep(false);
      }



    };


    const loadData = async () => {
      // Check if IDsession_selected and IDDFEP_selected are valid
      if (!IDsession_selected || !IDDFEP_selected || isNaN(IDsession_selected) || isNaN(IDDFEP_selected) || IDsession_selected <= 0 || IDDFEP_selected <= 0) {
        ///console.error('Invalid IDsession_selected or IDDFEP_selected');
        return;
      }

      console.log('IDsession_selected:', IDsession_selected);
      console.log('IDDFEP_selected:', IDDFEP_selected);

      // Clear rows before setting new rows
      setRows([]);
      setFilteredRows([]);
      setloadingdata_mfep(true);

      // Check if data exists in localforage
      const localData = await localforage.getItem(`dataOffersinscription_${IDsession_selected}_${IDDFEP_selected}`);
      if (localData && localData.data) {
        // Load data from localforage
        const apiRows = localData.data;
        setRows(apiRows);
        setFilteredRows(apiRows);

        const uniqueCodeEtabs = [...new Set(apiRows.map(row => row.code_etab))];
        const uniqueValues = uniqueCodeEtabs.map((code_etab, index) => ({
          key: index,
          value: code_etab,
          label: code_etab + ' | ' + apiRows.find(row => row.code_etab === code_etab).Nom_Et
        }));

        uniqueValues.unshift({
          key: 0,
          value: 999,
          label: 'كل المؤسسات'
        });

        setUniqueNomEtValues(uniqueValues);


        /////////Nom formation

        const uniqueNomformationabs = [
          ...new Set(apiRows.map((row) => row.IDMode_formation)),
        ];
        const uniqueValuesNomFormation = uniqueNomformationabs.map(
          (IDMode_formation, index) => ({
            indexvalue: index,
            value: IDMode_formation,
            label: apiRows.find(
              (row) => row.IDMode_formation === IDMode_formation
            ).Nom_NomFr_mode_formation,
          })
        );

        // Adding the desired object at the beginning of uniqueValuesNomFormation
        /*uniqueValuesNomFormation.unshift({
          indexvalue: 0,
          value: 999,
          label: "جميع الأنماط",
        });*/
        // Reset indexvalue based on the sorted order
        uniqueValuesNomFormation.forEach((item, index) => {
          item.indexvalue = index;
        });
        setuniqueNomFormationValues([]);
        setuniqueNomFormationValues(uniqueValuesNomFormation);

        /*setselectedNomFormationValue({
          indexvalue: 0,
          value: 999,
          label: "جميع الأنماط",
        });*/


        /////////Nom specialite

        const uniquespecialiteabs = [
          ...new Set(apiRows.map((row) => row.IDSpecialite)),
        ];
        const uniqueValuesspecialite = uniquespecialiteabs.map(
          (IDSpecialite, index) => ({
            indexvalue: index,
            value: IDSpecialite,
            label: apiRows.find((row) => row.IDSpecialite === IDSpecialite)
              .Nom_NomFr_CodeSpec_NbrSem_specialite,
          })
        );

        // Adding the desired object at the beginning of uniqueValuesspecialite
        uniqueValuesspecialite.unshift({
          indexvalue: 0,
          value: 999,
          label: "كل التخصصات",
        });
        // Reset indexvalue based on the sorted order
        uniqueValuesspecialite.forEach((item, index) => {
          item.indexvalue = index;
        });
        setuniquespecialiteValues([]);
        setuniquespecialiteValues(uniqueValuesspecialite);

        setselectedspecialiteValue({
          indexvalue: 0,
          value: 999,
          label: "كل التخصصات",
        });


        setloadingdata_mfep(false);

        console.log('EXIST:', `data_${IDsession_selected}_${IDDFEP_selected}`);
      }
      else {
        // Set the snackbar message
        //// setSnackbar({ children: ' fetching... ', severity: 'success' });

        // Fetch data from the API
        await fetchData();
      }
    };

    // Call the async function loadData directly inside useEffect
    loadData();

  }, [IDsession_selected, IDDFEP_selected]);



  useEffect(() => {
    if (
      selectedNomFormationValue &&
      selectedNomEtValue &&
      selectedspecialiteValue
    ) {
      // Reset filteredRows
      setFilteredRows([]);

      // Check for default values
      const isNomFormationDefault =
        Number(selectedNomFormationValue.value) === 999;
      const isNomEtDefault = Number(selectedNomEtValue.value) === 999;
      const isSpecDefault = Number(selectedspecialiteValue.value) === 999;


      // Filter rows based on selection
      const filteredData = rows.filter((row) => {
        if (
          isNomFormationDefault &&
          isNomEtDefault &&
          isSpecDefault

        ) {
          // No filtering needed, set filteredRows to all rows
          return true;
        } else {
          // Filter rows based on selected values
          return (
            (isNomFormationDefault ||
              Number(row.IDMode_formation) === Number(selectedNomFormationValue.value)) &&
            (isNomEtDefault || row.code_etab === selectedNomEtValue.value) &&
            (isSpecDefault ||
              Number(row.IDSpecialite) === Number(selectedspecialiteValue.value))
          );
        }
      });

      setFilteredRows(filteredData);
    }
  }, [
    selectedNomEtValue,
    selectedNomFormationValue,
    selectedspecialiteValue
  ]);

  /*
    useEffect(() => {
  
      setFilteredRows([]);
      // Filter rows based on selectedNomEtValue
      if (selectedNomEtValue && selectedNomEtValue.value === 999) {
        setFilteredRows(rows);
      } else {
  
        const filteredData = rows.filter(row => row.code_etab === selectedNomEtValue.value);
        /// alert(JSON.stringify(filteredData));
        setFilteredRows(filteredData);
      }
  
      // Increment the key to force a remount of the DataGrid
      setGridKey(prevKey => prevKey + 1);
  
    }, [selectedNomEtValue]);
  
    */
  const handleNomFormationChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setselectedNomFormationValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected Nom formation Value:", newValue);
    }
  };

  const handlespecialiteChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setselectedspecialiteValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected specialite Value:", newValue);
    }
  };

  const handleNomEtChange = (event, newValue) => {

    if (newValue) {

      // Handle the change according to newValue
      setSelectedNomEtValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected Nom Et Value:", newValue);
    }

  };


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.commit) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
        handleSaveOperation(editedRow.id);
      }
    }
  };




  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };



  const [idsaved, setidsaved] = useState(0);


  const [confirmationTrigger, setConfirmationTrigger] = useState(false);


  const handleSaveClick = (id) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //setidforsave(id);  
    ///setConfirmationTrigger(true); // Set the trigger for confirmation

  };

  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);



  const handleDeleteClick = (id) => () => {

    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { ID } = rowToDelete;
      const { NomAr } = rowToDelete;
      const { PrenomAr } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(ID);
      setDeleted_NomAr(NomAr);
      setDeleted_PrenomAr(PrenomAr);


    }


  };




  const handleConfirmationResponse = async (confirmed) => {
    return;
    // Close the confirmation dialog
    setConfirmationOpen(false);

    if (confirmed) {
      const idToDelete = deletedRowId;
      setDeletedRowId(null); // Reset deletedRowId to avoid multiple deletions

      try {
        // Send the API request to delete the row from the Laravel backend
        const response = await fetch(`${API_BASE_URL}/api/deleteRow_fromCand/${idToDelete}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

        if (response.ok) {
          // If the API request is successful, update the local state to remove the row
          setRows((prevRows) => prevRows.filter((row) => row.id !== idToDelete));
          ////////console.log('Row deleted successfully from Laravel backend.');
          setSnackbar({ children: '  تم الحذف بنجاح  ', severity: 'success' });

        } else {
          console.error('Failed to delete row from Laravel backend.');
          // Handle the error or display a notification to the user
        }
      } catch (error) {
        console.error('Error during the API request:', error);
        // Handle the error or display a notification to the user
      }
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;
      handleSaveOperation(idforsavea);
      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };



  const processRowUpdate = async (newRow) => {
    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Display an alert with the updated row data and id
    ///alert(`Row Data After Update for ID ${newRow.id}: ${JSON.stringify(updatedRow)}`);

    try {
      // Call handleSaveOperation with the id
      await handleSaveOperation(newRow.id, updatedRow);
    } catch (error) {
      console.error('Error during handleSaveOperation:', error);
    }

    return updatedRow;
  };





  const handleSaveOperation = async (id, updatedRow) => {

    const editedRow = updatedRow;
    if (!editedRow) {
      console.error('Row not found for ID:', id);
      return;
    }

    ////alert(`Row Data After Update: ${JSON.stringify(editedRow)}`);


    try {
      let response;


      // If the row is not new, it means it's already in the database, so update it
      response = await fetch(`${API_BASE_URL}/api/updateRowoffermfep/${id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', // Specify the content type
        },
        body: JSON.stringify(editedRow),
      });


      if (response.ok) {
        setRows((prevRows) =>
          prevRows.map((row) => {
            if (row.id === id) {
              // If the row being updated has the same id as the edited row
              // Update all fields except for the password
              return { ...row, isNew: false, Password: '****' };
            } else {
              // For other rows, keep them unchanged
              return row;
            }
          })
        );


        // Set the snackbar message
        setSnackbar({ children: '  تم الحفظ  ', severity: 'success' });

        // Set the row mode to view after saving
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      } else {
        console.error('Failed to save record in the database.');

        // Extract validation error messages from the API response
        const errorData = await response.json();
        const validationErrors = errorData?.error;

        // Concatenate validation error messages
        const errorMessage =
          'الأخطاء:' +
          Object.values(validationErrors || {}).join(', ');

        console.error('errorMessage:', errorMessage);

        setSnackbar({ children: validationErrors, severity: 'error' });

      }
    } catch (error) {
      console.error('Error during the API request:', error);

      // Set the snackbar message for the error
      setSnackbar({ children: 'حدث خطأ', severity: 'error' });
    }



  };




  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };





  const [addRecordClicked, setAddRecordClicked] = useState(false);

  useEffect(() => {
    if (addRecordClicked) {
      handleAddRecord();
      setAddRecordClicked(false);
    }
  }, [addRecordClicked]);

  const handleAddClick = () => {
    setIsAdding(true);
    setAddRecordClicked(true);
  };



  const handleAddRecord = () => {
    const id = uuidv4();
    setRows((oldRows) => {
      const newRows = [
        ...oldRows,
        { id, MatSt: '', NIN: '', NomAr: '', NomFr: '', PrenomAr: '', PrenomFr: '', Email: '', Password: '', isNew: true },
      ];



      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'NomAr' },
      }));
      return newRows;
    });
  };


  const handleRowModesModelChange = (newRowModesModel) => {

    setRowModesModel(newRowModesModel);
  };


  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };


  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#4caf50", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>


          <Dialog
            open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider >
                <Grid item sx={{ mt: 2 }} >
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: '0px' }} />
                  </Typography>
                </Grid>
              </Divider>


            </DialogTitle>



            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                هل أنت متأكد من أنك تريد حذف <span style={{ color: 'black' }}>{deleted_PrenomAr} {deleted_NomAr}؟</span>

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#4caf50", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </CacheProvider>
    );
  };


  const validation_multiple = () => {
    // Alert how many rows are selected
    alert(`Number of selected rows: ${selectedRows.length}`);

  }


  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  /*
  
  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, {
      header: columns.map((col) => col.headerName),
    });
  
    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };
  
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };*/

  const handleRowClick = (params) => {
    // Access the data of the clicked row using params.row
    // You can perform any action here, such as showing an alert
    alert(`Clicked row: ${params.row.id}`);
  };
  const handleRowDoubleClick = (params) => {
    window.open(`/inscription/${params.row.IDOffre}/${IDsession_selected}/${IDDFEP_selected}`, "_blank");

  };

  const handlebtnClick = (IDOffre_row) => () => {
    window.open(`/inscription/${IDOffre_row}/${IDsession_selected}/${IDDFEP_selected}`, "_blank");
  };

  const columns = [
    {
      field: 'id_rows',
      headerName: 'ترقيم',
      minWidth: 40,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "actions",
      type: "actions",
      headerName: "استمارة التسجيل",
      align: "center",
      minWidth: 200,
      cellClassName: "actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",

      getActions: (params) => {

        return [
          <Button
            onClick={handlebtnClick(params.row.IDOffre)}
            variant="contained"
            endIcon={
              <DescriptionIcon
                sx={{ mr: "2px", ml: "-2px", transform: "rotate(0deg)" }}
              />
            }
            style={{ backgroundColor: "#1976d2", width: "fit-content" }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 12,
                fontWeight: 900,
              }}
            >
              سجل هنا
            </Typography>
          </Button>
        ];
      },
    },

    {
      field: 'IDOffre',
      headerName: 'رمز العرض',
      minWidth: 55,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },


    {
      field: 'Nom_NomFr_CodeSpec_NbrSem_specialite',
      headerName: 'التخصص',
      minWidth: 450,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'Abr_AbrFr_qualification_dplm',
      headerName: 'الشهادة',
      minWidth: 300,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'Nom_NomFr_niveau_scol',
      headerName: 'المستوى المطلوب',
      minWidth: 300,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },


    {
      field: 'Nom_NomFr_mode_formation',
      headerName: 'نمط التكوين',
      flex: 1,
      minWidth: 400,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'code_Nom_etablissement',
      headerName: 'المؤسسة',
      flex: 1,
      minWidth: 400,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
    },

    {
      field: 'DateD',
      headerName: 'تاريخ البداية',
      minWidth: 140,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'DateF',
      headerName: 'تاريخ النهاية',
      minWidth: 140,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',

    }


  ];




  function generateRandomString(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };



  function onchangeValideCentral(row) {

    if (row.ValideCentral === 1) {
      /// alert(0);
      row.ValideCentral = 0; // Set ValideCentral to 0
    } else if (row.ValideCentral === 0) {
      //// alert(1);
      row.ValideCentral = 1; // Set ValideCentral to 1
    }

    // Update the state of the row data
    setRows(prevRows => {
      // Create a new array by mapping over the previous rows
      return prevRows.map(prevRow => {
        // If the ID of the current row matches the modified row, return the modified row
        if (prevRow.id === row.id) {
          return row;
        }
        // Otherwise, return the unchanged row
        return prevRow;
      });
    });

    handleSaveOperation(row.id, row);

  }


  return (
    <>
      <Header />



      <Box
        sx={{


          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >

        <div id="root_img" >




          <Box
            sx={{
              '& .super-app-theme--header': {
                fontFamily: 'Amiri',
                fontSize: '20px',
                fontWeight: 'bold', // Make the font bold
                backgroundColor: '#B9D3EE',
                borderRight: '1px solid #ccc', // Add this line to apply right border to the cell
              }

            }}

          >

            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>



                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    sx={{
                      marginTop: 12
                    }}
                  >
                    <Alert
                      {...snackbar}
                      onClose={handleCloseSnackbar}
                      sx={{
                        display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                        , '& .MuiAlert-action': { marginRight: '8px' }
                      }} // Adjust padding here
                    />
                  </Snackbar>

                )}



                <Grid container   >











                  <Grid item sx={{ width: "100%" }}>

                    <Divider sx={{ mt: '20px' }}>
                      <Grid item  >
                        <Typography
                          style={{
                            color: "#191970",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 25,
                            fontWeight: 700,
                            marginBottom: '25px'
                          }}
                          variant="h3" gutterBottom>
                          <EventRepeatIcon sx={{ mr: '5px' }} />
                          عروض التكوين ({Nomsession})
                        </Typography>
                      </Grid>

                    </Divider>


                  </Grid>


                  <Grid container alignItems="center" align="center" justifyContent="center" sx={{ width: "100%", marginBottom: "10px" }}>

                    <Grid item xs={12}>
                      <Grid item xs={5}>
                        {/* Autocomplement Wilayat  */}

                        <CacheProvider value={cacheRtl}>


                          <Autocomplete
                            noOptionsText={'حدث الصفحة مرة أخرى'}
                            classes={classes_listdfep}
                            id="combo-box-demowly"
                            onChange={onwlyChanged}
                            value={selectedOption} // Use the value prop to set the selected option
                            options={options_Wlylist}

                            defaultValue={options_Wlylist[0]} // Set the defaultValue to the first option
                            sx={{ mt: 1, mb: 1 }}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor: indexvalue % 2 === 0 ? "#add8e6" : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? '- ' + label : label}
                                </span>
                              );
                            }}

                            renderInput={(params) => (

                              <TextField
                                {...params}
                                
                                placeholder="إختر ولاية"
                                error={!selectedOption}
                                helperText={
                                  !selectedOption
                                    ? 'إختر ولاية'
                                    : ''
                                }
                                tabIndex={1}
                                dir="rtl" // Add dir="rtl" here
                                 
                                sx={{

                                  '& .MuiFormHelperText-root': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '14px',
                                    color: '#b31d3e',
                                    fontWeight: 700
                                  },

                                  '& .MuiFormLabel-root': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '16px',
                                    color: '#191970'
                                  },
                                  '& .MuiInputBase-input': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '18px',
                                    color: '#191970',
                                    fontWeight: 700
                                  },
                                  '& label.Mui-focused': {
                                    fontSize: '16px',
                                    color: '#b31d3e',
                                    fontWeight: 700
                                  },
                                  '& .MuiInput-underline:after': {
                                    borderBottomColor: '#add8e6',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: '16px',
                                      borderColor: '#add8e6',
                                      borderWidth: "2px",
                                    },
                                    '&:hover fieldset': {
                                      borderColor: 'green',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: 'green',
                                    },
                                  }
                                }}

                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}

                              />

                              /*
                                  <Box display="flex" justifyContent="center">
                                    <TextField fullWidth {...params} label="الولايات" />
                                  </Box>*/
                            )}


                          />




                        </CacheProvider>



                        {uniqueNomFormationValues.length > 0 ? (

                          <Autocomplete

                            noOptionsText={"حدد نمط التكوين"}
                            options={uniqueNomFormationValues}
                            onChange={handleNomFormationChange}
                            value={selectedNomFormationValue}
                            defaultValue={uniqueNomFormationValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="إختر نمط التكوين"
                                placeholder="إختر نمط التكوين"
                                error={!Number(selectedNomFormationValue.value)>0}
                                helperText={
                                  !Number(selectedNomFormationValue.value)>0
                                    ? 'إختر نمط التكوين'
                                    : ''
                                }

                                sx={{
                                  mt:1,
                                  mb:1,
                                  '& .MuiFormHelperText-root': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '14px',
                                    color: '#b31d3e',
                                    fontWeight: 700
                                  },

                                  '& .MuiFormLabel-root': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '16px',
                                    color: '#191970'
                                  },
                                  '& .MuiInputBase-input': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '18px',
                                    color: '#191970',
                                    fontWeight: 700
                                  },
                                  '& label.Mui-focused': {
                                    fontSize: '16px',
                                    color: '#b31d3e',
                                    fontWeight: 700
                                  },
                                  '& .MuiInput-underline:after': {
                                    borderBottomColor: '#add8e6',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: '16px',
                                      borderColor: '#add8e6',
                                      borderWidth: "2px",
                                    },
                                    '&:hover fieldset': {
                                      borderColor: 'green',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: 'green',
                                    },
                                  }
                                }}

                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor:
                                      indexvalue % 2 === 0
                                        ? "#add8e6"
                                        : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />

                        ) : null}


                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      align="center"
                      justifyContent="center"
                      sx={{ width: "100%", marginBottom: "10px", marginTop: "10px" }}
                    >

                      {Number(selectedNomFormationValue.value)>0 &&  uniqueNomEtValues.length > 0 ? (

                        <Grid item xs={5}>





                          <Autocomplete
                            noOptionsText={'   '}

                            options={uniqueNomEtValues}
                            onChange={handleNomEtChange}
                            value={selectedNomEtValue}
                            defaultValue={uniqueNomEtValues[0]} // Set the defaultValue to the first option

                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="المؤسسات"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, key } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor: key % 2 === 0 ? "#add8e6" : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {key === -1 ? label : label}

                                </span>
                              );
                            }}
                          />



                        </Grid>

                      ) : null}

                      {Number(selectedNomFormationValue.value)>0 &&  uniquespecialiteValues.length > 0 ? (
                        <Grid item xs={5} sx={{ ml: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد التخصص"}
                            options={uniquespecialiteValues}
                            onChange={handlespecialiteChange}
                            value={selectedspecialiteValue}
                            defaultValue={uniquespecialiteValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="التخصص"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor:
                                      indexvalue % 2 === 0
                                        ? "#add8e6"
                                        : "#FFFFFF",
                                    color: "#191970",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}





                    </Grid>

                  </Grid>


                  {loadingdata_mfep ? <>

                    <Box sx={{ width: '100%' }}>
                      <LinearProgress color="success" />
                    </Box>

                  </> : null}
                  {/* 
<pre style={{ fontSize: 10 }}>
        {JSON.stringify(selectedRows, null, 4)}
      </pre>
*/}
                  {/* 
 <DataGrid
      checkboxSelection
      onRowSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);
        const selectedRows = filteredRows.filter((row) =>
          selectedIDs.has(row.id),
        );

         ///alert(JSON.stringify(selectedRows, null, 4)); // Alert selected rows

         setSelectedRows(selectedRows);
      }}
*/}

                  <Grid container>
                    <Grid item sx={{ width: "100%" }}>

                      <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={theme_datagrid}>
                          <div dir="rtl" style={{ height: 500, width: '100%' }}>
                            <DataGrid



                              rows={filteredRows}
                              columns={columns}

                              onRowDoubleClick={handleRowDoubleClick}
                              editMode="row"
                              rowModesModel={rowModesModel}
                              onRowModesModelChange={handleRowModesModelChange}
                              onRowEditStop={handleRowEditStop}
                              processRowUpdate={processRowUpdate}
                              getRowId={(row) => row.id}
                              getRowClassName={(params) =>
                                parseInt(params.row.id_rows, 10) % 2 === 0 ? 'even-row' : 'odd-row'
                              }

                              slots={{
                                toolbar: CustomToolbar,
                              }}

                              slotProps={{
                                toolbar: { setRows, setRowModesModel, columns, handleExportExcel, validation_multiple, selectedRows },
                              }}

                              sx={{

                                boxShadow: 2,
                                border: 2,
                                borderColor: 'primary.light',
                                overflowX: 'scroll',


                                '& .MuiDataGrid-cell': {
                                  fontFamily: 'Amiri',
                                  fontSize: '20px',
                                  textAlign: 'center',
                                  backgroundColor: 'rgba(255,255,255,0.65)',
                                  borderRight: '1px solid #ccc', // Add this line to apply right border to the cell


                                },
                                '& .MuiDataGrid-row:hover': {
                                  backgroundColor: '#2b84dd', // Background color when row is hovered
                                },

                                '& .even-row': {
                                  backgroundColor: '#2b84dd61', // Background color for even rows
                                },
                                '& .odd-row': {
                                  backgroundColor: '#ffffff', // Background color for odd rows
                                },


                              }}

                              initialState={{

                                pagination: {
                                  paginationModel: { pageSize: 25, page: 0 },
                                },

                              }}


                            />

                          </div>
                        </ThemeProvider>
                      </CacheProvider>

                    </Grid>
                  </Grid>
                </Grid>



              </ThemeProvider>
            </CacheProvider>

          </Box>


        </div>

        <Footer />


        <ConfirmationDialog open={isConfirmationOpen} onClose={handleConfirmationResponse} />
        <SaveConfirmationDialog open={isSaveConfirmationOpen} onClose={handleSaveConfirmationResponse} />
      </Box>

    </>
  );
}

export default Offersinscription;
