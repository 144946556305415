import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Container from "@mui/material/Container";
import Footer from "../containers/Footer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ApiIcon from "@mui/icons-material/Api";
import FunctionsIcon from "@mui/icons-material/Functions";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LoopIcon from "@mui/icons-material/Loop";
import StorageIcon from '@mui/icons-material/Storage';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import ApartmentIcon from "@mui/icons-material/Apartment";

import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import DescriptionIcon from "@mui/icons-material/Description";
import PrintIcon from "@mui/icons-material/Print";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import appico from "../src/img/logo.png";
import logo2 from "../src/img/logo2.png";
import flag_dz from "../src/img/flag_dz.png";

import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import iconbarleft from "../src/img/logo.png";
import pharmacy from "../src/img/logo.png";
import blooddon from "../src/img/logo.png";
import moresearch from "../src/img/logo.png";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Box from "@mui/material/Box";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../containers/config"; // Adjust the path if necessary

import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import "./amirifont.css";

const Header = ({
  IDetablissement,
  IDDFEP,
  IDNature_etsF,
  IDCandidat,
  crypted_id,
  NumSem,
  IDapis_userslist,
  loadingauth,
  Singup_active,
  download_cert_scol_active,
  EditingInfo_active,
  Eval_notes_active,
  download_cert_inscription_active,
  Singup_active_Dfep,
  download_cert_scol_active_Dfep,
  EditingInfo_active_Dfep,
  Eval_notes_active_Dfep,
  download_cert_inscription_active_Dfep,
  Singup_active_Mfep,
  download_cert_scol_active_Mfep,
  EditingInfo_active_Mfep,
  Eval_notes_active_Mfep,
  download_cert_inscription_active_Mfep,
}) => {
  const [IDsession_selected, setIDsession_selected] = useState(null);
  const [Nomsession, setNomsession] = useState("");

  const apiUrl_session = `${API_BASE_URL}/api/getsessionEncour`;

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(apiUrl_session);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (!data || !data.data || !Array.isArray(data.data)) {
          throw new Error("Unexpected data format received from API");
        }

        // Store fetched data in localforage
        ////await localforage.setItem('sessionEncour', data.data);

        // Process fetched data
        const getsessionEncour = data.data.filter(
          (Wlylist) => Wlylist.Nom !== null
        );

        // Set selected session if available
        if (getsessionEncour.length > 0) {
          ///console.log("IDsession_selected: ",getsessionEncour[0].IDSession);
          setIDsession_selected(getsessionEncour[0].IDSession);
          setNomsession(getsessionEncour[0].Nom);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadDataFromLocalForage = async () => {
      try {
        // If data doesn't exist in localforage, fetch it
        await fetchData();
      } catch (error) {
        console.error("Error loading data from localforage:", error);
      }
    };

    // Load data from localforage or fetch from API
    loadDataFromLocalForage();
  }, []);

  const [value, setValue] = useState(0); //select default tab
  const [value2, setValue2] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl_infocand, setAnchorEl_infocand] = React.useState(null);
  const open_infocand = Boolean(anchorEl_infocand);
  const handleClick_infocand = (event) => {
    setAnchorEl_infocand(event.currentTarget);
  };
  const handleClose_infocand = () => {
    setAnchorEl_infocand(null);
  };

  const [anchorElMotakawin, setAnchorElMotakawin] = React.useState(null);
  const openMotakawin = Boolean(anchorElMotakawin);
  const handleClickMotakawin = (event) => {
    setAnchorElMotakawin(event.currentTarget);
  };
  const handleCloseMotakawin = () => {
    setAnchorElMotakawin(null);
  };

  const navigate = useNavigate();
  const handleClickValidationStEtab = () => {
    // Navigate to the desired route
    navigate("/ValidationCandInfo");
    handleCloseMotakawin();
  };
  const handleClickChangPwStEtab = () => {
    // Navigate to the desired route
    navigate("/ChangPwStEtab");
    handleCloseMotakawin();
  };

  const handleClicksettingDfep = () => {
    // Navigate to the desired route
    if (Number(IDNature_etsF) === 5) {
      navigate("/SettingDfep");
    } else if (Number(IDNature_etsF) === 1) {
      navigate("/SettingMfep");
    }

    handleCloseMotakawin();
  };

  const handleClicksettingapi = () => {
    // Navigate to the desired route
    navigate("/Apis_userslist");
    handleCloseMotakawin();
  };

  const handleClicksettingApis_functions = () => {
    // Navigate to the desired route
    navigate("/Apis_functions");
    handleCloseMotakawin();
  };

  const handleClicksettingMigrate_hfsql = () => {
    // Navigate to the desired route
    navigate("/Migrate_hfsql");
    handleCloseMotakawin();
  };

  const handleClicksettingapis_userlist_functions = () => {
    // Navigate to the desired route
    navigate("/apis_userlist_functions");
    handleCloseMotakawin();
  };

  const handleClicksession = () => {
    // Navigate to the desired route
    navigate("/SessionsMfep");
    handleCloseMotakawin();
  };

  const handleClickChangPwPriv = () => {
    // Navigate to the desired route
    navigate("/ChangPwPriv");
    handleCloseMotakawin();
  };

  const handleClickChangePwEtab = () => {
    // Navigate to the desired route
    navigate("/ChangePwEtab");
    handleCloseMotakawin();
  };

  /* const usePathname = () => {
     const location = useLocation();
     return location.pathname;
   }*/

  const openMyWindow = () => {
    //window.open("/Doc", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
    ///window.open("/Doc", '_system');
  };

  const location = useLocation();
  //////////////console.log(location.pathname);

  useEffect(() => {

    ////////let path = props.location.pathname;
    let path = location.pathname;

    ///alert('location.pathname'+location.pathname);
    //////////////console.log('location.pathname'+location.pathname);

    if (IDCandidat !== null && IDCandidat !== undefined) {
      if (
        (path === "/InfoCand" && value !== 0) ||
        (path === "/Updateinfost" && value !== 0) ||
        (path === "/NotesApr" && value !== 0) ||
        (path === "/ChangePassword" && value !== 0)
      ) {
        setValue(0);
      }
    } else if (
      IDetablissement !== null &&
      (IDCandidat == null ||
        Number(IDCandidat) <= 0 ||
        IDCandidat == undefined) &&
      IDetablissement !== undefined &&
      IDetablissement !== ""
    ) {
      if ((path === "/SettingEtab" || path === "/HomeDfep") && value !== 0) {
        setValue(0);
      } else if (
        (path === "/ValidationCandInfo" && value !== 1) ||
        (path === "/ChangPwStEtab" && value !== 1) ||
        (path === "/ChangPwStDfep" && value !== 1) ||
        (path === "/ChangPwStMfep" && value !== 1) ||
        (path === "/ChangPwPriv" && value !== 1) ||
        (path === "/ChangePwEtab" && value !== 1) ||
        (path === "/SettingDfep" && value !== 1) ||
        (path === "/Apis_functions" && value !== 1) ||
        (path === "/Apis_userslist" && value !== 1) ||
        (path === "/apis_userlist_functions" && value !== 1) ||
        (path === "/OffersEtab" && value !== 1) ||
        (path === "/OffersDfep" && value !== 1) ||
        (path === "/OffersMfep" && value !== 1) ||
        (path === "/SessionsMfep" && value !== 1) ||
        (path === "/SettingMfep" && value !== 1) ||
        (path === "/Migrate_hfsql" && value !== 1)
      ) {
        setValue(1);
      } else if (
        (path === "/ShowAllAprs" && value !== 2) ||
        (path === "/ShowAllAprsdfep" && value !== 2) ||
        (path === "/ShowAllAprsMfep" && value !== 2)
      ) {
        setValue(2);
      }
    } else {
      ///if (path === "/SignUp" && value !== 0) setValue(0);
    }

    if ((path === "/" || path === "/login") && value !== 0) {
      sessionStorage.clear();
      setValue(0);
    } else if (path === "/loginEtab" && value !== 1) {
      sessionStorage.clear();
      setValue(1);
    }
    else if ((path === "/Offersinscription" && value !== 2) ||
      (path.includes("/inscription") && value !== 2)) {
      sessionStorage.clear();
      setValue(2);
    }
    else if (path === "/SignUpInnovation" && value !== 4) {
      sessionStorage.clear();
      setValue(4);
    }
  }, []);

  const handleChangeTabs1 = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  ///////////console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  ///////////console.log(isMatch);
  const isMatch_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch_xs = useMediaQuery(theme.breakpoints.down("xs"));

  const themeAmiri = createTheme({
    typography: {
      fontFamily: '"Amiri", serif',
      fontSize: 12,
    },
  });

  const themeRoboto = createTheme({
    typography: {
      fontFamily: '"Roboto Slab", serif',
      fontSize: 12,
    },
  });

  const themeChanga = createTheme({
    typography: {
      fontFamily: '"Tajawal", sans-serif',
      fontSize: 16,
    },
  });

  let toLink_SettingDfep_or_SettingEtab = "";
  let toLink_changpwst = "";
  let toLink_Offers = "";

  if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    Number(IDNature_etsF) === 1
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeMfep";
    toLink_changpwst = "/ChangPwStMfep";
    toLink_Offers = "/OffersMfep";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (IDNature_etsF === 5 || IDNature_etsF === "5")
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeDfep";
    toLink_changpwst = "/ChangPwStDfep";
    toLink_Offers = "/OffersDfep";
  }
  else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (IDNature_etsF === 40 || IDNature_etsF === "40")
  ) {
    toLink_SettingDfep_or_SettingEtab = "/InnovationList";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== ""
  ) {
    toLink_SettingDfep_or_SettingEtab = "/SettingEtab";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "/OffersEtab";
  } else if (
    IDetablissement !== null &&
    IDetablissement !== undefined &&
    IDetablissement !== ""
  ) {
    toLink_SettingDfep_or_SettingEtab = "/SettingPriv";
    toLink_changpwst = "";
    toLink_Offers = "";
  }

  return (
    ///select first tab

    <>
      {isMatch ? (
        <>
          <Box
            sx={{ mb: 6 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <DrawerComp
              IDsession_selected={IDsession_selected}
              IDetablissement={IDetablissement}
              IDDFEP={IDDFEP}
              IDNature_etsF={IDNature_etsF}
              IDCandidat={IDCandidat}
              crypted_id={crypted_id}
              NumSem={NumSem}
              IDapis_userslist={IDapis_userslist}
              loadingauth={loadingauth}
              Singup_active={Singup_active}
              download_cert_scol_active={download_cert_scol_active}
              EditingInfo_active={EditingInfo_active}
              Eval_notes_active={Eval_notes_active}
              download_cert_inscription_active={
                download_cert_inscription_active
              }
              Singup_active_Dfep={Singup_active_Dfep}
              download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
              EditingInfo_active_Dfep={EditingInfo_active_Dfep}
              Eval_notes_active_Dfep={Eval_notes_active_Dfep}
              download_cert_inscription_active_Dfep={
                download_cert_inscription_active_Dfep
              }
              Singup_active_Mfep={Singup_active_Mfep}
              download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
              EditingInfo_active_Mfep={EditingInfo_active_Mfep}
              Eval_notes_active_Mfep={Eval_notes_active_Mfep}
              download_cert_inscription_active_Mfep={
                download_cert_inscription_active_Mfep
              }
            />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              mb: 25,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <React.Fragment>
              <div>
                {/* Your content above the header */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <AppBar>
                    <div>
                      <Box>
                        <Grid
                          container
                          columns={{ xs: 12, sm: 12, md: 12 }}
                          sx={{ backgroundColor: "#d6e6f6" }}
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid
                              container
                              columns={{ xs: 12, sm: 12, md: 12 }}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                md={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  padding: 1,
                                }}
                              >
                                {isMatch_xs ? (
                                  <></>
                                ) : isMatch_sm ? (
                                  <></>
                                ) : isMatch ? (
                                  <>
                                    <img
                                      src={appico}
                                      style={{ width: "50px", height: "50px" }}
                                      alt="Image 1"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={appico}
                                      style={{ width: "70px", height: "70px" }}
                                      alt="Image 1"
                                    />
                                  </>
                                )}
                              </Grid>
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <Box
                                          sx={{ textAlign: "center", mt: 2 }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "#191970",
                                              fontFamily:
                                                '"Tajawal", sans-serif',
                                              fontWeight: 900,
                                              fontSize: 22,
                                            }}
                                          >
                                            الجمهورية الجزائرية الديمقراطية
                                            الشعبية
                                          </Typography>
                                        </Box>

                                        <Box
                                          sx={{
                                            textAlign: "center",
                                            mb: 2,
                                            position: "relative",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "#191970",
                                              fontFamily:
                                                '"Tajawal", sans-serif',

                                              fontWeight: 900,
                                              fontSize: 20,
                                            }}
                                          >
                                            وزارة التكويـن والتعلـيـم المهنييـن
                                          </Typography>
                                        </Box>
                                      </div>
                                    </Box>
                                  </div>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                md={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  padding: 1,
                                }}
                              >
                                {isMatch_xs ? (
                                  <></>
                                ) : isMatch_sm ? (
                                  <></>
                                ) : isMatch ? (
                                  <>
                                    <img
                                      src={flag_dz}
                                      style={{ width: "50px", height: "50px" }}
                                      alt="Image 1"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={flag_dz}
                                      style={{ width: "70px", height: "70px" }}
                                      alt="Image 1"
                                    />
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>

                    <Toolbar sx={{ width: "100%" }}>
                      <Box
                        component="img"
                        sx={{
                          height: 80,
                          ml: 1,
                        }}
                        alt="منصة المتكون"
                        src={logo2}
                      />

                      <Tabs
                        sx={{ width: "100%", justifyContent: "flex-end" }}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "white",
                          },
                        }}
                        textColor="inherit"
                        value={value}
                        onChange={handleChangeTabs1}
                        variant="standard"
                      >
                        {(IDetablissement == null ||
                          IDetablissement == undefined) &&
                          (IDCandidat == null || IDCandidat == undefined) &&
                          (IDapis_userslist == null ||
                            IDapis_userslist == undefined) ? (
                          <Tab
                            component={Link}
                            to="/login"
                            key={"4"}
                            icon={<PersonIcon />}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              "&:hover": {
                                // Apply styles on hover
                                backgroundColor: "#ffffff1a", // Darker background color on hover
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#ffffff1a", // Example background color for active state
                              },
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginRight: "5px",
                                }}
                              >
                                بوابة المتكون
                              </Typography>
                            }
                          />
                        ) : null}

                        {(IDetablissement == null ||
                          IDetablissement == undefined) &&
                          (IDCandidat == null || IDCandidat == undefined) &&
                          (IDapis_userslist == null ||
                            IDapis_userslist == undefined) ? (
                          <Tab
                            component={Link}
                            to="/loginEtab"
                            key={"5"}
                            icon={<ApartmentIcon />}
                            sx={{
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: 16,
                              fontWeight: 700,
                              display: "flex",
                              alignItems: "center",
                              "&:hover": {
                                // Apply styles on hover
                                backgroundColor: "#ffffff1a", // Darker background color on hover
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#ffffff1a", // Example background color for active state
                              },
                              mr: "5px",
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginRight: "5px",
                                }}
                              >
                                بوابة المؤسسة
                              </Typography>
                            }
                          />
                        ) : null}

                        {IDCandidat !== null && IDCandidat !== undefined && (
                          <Tab
                            key={"2"}
                            ///component={Link} to="/Updateinfost"
                            onClick={handleClick_infocand}
                            sx={{
                              minWidth: "auto",
                              "&:hover": {
                                // Apply styles on hover
                                backgroundColor: "#ffffff1a", // Darker background color on hover
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#ffffff1a", // Example background color for active state
                              },

                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            icon={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <DescriptionIcon />
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mx: "5px",
                                  }}
                                  type="body2"
                                  style={{ color: "#ffffff" }}
                                >
                                  حسابي
                                </Typography>
                                <ArrowDropDownIcon />
                              </div>
                            }
                          />
                        )}

                        {IDetablissement !== null &&
                          (IDCandidat == null ||
                            Number(IDCandidat) <= 0 ||
                            IDCandidat == undefined) &&
                          IDetablissement !== undefined && (
                            <Tab
                              component={Link}
                              to={toLink_SettingDfep_or_SettingEtab}
                              key={"0"}
                              icon={<HomeIcon />}
                              sx={{
                                minWidth: "auto",
                                "&:hover": {
                                  // Apply styles on hover
                                  backgroundColor: "#ffffff1a", // Darker background color on hover
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  backgroundColor: "#ffffff1a", // Example background color for active state
                                },

                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              label={
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                  style={{ color: "#ffffff" }}
                                >
                                  الرئيسية
                                </Typography>
                              }
                            />
                          )}

                        {IDapis_userslist !== null &&
                          IDapis_userslist !== undefined && (
                            <Tab
                              component={Link}
                              to="/ShowApiList"
                              key={"0"}
                              icon={<HomeIcon />}
                              sx={{
                                minWidth: "auto",
                                "&:hover": {
                                  // Apply styles on hover
                                  backgroundColor: "#ffffff1a", // Darker background color on hover
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  backgroundColor: "#ffffff1a", // Example background color for active state
                                },

                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              label={
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                  style={{ color: "#ffffff" }}
                                >
                                  الرئيسية
                                </Typography>
                              }
                            />
                          )}

                        {IDetablissement !== null &&
                          (IDCandidat == null ||
                            Number(IDCandidat) <= 0 ||
                            IDCandidat == undefined) &&
                          IDetablissement !== undefined && (
                            <Tab
                              onClick={handleClickMotakawin}
                              sx={{
                                "&:hover": {
                                  // Apply styles on hover
                                  backgroundColor: "#ffffff1a", // Darker background color on hover
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  backgroundColor: "#ffffff1a", // Example background color for active state
                                },

                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              icon={<DescriptionIcon />}
                              label={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mx: "5px",
                                    }}
                                    type="body2"
                                    style={{ color: "#ffffff" }}
                                  >
                                    لوحة التحكم
                                  </Typography>
                                  <ArrowDropDownIcon />
                                </div>
                              }
                            />
                          )}

                        {IDetablissement !== null &&
                          Number(IDNature_etsF) !== 40 &&
                          (IDCandidat == null ||
                            Number(IDCandidat) <= 0 ||
                            IDCandidat == undefined) &&
                          IDetablissement !== undefined && (
                            <Tab
                              component={Link}
                              to={
                                Number(IDNature_etsF) === 1
                                  ? "/ShowAllAprsMfep"
                                  : Number(IDNature_etsF) === 5
                                    ? "/ShowAllAprsdfep"
                                    : "/ShowAllAprs"
                              }
                              key={"8"}
                              icon={<PrintIcon />}
                              sx={{
                                minWidth: "auto",
                                "&:hover": {
                                  // Apply styles on hover
                                  backgroundColor: "#ffffff1a", // Darker background color on hover
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  backgroundColor: "#ffffff1a", // Example background color for active state
                                },

                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              label={
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                  style={{ color: "#ffffff" }}
                                >
                                  مطبوعات
                                </Typography>
                              }
                            />
                          )}

                        {IDCandidat !== null && IDCandidat !== undefined && (
                          <Tab
                            onClick={handleClick}
                            icon={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <PrintIcon />
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mx: "5px",
                                  }}
                                  type="body2"
                                  style={{ color: "#ffffff" }}
                                >
                                  مطبوعات
                                </Typography>
                                <ArrowDropDownIcon />
                              </div>
                            }
                            style={{ minWidth: "auto" }}
                          />
                        )}

                        {(IDetablissement == null ||
                          IDetablissement == undefined) &&

                          (IDCandidat == null || IDCandidat == undefined) &&
                          (IDapis_userslist == null ||
                            IDapis_userslist == undefined) &&
                          IDsession_selected !== null &&
                          IDsession_selected !== undefined ? (
                          <Tab
                            component={Link}
                            to="/Offersinscription"
                            key={"9"}
                            icon={<EventRepeatIcon />}
                            sx={{
                              display: "flex",
                              alignItems: "right",
                              "&:hover": {
                                // Apply styles on hover
                                backgroundColor: "#ffffff1a", // Darker background color on hover
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#ffffff1a", // Example background color for active state
                              },
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginRight: "5px",
                                  textAlign: "right",
                                }}
                              >
                                عروض التكوين
                              </Typography>
                            }
                          />
                        ) : null}

                        {(IDetablissement == null ||
                          IDetablissement == undefined) &&
                          (IDCandidat == null || IDCandidat == undefined) &&
                          (IDapis_userslist == null ||
                            IDapis_userslist == undefined) ? (
                          <Tab
                            component={Link}
                            to="/CheckAuth"
                            key={"7"}
                            icon={<DocumentScannerIcon />}
                            sx={{
                              display: "flex",
                              alignItems: "right",
                              "&:hover": {
                                // Apply styles on hover
                                backgroundColor: "#ffffff1a", // Darker background color on hover
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#ffffff1a", // Example background color for active state
                              },
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginRight: "5px",
                                  textAlign: "right",
                                }}
                              >
                                تحقق من الشهادة
                              </Typography>
                            }
                          />
                        ) : null}



                        {(IDetablissement == null ||
                          IDetablissement == undefined) &&
                          (IDCandidat == null || IDCandidat == undefined) &&
                          (IDapis_userslist == null ||
                            IDapis_userslist == undefined) ? (
                          <Tab
                            component={Link}
                            to="/SignUpInnovation"
                            key={"8"}
                            icon={<TipsAndUpdatesIcon />}
                            sx={{
                              display: "flex",
                              alignItems: "right",
                              "&:hover": {
                                // Apply styles on hover
                                backgroundColor: "#ffffff1a", // Darker background color on hover
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#ffffff1a", // Example background color for active state
                              },
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginRight: "5px",
                                  textAlign: "right",
                                }}
                              >
                                الصالون الوطني للإبتكار
                              </Typography>
                            }
                          />
                        ) : null}




                        {/* 
 
{(IDetablissement!==null && (IDCandidat==null || Number(IDCandidat)<=0 || IDCandidat==undefined) 
  && IDetablissement !== undefined)
  || (IDCandidat !== null 
  && IDCandidat !== undefined)
  || (IDapis_userslist !== null 
  && IDapis_userslist !== undefined) ?  

  
<Tab 
    component={Link} 
    to="/login"
    key={"6"}
    
    icon={<LogoutIcon />}
     sx={{
      minWidth:'auto',
      fontFamily: 
      '"Tajawal", sans-serif', 
      fontSize: 16
      ,fontWeight:700,
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      '&:hover': { // Apply styles on hover
        backgroundColor: '#ffffff1a', // Darker background color on hover

      },
      '&.Mui-selected': { // Apply styles when tab is active
        backgroundColor: '#ffffff1a', // Example background color for active state

      },
      mr:'5px',
     whiteSpace: 'nowrap', // Prevent text wrapping
     display:'none'
    }}
    

    onClick={() => {
      if (IDapis_userslist !== null && IDapis_userslist !== undefined) {

        sessionStorage.clear();
        // Remove all data with the prefix
        const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith(APP_PREFIX));
        keysToRemove.forEach(key => localStorage.removeItem(key));

      } else {
        const distroytoken = `${API_BASE_URL}/api/distroytoken`;
        fetch(distroytoken, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        })
        .then(response => response.json())
        .then(data => {
          if (data) {
            console.error("Distroyed token: successfully");
          }
        })
        .catch(error => {
          console.error("Error distroytoken:", error);
        })
        .finally(() => {
          sessionStorage.clear();
          // Remove all data with the prefix
          const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith(APP_PREFIX));
          keysToRemove.forEach(key => localStorage.removeItem(key));
        });
      }
    }}

    
    
    label={
      <Typography
        theme={themeChanga}
        sx={{  textAlign: 'right', mr: 0, fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 700, mr: '5px' }}
        type="body2"
        style={{ color: '#ffffff' }}
      >
        خروج
      </Typography>
    }
  />
  
   
: null
            }


*/}
                      </Tabs>

                      <div>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {IDCandidat !== null &&
                            IDCandidat !== undefined &&
                            download_cert_inscription_active !== null &&
                            download_cert_inscription_active !== undefined &&
                            Number(download_cert_inscription_active) === 1 &&
                            Number(download_cert_inscription_active_Dfep) === 1 &&
                            Number(download_cert_inscription_active_Mfep) ===
                            1 &&
                            Number(NumSem) === 1
                            ? (
                              <MenuItem
                                component={Link}
                                to={`/ETATCertificatInscriptionPDF/${crypted_id}`}
                                target="_blank" // Add this line to open in a new tab
                              >
                                {" "}
                                <PictureAsPdfIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  شهادة تسجيل{" "}
                                </Typography>
                              </MenuItem>
                            ) : Number(NumSem) === 1 ? (
                              <MenuItem disabled={true}>
                                {" "}
                                <PictureAsPdfIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  شهادة تسجيل{" "}
                                </Typography>
                              </MenuItem>
                            ) : null}

                          {IDCandidat !== null &&
                            IDCandidat !== undefined &&
                            download_cert_scol_active !== null &&
                            download_cert_scol_active !== undefined &&
                            Number(download_cert_scol_active) === 1 &&
                            Number(download_cert_scol_active_Dfep) === 1 &&
                            Number(download_cert_scol_active_Mfep) === 1 ? (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem
                                component={Link}
                                to={`/ETATCertificatScolaritePDF/${crypted_id}`}
                                target="_blank" // Add this line to open in a new tab
                              >
                                {" "}
                                <PictureAsPdfIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  شهادة تربص{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          ) : (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem disabled={true}>
                                {" "}
                                <PictureAsPdfIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  شهادة تربص{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          )}
                        </Menu>
                      </div>

                      <div>
                        <Menu
                          id="basic-menu_infocand"
                          anchorEl={anchorEl_infocand}
                          open={open_infocand}
                          onClose={handleClose_infocand}
                          MenuListProps={{
                            "aria-labelledby": "basic-button_infocand",
                          }}
                        >
                          <MenuItem component={Link} to="/InfoCand">
                            {" "}
                            <PersonIcon />{" "}
                            <Typography
                              theme={themeChanga}
                              sx={{
                                textAlign: "right",
                                mr: 0,
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 16,
                                fontWeight: 700,
                                mr: "5px",
                              }}
                              type="body2"
                            >
                              بطاقة معلوماتي
                            </Typography>
                          </MenuItem>

                          {IDCandidat !== null &&
                            IDCandidat !== undefined &&
                            Eval_notes_active !== null &&
                            Eval_notes_active !== undefined &&
                            Number(Eval_notes_active) === 1 &&
                            Number(Eval_notes_active_Dfep) === 1 &&
                            Number(Eval_notes_active_Mfep) === 1 ? (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem component={Link} to="/NotesApr">
                                {" "}
                                <StickyNote2Icon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  نتائج السداسي{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          ) : (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem disabled={true}>
                                {" "}
                                <StickyNote2Icon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  نتائج السداسي{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          )}

                          {IDCandidat !== null && IDCandidat !== undefined ? (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem component={Link} to="/ChangePassword">
                                {" "}
                                <LockIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  تغيير كلمة السر{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          ) : null}
                        </Menu>
                      </div>

                      <div>
                        <Menu
                          id="basic-menuMotakawin"
                          anchorEl={anchorElMotakawin}
                          open={openMotakawin}
                          onClose={handleCloseMotakawin}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {IDetablissement !== null &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined &&
                            Number(IDNature_etsF) === 1 && (
                              <div>
                                <Divider
                                  variant="inset"
                                  component="li"
                                  style={{ width: "100%" }}
                                />
                                <MenuItem onClick={handleClicksettingapi}>
                                  {" "}
                                  <ApiIcon />{" "}
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mr: "5px",
                                    }}
                                    type="body2"
                                  >
                                    {" "}
                                    إدارة حسابات (API){" "}
                                  </Typography>
                                </MenuItem>
                              </div>
                            )}

                          {IDetablissement !== null &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined &&
                            Number(IDNature_etsF) === 1 && (
                              <div>
                                <Divider
                                  variant="inset"
                                  component="li"
                                  style={{ width: "100%" }}
                                />
                                <MenuItem
                                  onClick={handleClicksettingApis_functions}
                                >
                                  {" "}
                                  <FunctionsIcon />{" "}
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mr: "5px",
                                    }}
                                    type="body2"
                                  >
                                    {" "}
                                    إدارة الدوال (API Functions){" "}
                                  </Typography>
                                </MenuItem>
                              </div>
                            )}

                          {IDetablissement !== null &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined &&
                            Number(IDNature_etsF) === 1 && (
                              <div>
                                <Divider
                                  variant="inset"
                                  component="li"
                                  style={{ width: "100%" }}
                                />
                                <MenuItem
                                  onClick={
                                    handleClicksettingapis_userlist_functions
                                  }
                                >
                                  {" "}
                                  <FunctionsIcon /> <ApiIcon />{" "}
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mr: "5px",
                                    }}
                                    type="body2"
                                  >
                                    {" "}
                                    الدالة و الحساب (API){" "}
                                  </Typography>
                                </MenuItem>
                              </div>
                            )}

                          {IDetablissement !== null &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined &&
                            Number(IDNature_etsF) === 1 && (
                              <div>
                                <Divider
                                  variant="inset"
                                  component="li"
                                  style={{ width: "100%" }}
                                />
                                <MenuItem onClick={handleClicksession}>
                                  {" "}
                                  <LoopIcon />{" "}
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mr: "5px",
                                    }}
                                    type="body2"
                                  >
                                    {" "}
                                    الدورات{" "}
                                  </Typography>
                                </MenuItem>
                              </div>
                            )}

                          {IDetablissement !== null &&
                            Number(IDNature_etsF) !== 40 &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined &&
                            IDNature_etsF !== null &&
                            IDNature_etsF !== undefined && (
                              <div>
                                <Divider
                                  variant="inset"
                                  component="li"
                                  style={{ width: "100%" }}
                                />

                                <MenuItem component={Link} to={toLink_Offers}>
                                  {" "}
                                  <EventRepeatIcon />{" "}
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mr: "5px",
                                    }}
                                    type="body2"
                                  >
                                    {" "}
                                    عروض التكوين{" "}
                                  </Typography>
                                </MenuItem>
                              </div>
                            )}

                          {IDetablissement !== null &&
                            Number(IDNature_etsF) !== 40 &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined &&
                            IDNature_etsF !== null &&
                            IDNature_etsF !== undefined && (
                              <div>
                                <Divider
                                  variant="inset"
                                  component="li"
                                  style={{ width: "100%" }}
                                />

                                <MenuItem
                                  component={Link}
                                  to={toLink_changpwst}
                                >
                                  {" "}
                                  <LockIcon />{" "}
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mr: "5px",
                                    }}
                                    type="body2"
                                  >
                                    {" "}
                                    تغيير كلمة سر المتكون{" "}
                                  </Typography>
                                </MenuItem>
                              </div>
                            )}

                          {IDetablissement !== null &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined &&
                            (Number(IDNature_etsF) === 5 ||
                              Number(IDNature_etsF) === 1) && (
                              <div>
                                <Divider
                                  variant="inset"
                                  component="li"
                                  style={{ width: "100%" }}
                                />
                                <MenuItem onClick={handleClicksettingDfep}>
                                  {" "}
                                  <ApartmentIcon />{" "}
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mr: "5px",
                                    }}
                                    type="body2"
                                  >
                                    {" "}
                                    إدارة حسابات المؤسسات{" "}
                                  </Typography>
                                </MenuItem>
                              </div>
                            )}

                          {IDetablissement !== null &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined && (
                              <div>
                                <Divider
                                  variant="inset"
                                  component="li"
                                  style={{ width: "100%" }}
                                />
                                <MenuItem onClick={handleClickChangePwEtab}>
                                  {" "}
                                  <LockIcon />{" "}
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mr: "5px",
                                    }}
                                    type="body2"
                                  >
                                    {" "}
                                    تغيير كلمة سر حسابك{" "}
                                  </Typography>
                                </MenuItem>
                              </div>
                            )}


                          {IDetablissement !== null &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined &&
                            Number(IDNature_etsF) === 1 && (
                              <div>
                                <Divider
                                  variant="inset"
                                  component="li"
                                  style={{ width: "100%" }}
                                />
                                <MenuItem onClick={handleClicksettingMigrate_hfsql}>
                                  {" "}
                                  <StorageIcon />{" "}
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mr: "5px",
                                    }}
                                    type="body2"
                                  >
                                    {" "}
                                    نقل قاعدة بيانات من (HFSQL){" "}
                                  </Typography>
                                </MenuItem>
                              </div>
                            )}

                        </Menu>
                      </div>

                      {(IDetablissement !== null &&
                        (IDCandidat == null ||
                          Number(IDCandidat) <= 0 ||
                          IDCandidat == undefined) &&
                        IDetablissement !== undefined) ||
                        (IDCandidat !== null && IDCandidat !== undefined) ||
                        (IDapis_userslist !== null &&
                          IDapis_userslist !== undefined) ? (
                        <Box ml="auto" sx={{ ml: 5 }}>
                          {" "}
                          {/* This will push the Button to the end */}
                          <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/login"
                            key={"6"}
                            icon={<LogoutIcon />}
                            sx={{
                              backgroundColor: "#ffffff1a", // Set background color to red
                              border: "2px solid #ffffff4a", // Add white border
                              minWidth: "auto",
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: 16,
                              fontWeight: 700,
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              "&:hover": {
                                // Apply styles on hover

                                backgroundColor: "red", // Set background color to red
                                border: "2px solid #ffffffd1", // Add white border
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#ffffff1a", // Example background color for active state
                              },
                              mr: "5px",
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            onClick={() => {
                              if (
                                IDapis_userslist !== null &&
                                IDapis_userslist !== undefined
                              ) {
                                sessionStorage.clear();
                                // Remove all data with the prefix
                                const keysToRemove = Object.keys(
                                  localStorage
                                ).filter((key) => key.startsWith(APP_PREFIX));
                                keysToRemove.forEach((key) =>
                                  localStorage.removeItem(key)
                                );
                              } else {
                                const destroyToken = `${API_BASE_URL}/api/destroyToken`;

                                fetch(destroyToken, {
                                  credentials: 'include',
                                })
                                  .then((response) => {
                                    if (!response.ok) {
                                      throw new Error(
                                        "Network response was not ok"
                                      );
                                    }
                                    return response.json();
                                  })
                                  .then((data) => {
                                    if (data) {
                                      console.error(
                                        "Distroyed token: successfully"
                                      );
                                    }
                                  })
                                  .catch((error) => {
                                    console.error("Error destroyToken:", error);
                                  })
                                  .finally(() => {
                                    sessionStorage.clear();
                                    // Remove all data with the prefix
                                    const keysToRemove = Object.keys(
                                      localStorage
                                    ).filter((key) =>
                                      key.startsWith(APP_PREFIX)
                                    );
                                    keysToRemove.forEach((key) =>
                                      localStorage.removeItem(key)
                                    );
                                  });
                              }
                            }}
                          >
                            <Typography
                              theme={themeChanga}
                              sx={{
                                textAlign: "right",
                                mr: 0,
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 16,
                                fontWeight: 700,
                                mr: "0px",
                              }}
                              type="body2"
                              style={{ color: "#ffffff" }}
                            >
                              <LogoutIcon
                                style={{
                                  marginLeft: "3px",
                                  marginBottom: "-6px",
                                }}
                              />{" "}
                              خروج
                            </Typography>
                          </Button>
                        </Box>
                      ) : null}
                    </Toolbar>
                  </AppBar>
                </Box>
              </div>
            </React.Fragment>
          </Box>
        </>
      )}
    </>
  );
};

export default Header;
