import React, { useState, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import './validationstEtab.css'; // Import the external stylesheet
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import XLSX from 'sheetjs-style';
import CheckIcon from '@mui/icons-material/Check';
import Footer from '../containers/Footer';
import Header from '../containers/Header';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {

  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only alphabets (a to z).');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only Arabic alphabets.');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}





const apiUrl = `${API_BASE_URL}/api/getAllinfoste_update`;
const apiUrlListinscription = `${API_BASE_URL}/api/getAllinfostup`;
const apiUrlListinscriptionUpdate = `${API_BASE_URL}/api/getAllinfoste_update`;


function CustomToolbar({ setRows, setRowModesModel, handleExportExcel, columns }) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button onClick={handleExportExcel} color="primary">
        Export to Excel
      </Button>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}




function ValidationStEtab() {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState('');

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);


  const editedValueRef = useRef(editedValue);

  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);



  const navigate = useNavigate();



  /*
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
  
    // Set the rows state with the updated row
    setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
  
    // Show an alert with the updated row data
    alert(`Row Data After Update: ${JSON.stringify(updatedRow)}`);
  
    return updatedRow;
  };*/

  useEffect(() => {
    // Log the edited values whenever rows are updated
    //console.log('Edited Values:', editedValue);
  }, [editedValue]);


  React.useEffect(() => {
    Promise.all([fetch(apiUrlListinscription), fetch(apiUrlListinscriptionUpdate)])
      .then(([responseListinscription, responseListinscriptionUpdate]) =>
        Promise.all([responseListinscription.json(), responseListinscriptionUpdate.json()])
      )
      .then(([dataListinscription, dataListinscriptionUpdate]) => {
        const mergedRows = [
          ...dataListinscription.data.map((item) => ({
            id: `listinscription_${item.ID}`,
            sourcedt: `listinscription`,
            ID: item.ID,
            NomAr: item.NomAr,
            NomFr: item.NomFr,
            PrenomAr: item.PrenomAr,
            PrenomFr: item.PrenomFr,
            Email: item.Email,
            Password: item.Password,
            crypted_id: item.crypted_id,
            actions: '',
          })),
          ...dataListinscriptionUpdate.data.map((item) => ({
            id: `listinscription_update_${item.ID}`,
            sourcedt: `listinscription_update`,
            ID: item.ID,
            NomAr: item.NomAr,
            NomFr: item.NomFr,
            PrenomAr: item.PrenomAr,
            PrenomFr: item.PrenomFr,
            Email: item.Email,
            Password: item.Password,
            crypted_id: item.crypted_id,
            actions: '',
          })),
        ];

        setRows(mergedRows);
        //console.log('Merged Rows:', mergedRows);

      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);





  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.commit) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
        handleSaveOperation(editedRow.id);
      }
    }
  };




  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };



  const [idsaved, setidsaved] = useState(0);


  const [confirmationTrigger, setConfirmationTrigger] = useState(false);


  const handleSaveClick = (id) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //setidforsave(id);  
    ///setConfirmationTrigger(true); // Set the trigger for confirmation

  };

  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);



  const handleDeleteClick = (id) => () => {
    // Open the confirmation dialog
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { ID } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      // Set DeletedRowId to NomAr value
      setDeletedRowId(ID);
    }

  };


  const handleAcceptClick = async (id) => {
    const idaccept = rows.find((row) => row.id === id);

    const { ID } = idaccept;

    try {
      const response = await fetch(`${API_BASE_URL}/api/upd_ListInscription_f_ListInscription_update/${ID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        // You can add the request body if needed
        // body: JSON.stringify({ /* your data */ }),
      });

      if (response.ok) {


        // Fetch the updated data from the API endpoints
        const [responseListinscription, responseListinscriptionUpdate] = await Promise.all([
          fetch(apiUrlListinscription),
          fetch(apiUrlListinscriptionUpdate),
        ]);

        const [dataListinscription, dataListinscriptionUpdate] = await Promise.all([
          responseListinscription.json(),
          responseListinscriptionUpdate.json(),
        ]);

        // Merge the updated data from both endpoints
        const mergedRows = [
          ...dataListinscription.data.map((item) => ({
            id: `listinscription_${item.ID}`,
            sourcedt: `listinscription`,
            ID: item.ID,
            NomAr: item.NomAr,
            NomFr: item.NomFr,
            PrenomAr: item.PrenomAr,
            PrenomFr: item.PrenomFr,
            Email: item.Email,
            Password: item.Password,
            crypted_id: item.crypted_id,
            actions: '',
          })),
          ...dataListinscriptionUpdate.data.map((item) => ({
            id: `listinscription_update_${item.ID}`,
            sourcedt: `listinscription_update`,
            ID: item.ID,
            NomAr: item.NomAr,
            NomFr: item.NomFr,
            PrenomAr: item.PrenomAr,
            PrenomFr: item.PrenomFr,
            Email: item.Email,
            Password: item.Password,
            crypted_id: item.crypted_id,
            actions: '',
          })),
        ];

        // Update the local state with the new merged data
        setRows(mergedRows);
        //console.log('Merged Rows:', mergedRows);
        // Handle success response
        setSnackbar({ children: 'Accepted successfully saved', severity: 'success' });

      } else {
        // Handle error response
        const errorData = await response.json();
        const errorMessage =
          'الأخطاء:' +
          Object.values(errorData?.error || {}).join(', ');

        console.error('errorMessage:', errorMessage);

        setSnackbar({ children: errorMessage, severity: 'error' });
      }
    } catch (error) {
      // Handle network error or other issues
      console.error('Error during the API request:', error);

      setSnackbar({ children: 'Error during the API request', severity: 'error' });
    }
  };



  const handleConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setConfirmationOpen(false);

    if (confirmed) {
      const idToDelete = deletedRowId;
      setDeletedRowId(null); // Reset deletedRowId to avoid multiple deletions

      try {
        // Send the API request to delete the row from the Laravel backend
        const response = await fetch(`${API_BASE_URL}/api/deleteRow_update/${idToDelete}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

        if (response.ok) {
          //console.log('Row deleted successfully from Laravel backend.');

          // Fetch the updated data from the API endpoints
          const [responseListinscription, responseListinscriptionUpdate] = await Promise.all([
            fetch(apiUrlListinscription),
            fetch(apiUrlListinscriptionUpdate),
          ]);

          const [dataListinscription, dataListinscriptionUpdate] = await Promise.all([
            responseListinscription.json(),
            responseListinscriptionUpdate.json(),
          ]);

          // Merge the updated data from both endpoints
          const mergedRows = [
            ...dataListinscription.data.map((item) => ({
              id: `listinscription_${item.ID}`,
              sourcedt: `listinscription`,
              ID: item.ID,
              NomAr: item.NomAr,
              NomFr: item.NomFr,
              PrenomAr: item.PrenomAr,
              PrenomFr: item.PrenomFr,
              Email: item.Email,
              Password: item.Password,
              crypted_id: item.crypted_id,
              actions: '',
            })),
            ...dataListinscriptionUpdate.data.map((item) => ({
              id: `listinscription_update_${item.ID}`,
              sourcedt: `listinscription_update`,
              ID: item.ID,
              NomAr: item.NomAr,
              NomFr: item.NomFr,
              PrenomAr: item.PrenomAr,
              PrenomFr: item.PrenomFr,
              Email: item.Email,
              Password: item.Password,
              crypted_id: item.crypted_id,
              actions: '',
            })),
          ];

          // Update the local state with the new merged data
          setRows(mergedRows);
          //console.log('Merged Rows:', mergedRows);

          setSnackbar({ children: 'Row deleted successfully from Laravel backend', severity: 'success' });
        } else {
          console.error('Failed to delete row from Laravel backend.');
          // Handle the error or display a notification to the user
        }
      } catch (error) {
        console.error('Error during the API request:', error);
        // Handle the error or display a notification to the user
      }
    } else {
      // User clicked "Cancel," do nothing
    }
  };



  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;
      handleSaveOperation(idforsavea);
      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };



  const processRowUpdate = async (newRow) => {
    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Display an alert with the updated row data and id
    ///alert(`Row Data After Update for ID ${newRow.id}: ${JSON.stringify(updatedRow)}`);

    try {
      // Call handleSaveOperation with the id
      await handleSaveOperation(newRow.id, updatedRow);
    } catch (error) {
      console.error('Error during handleSaveOperation:', error);
    }

    return updatedRow;
  };





  const handleSaveOperation = async (id, updatedRow) => {

    const editedRow = updatedRow;
    if (!editedRow) {
      console.error('Row not found for ID:', editedRow.ID);
      return;
    }
    const { ID } = editedRow; // Access the NomAr from the updatedRow
    //// alert(`ID from updatedRow: ${ID}`);
    ///alert(`Row Data After Update: ${JSON.stringify(editedRow)}`);

    try {
      let response;

      if (ID) {

        // If the row is not new, it means it's already in the database, so update it
        response = await fetch(`${API_BASE_URL}/api/updateRow_update/${ID}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          },
          body: JSON.stringify(editedRow),
        });
      }

      if (response.ok) {


        // Update the local state with the edited row
        setRows((prevRows) =>
          prevRows.map((row) => (row.id === id ? { ...row, isNew: false } : row))
        );
        //console.log('Record updated successfully in the database.');


        // Set the snackbar message
        setSnackbar({ children: 'User successfully saved', severity: 'success' });

        // Set the row mode to view after saving
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      } else {
        console.error('Failed to save record in the database.');

        // Extract validation error messages from the API response
        const errorData = await response.json();
        const validationErrors = errorData?.error;

        // Concatenate validation error messages
        const errorMessage =
          'الأخطاء:' +
          Object.values(validationErrors || {}).join(', ');

        console.error('errorMessage:', errorMessage);

        setSnackbar({ children: errorMessage, severity: 'error' });



        // Set the snackbar message for the error
        ///setSnackbar({ children: errorMessage, severity: 'error' });
        ///setSnackbar({ children: errorMessage.replace(/\\n/g, '\n'), severity: 'error' });

      }
    } catch (error) {
      console.error('Error during the API request:', error);

      // Set the snackbar message for the error
      setSnackbar({ children: 'Error during the API request', severity: 'error' });
    }



  };


  const ConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };





  const [addRecordClicked, setAddRecordClicked] = useState(false);

  useEffect(() => {
    if (addRecordClicked) {
      handleAddRecord();
      setAddRecordClicked(false);
    }
  }, [addRecordClicked]);

  const handleAddClick = () => {
    setIsAdding(true);
    setAddRecordClicked(true);
  };



  const handleAddRecord = () => {
    const id = uuidv4();
    setRows((oldRows) => {
      const newRows = [
        ...oldRows,
        { id, NomAr: '', NomFr: '', PrenomAr: '', PrenomFr: '', Email: '', isNew: true },
      ];
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'NomAr' },
      }));
      return newRows;
    });
  };


  const handleRowModesModelChange = (newRowModesModel) => {

    setRowModesModel(newRowModesModel);
  };



  /*

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  
    // Check if a row is in edit mode
    const editedRowId = Object.keys(newRowModesModel).find(
      (id) => newRowModesModel[id]?.mode === GridRowModes.Edit
    );
  
    // If a row is in edit mode, trigger the save operation
    if (editedRowId) {
      handleSaveOperation(editedRowId);
    }
  };

*/
  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };


  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, {
      header: columns.map((col) => col.headerName),
    });

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };



  const columns = [
    { field: 'ID', headerName: 'ID', width: 100 },
    {
      field: 'NomAr', headerName: 'NomAr', width: 150, editable: true,
      preProcessEditCellProps: preProcessEditCellPropsAr,
      renderEditCell: renderEditName,
    },

    {
      field: 'NomFr',
      headerName: 'NomFr',
      width: 150,
      editable: true,
      preProcessEditCellProps: preProcessEditCellProps,
      renderEditCell: renderEditName,
    },

    {
      field: 'PrenomAr', headerName: 'PrenomAr', width: 200, editable: true,
      preProcessEditCellProps: preProcessEditCellPropsAr,
      renderEditCell: renderEditName,
    },
    {
      field: 'PrenomFr', headerName: 'PrenomFr', width: 200, editable: true,
      preProcessEditCellProps: preProcessEditCellProps,
      renderEditCell: renderEditName,
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {

        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const isListInscription = row.sourcedt === 'listinscription';
        if (isListInscription) {
          // Hide actions when sourcedt is equal to 'listinscription'
          return [];
        }

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }


        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
          ,
          <GridActionsCellItem
            icon={<CheckIcon />}
            label="Accept"
            onClick={() => handleAcceptClick(id)}
            color="inherit"
          />,
        ];

      },
    },
  ];



  const getRowClassName = (params) => {
    // Check if the row has sourcedt equal to 'listinscription'
    if (params.row.sourcedt === 'listinscription') {
      return 'custom-green-row'; // Apply the custom style for light green color
    } else if (params.row.sourcedt === 'listinscription_update') {
      return 'custom-red-row'; // Apply the custom style for red color
    }
    return ''; // No custom style for other rows
  };

  return (

    <>

      <Header />

      <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        {/* <Button color="primary" onClick={isAdding ? handleAddRecord : handleAddClick}>
        {isAdding ? 'Add Record' : 'Add record'}
      </Button>
*/ }
        <div style={{ height: 400, width: '100%', direction: "ltr" }}>

          <DataGrid

            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            getRowId={(row) => row.id}
            sortModel={[
              {
                field: 'ID',
                sort: 'asc',
              },
            ]}
            getRowClassName={getRowClassName}

            slots={{
              toolbar: CustomToolbar,
            }}

            slotProps={{
              toolbar: { setRows, setRowModesModel, columns, handleExportExcel },
            }}

          />



          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </div>
        <ConfirmationDialog open={isConfirmationOpen} onClose={handleConfirmationResponse} />
        <SaveConfirmationDialog open={isSaveConfirmationOpen} onClose={handleSaveConfirmationResponse} />
      </Box>

      <Footer />
    </>
  );
}

export default ValidationStEtab;
